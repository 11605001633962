import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class HubbleGPTField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      document.getElementById('marked_text').style.visibility='hidden'
      document.getElementById("typing_imga").style.visibility = "visible";
      var pub_dics = localStorage.getItem('pub_dics');
      var text = document.getElementById('input_text').value;
      console.log(text);
      const req = request.post(gpu_api + '/ask_hubble_bot').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"query":text});
      req.end((end,res)=>{
        if (end!== null && end.status == 504){
          document.getElementById("typing_imga").style.visibility = "hidden";
          document.getElementById('error_msg').style.visibility = 'visible';
          var element = document.getElementById('error_msg');
          function fadeOut(el) {
            var opacity = 1; // Initial opacity
            var interval = setInterval(function() {
               if (opacity > 0) {
                  opacity -= 0.1;
                  el.style.opacity = opacity;
               } else {
                  clearInterval(interval); // Stop the interval when opacity reaches 0
                  el.style.visibility = 'hidden'; // Hide the element
                  el.style.opacity = 1
               }
            }, 550);
         }
         fadeOut(element);
          //document.getElementById('error_msg').delay(5000).fadeOut();
          

          return;
        }
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
        }
        console.log(res.body);
        var answer = res.body.answer;
        answer = answer.replace("'","").replace("\"","").trim();
        ////////////////////////////////
        var regExp = /((EMBASE|CURRENT_CONTENTS|PUBMED|INSIGHTMEME|BIOSIS|DERWENT_DRUG_FILE|FDA|EMA):[A-Z0-9]+)/g;
        var matches = answer.match(regExp);
        var checked = [];
        if(matches != null){
        for (var i = 0; i < matches.length; i++) {
          var str = matches[i];
          var hubble_ids = str;//.substring(1, str.length - 1);
          var hubble_id = hubble_ids.split(',');
          for(var j = 0; j<hubble_id.length;j++){
            if(checked.includes(hubble_id[j])){
              continue;
            }
           // var answer_parts = answer.split(hubble_id[j].trim());
            hubble_id[j] = hubble_id[j].replace("'","").replace("\"","").trim();
            
            if(hubble_id[j].startsWith("EMBASE:") || hubble_id[j].startsWith("PUBMED:")||hubble_id[j].startsWith("CURRENT_CONTENTS:")||hubble_id[j].startsWith("BIOSIS:")||hubble_id[j].startsWith("INSIGHTMEME:")||hubble_id[j].startsWith("DERWENT_DRUG_FILE:")||hubble_id[j].startsWith("CABI:")||hubble_id[j].startsWith("FDA:")||hubble_id[j].startsWith("EMA:")){
              
              answer = answer.replaceAll(hubble_id[j],"<a href='https://search.hubble.int.bayer.com/detail/literature/?id="+hubble_id[j]+"' target='_blank'>"+hubble_id[j]+"</a> ");
              checked.push(hubble_id[j])
              //answer =  answer_parts[0]+ " <a href='https://search.hubble.int.bayer.com/detail/literature/?id="+hubble_id[j]+"' target='_blank'>"+hubble_id[j]+"</a> "+ answer_parts[1];
            
          }
          }
          
        }
      }
        //var parse = require('html-react-parser');
        //answer = parse(answer);
        //////////////////////////////////////
        var marked_text = answer;

        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById("typing_imga").style.visibility = "hidden";
        document.getElementById('marked_text').style.visibility='visible'
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>GPT3.5 powered Question Answering using Hubble</h3>
          <h3>Question:</h3>
          <input type="text" id="input_text" className="premise_text"  name="text_input"></input>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#hubble_gpt3">Documentation about making API calls</a></div>
          <div className='typing'><img id="typing_imga" className='typing_img' src={'/typing.gif'} /></div>
          <div id='marked_text' className="marked_text"></div>
          <div id="doc">Please note that GPT models are hosted by Azure cloud, however, we have opted out from content filtering and abuse monitoring with Microsoft, therefore no submitted data should be logged anywhere. Feel free to review documentation <a href='https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy' target="_blank">here</a>. Please refrain from uploading personal or secret business data or information. <br/>
          Please also review Bayer's guidelines for using GPT models <a href="https://bayernet.int.bayer.com/-/media/bag-intra/ws_bayernet/global/shared/news/2023/09/generative_ai_guidance-and-rules_final_en.pdf" target="_blank">here</a>
          </div>
      </div>
    );
  }
}

export default HubbleGPTField;