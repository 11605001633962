
import React, {Component} from 'react';
import {nlp_machine_api} from '../APIs.js'

class NumericSequence_field extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: [],
      first_year: 1828,
      last_year: 2022,
      prediction_year: 2023,
      training_start_date: 1950,
      sequence: "0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 5, 10, 15, 18, 20, 22, 29, 29, 34, 30, 34, 38, 40, 42, 48, 45, 50, 48, 52, 55, 58, 65, 70, 68, 72, 73, 76, 78"
    };
  }
  _handleChangeEvent(val) {
    return val;
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
    var text = document.getElementById('input_text').value;
    var first_year = document.getElementById('first_year').value;
    var last_year = document.getElementById('last_year').value;
    var prediction_year = document.getElementById('prediction_year').value;
    var training_start_date = document.getElementById('training_start_date').value;
      console.log(text);
      const req = request.post(nlp_machine_api+'/num_series_prediction').set({ 'Authorization': localStorage.getItem("cognito_token"), "Accept": 'application/json', "Content-Type": 'application/json' });;
      var data_load = {"publications":text,"first_year":first_year,"last_year":last_year,"prediction_year":prediction_year,"training_start_date":training_start_date};
      console.log(data_load)
      req.send(JSON.stringify(data_load));
      req.end((end,res)=>{
        if(res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login"); 
        }
        console.log(res.body);
        
        var marked_text = "Prediction:"+res.body["Prediction for year 2023"] +"<br/>\n"+"Training MAE:"+res.body["Training MAE"];
        // var entity_table = "<table border='1'><thead><td>ID</td><td>Concept</td><td>Similarity</td></td></thead>";
        // for(var i = 0;i<annotations.length;i++){
        //     //console.log(annotations[i]);
        //     entity_table = entity_table+"<tr><td>"+annotations[i].concept_id+"</td><td>"+annotations[i].concept_name+"</td><td>"+annotations[i].similarity+"</td></tr>";
            
        // }
        // entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Predict next in numeric sequence - ZeroInflatedRegressor with Random Forest Classifier</h3>
          <h3>First Year:</h3>
          <input type="text"  id="first_year" className="year_inputs_pred" name="first_year"  onChange={()=>{this._handleChangeEvent(this.state.first_year);}} 
                   defaultValue={this.state.first_year}></input><br/>
          <h3>Last Year:</h3>
          <input type="text"  id="last_year" className="year_inputs_pred" name="last_year" onChange={()=>{this._handleChangeEvent(this.state.last_year);}} 
                   defaultValue={this.state.last_year}></input><br/>
          <h3>Prediction Year:</h3>
          <input type="text"  id="prediction_year" className="year_inputs_pred" name="prediction_year" onChange={()=>{this._handleChangeEvent(this.state.prediction_year);}} 
                   defaultValue={this.state.prediction_year}></input><br/>
          <h3>Training Start Year:</h3>
          <input type="text"  id="training_start_date" className="year_inputs_pred" name="training_start_date" onChange={()=>{this._handleChangeEvent(this.state.training_start_date);}} 
                   defaultValue={this.state.training_start_date}></input><br/>
          <h3>Comma separated numerical sequence:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input" onChange={()=>{this._handleChangeEvent(this.state.sequence);}} 
                   defaultValue={this.state.sequence}></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#num_seq_regression">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
          <div id='entity_table' className="entity_table"></div>
      </div>
    );
  }
}

export default NumericSequence_field;