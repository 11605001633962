import React, { Component } from 'react';
import logo from './Linguist-logo.png'
import FileDrop from './FileDrop.js'
import PublicDictionaryView from './PublicDictionaryView.js'
import TopMenu from './TopMenu.js'
import PrivateDictionaryView from './PrivateDictionaryView.js';
import DictionaryUploader from './DictionaryUploader.js';


class Home extends Component {



  render() {
    return (
      <div className="MegaContainer">
           <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          <div className="mainBlock">
          <div className="mainLeft">
            <div className="note">Note: Maximum file size for both upload and download is 10MB, please make sure that your zip is not 
            larger than 10MB, otherwise download of zip may fail.</div>
            <FileDrop/>
            <div>Note: Please make sure our license agreements allow you to process PDF documents you are processing.</div>
        </div>
        <div className="MainRight">
          <PublicDictionaryView/>
          <PrivateDictionaryView/>
          <h3>Upload user dictionary</h3>
          <DictionaryUploader/>
        
        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Home;