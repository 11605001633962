import React, {Component} from 'react';
import {java_api} from '../APIs.js'
import { authProvider } from '../AuthProvider';

class TextAnnotationField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  async ButtonSubmit(event) {
    const request = require('superagent');
    const token = await authProvider.getAccessToken();
      var pub_dics = localStorage.getItem('pub_dics');
      var text = document.getElementById('input_text').value;
      console.log(text);
      var new_api = "https://api3.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
      const req = request.post(new_api+'/linnaeusannotate').set("Authorization",token.accessToken);//("cognito_token"));
      req.send({"public_dic":localStorage.getItem("pub_dics"),"private_dic":localStorage.getItem("priv_dics"),"username":sessionStorage.getItem("username"),'text':text});
      req.end((end,res)=>{
        if (res==undefined && end !== undefined){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
        }
        console.log(res.body);
        var text = res.body.text;
        function sort_by_key(array, key)
        {
        return array.sort(function(a, b)
        {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        }
        var annotations = sort_by_key(res.body.annotations,"start_span");

        var addition = 0;
        var marked_text = text;
        var entity_table = "<table border='1'><thead><td>ID</td><td>Type</td><td>Text</td><td>Pref. term</td><td>Start</td><td>End</td></thead>";
        for(var i = 0;i<annotations.length;i++){
            console.log(annotations[i]);
            var span_element = "<span title='Semantic type: "+annotations[i].sem_type+'\nID: '+annotations[i].id+'\npref term: '+annotations[i].pref_term+"' style=\"background-color:"+annotations[i].hex+"80"+"\">";
            var span_len = span_element.length;
            marked_text =  marked_text.slice(0, annotations[i].start_span+addition) +  span_element + marked_text.slice(annotations[i].start_span+addition,annotations[i].end_span+addition)+"</span>"+marked_text.slice(annotations[i].end_span+addition);
            addition = addition + span_len + 7;
            entity_table = entity_table+"<tr><td>"+annotations[i].id+"</td><td>"+annotations[i].sem_type+"</td><td>"+annotations[i].text_e+"</td><td>"+annotations[i].pref_term+"</td><td>"+annotations[i].start_span+"</td><td>"+annotations[i].end_span+"</td></tr>";
            
        }
        entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
        document.getElementById('entity_table').innerHTML = entity_table;
        //this.state.dobjects.push({name:res.body.url,url:'http://localhost:8080/downloadFile?docName='+res.body.url});
        //this.forceUpdate() 
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Annotation method: Linnaeus (modified) v3.1.5</h3>
          <h3>Imput text for annotation:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#Linnaeus">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
          <div id='entity_table' className="entity_table"></div>
      </div>
    );
  }
}

export default TextAnnotationField;