import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {java_api} from './APIs.js';

class FileDrop extends Component {
  
  constructor() {
    super();
  
  this.downloadzipped = this.downloadzipped.bind(this);
    this.onDrop = (files) => {
      const request = require('superagent');
      this.setState({files});
      files.forEach(file => {
        var data = {public_dic:localStorage.getItem("pub_dics")};
        const req = request.post(java_api+'/upload').set("Authorization",localStorage.getItem("cognito_token"));
        var private_dics = localStorage.getItem("priv_dics");
        var p_dic = private_dics.split(",");
        var priv_dic_str = "";
        for(var i = 0;i<p_dic.length;i++){
          p_dic[i] = sessionStorage.getItem("username")+"/"+p_dic[i];
          priv_dic_str = priv_dic_str+p_dic[i]+",";
        }
        priv_dic_str = priv_dic_str.slice(0, -1);
        var public_dics = localStorage.getItem("pub_dics");
        var all_dics = priv_dic_str+","+public_dics;
      req.attach('file', file).field("public_dic",all_dics);
      this.state.dobjects.push({name:"Working...",url:''});
      this.forceUpdate() 
      req.end((end,res)=>{
        if (res==undefined && end !== undefined){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
         // throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
        }
        if(res.body.content == "The file is in wrong format (only pdf accepted)"){
          var index = this.state.dobjects.map(function(e) { return e.name; }).indexOf('Working...');
          if (index > -1) {
            this.state.dobjects.splice(index, 1);
          }
          this.state.dobjects.push({name:"Error:"+res.body.content,url:''});
          this.forceUpdate() 
          return
        }
        console.log(res);
        var index = this.state.dobjects.map(function(e) { return e.name; }).indexOf('Working...');
        if (index > -1) {
          this.state.dobjects.splice(index, 1);
        }
        //this.state.dobjects.pop({name:"Working...",url:''})
        this.state.dobjects.push({name:res.body.url,url:java_api+'/downloadfile?docName='+res.body.url});
        this.forceUpdate() 
      });
  })
  
      
    };
    this.state = {
      files: [],
      dobjects: []
    };
  }


  download_single(file){
    const request = require('superagent');
    var fileList = "";
    fileList = file;
    //var apilink = "https://zf5syc4b0l.execute-api.eu-central-1.amazonaws.com/LinguistAPI_state1";
    //var java_api = 'http://localhost:8080'
    const req = request.post(java_api+'/downloadfile').set("Authorization",localStorage.getItem("cognito_token")).set("Content-Type","application/json").set("Accept","application/pdf").responseType('blob');;
    console.log(fileList);
    req.send({"docName":fileList});
    req.then((res) => {

      var blob =  res.body;
      return blob;
  })
  .then((blob) => {
    
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'processed'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  })
  
  }

  downloadzipped(){
    const request = require('superagent');
    var fileList = "";
    for(var i =0;i<this.state.files.length;i++)
    {
        fileList = fileList + this.state.files[i].name + ",";
        
    }
    fileList = fileList.substring(0, fileList.length - 1);
    //var apilink = "https://zf5syc4b0l.execute-api.eu-central-1.amazonaws.com/LinguistAPI_state1";
    const req = request.post(java_api+'/downloadFilesZipped').set("Authorization",localStorage.getItem("cognito_token")).responseType('blob');
    console.log(fileList);
    req.send({"docName":fileList});
    req.then((res) => {
      var blob = res.body;//new Blob(res.body, {type: "application/zip"});
      return blob;
  })
  .then((blob) => {
    
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'zipped.zip'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  })
  }

  render() {
    const files = this.state.dobjects.map(file => (
      <li key={file.name}>
        {file.url!==''  && <a href="#" onClick={() => this.download_single(file.name)}>{file.name}</a>}
        {file.url==''  && file.name}
      </li>
    ));
    var fileList = ""
    for(var i =0;i<this.state.dobjects.length;i++)
    {
        if(this.state.dobjects[i].url!==null && this.state.dobjects[i].name!==null && this.state.dobjects[i].name!="null"){
        fileList = fileList + this.state.dobjects[i].name + ",";
        }
    }
    fileList = fileList.substring(0, fileList.length - 1);
    var zipurl = "#";
    var downloadLink = "";
    if(this.state.files.length>0){
    zipurl = java_api+"/downloadFilesZipped?docName="+fileList;
    downloadLink = <a href="#" onClick={this.downloadzipped}>Download All (zip)</a>
    }

    return (
      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
              <h4>Files</h4>
              <ul>{files}</ul>
              {downloadLink}
            </aside>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default FileDrop;