import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class SummarizationField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      var pub_dics = localStorage.getItem('pub_dics');
      var text = document.getElementById('input_text').value;
      console.log(text);
      const req = request.post(gpu_api + '/t5_summarize').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"query":text});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
        }
        console.log(res.body);
        var annotations = res.body;

        var addition = 0;
        var marked_text = res.body.summary;

        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Summarization: T5</h3>
          <h3>Imput text for summarizing:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#t5_summarization">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
      </div>
    );
  }
}

export default SummarizationField;