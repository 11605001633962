import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class TranslationField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      var input_lang = document.getElementById('input_language').value;
      var output_lang = document.getElementById('output_language').value;
      var text = document.getElementById('input_text').value;
      console.log(text);
      const req = request.post(gpu_api + '/t5_translate').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"context":text,"source_language":input_lang,"target_language":output_lang});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
         // throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
        }
        console.log(res.body);
        var annotations = res.body;

        var addition = 0;
        var marked_text = res.body.translation;
        // var entity_table = "<table border='1'><thead><td>ID</td><td>Concept</td><td>Similarity</td></td></thead>";
        // for(var i = 0;i<annotations.length;i++){
        //     //console.log(annotations[i]);
        //     entity_table = entity_table+"<tr><td>"+annotations[i].concept_id+"</td><td>"+annotations[i].concept_name+"</td><td>"+annotations[i].similarity+"</td></tr>";
            
        // }
        // entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
        //document.getElementById('entity_table').innerHTML = entity_table;
        //this.state.dobjects.push({name:res.body.url,url:'http://localhost:8080/downloadFile?docName='+res.body.url});
        //this.forceUpdate() 
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Translation: T5</h3>
          <p className="inter_para">Due to T5 model's cut-off, it is best to translate sentence by sentence with this model</p>
          <div id="boxForLang">
          <label className="inputlabel" for="input_language">Input language:</label>
          <select name="input_language" id="input_language">
          <option value="English">English</option>
          </select>
          <label className="inputlabel" for="output_language">Output language:</label>
          <select name="output_language" id="output_language">
          <option value="German">German</option>
          <option value="French">French</option>
            <option value="Romanian">Romanian</option>
          </select>
          </div>
          <br/>
          <h3>Imput text for translation:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#t5_translation">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
      </div>
    );
  }
}

export default TranslationField;