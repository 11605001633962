import React, { Component } from 'react';
import logo from '../Linguist-logo.png';

import WorkbenchTable from './WorkbenchTable.js';
import TopMenu from '../TopMenu.js';
import Modal from "./Modal.js"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class Workbench extends Component {
    constructor() {
        super();
       
        this.state = {
          files: [],
          annotations: [],
          show: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.exportData = this.exportData.bind(this);
        this.sort_by_key = this.sort_by_key.bind(this);
        this.ProcessCRFUMLSTagging = this.ProcessCRFUMLSTagging.bind(this);
        this.MarinikaZitnik = this.MarinikaZitnik.bind(this);
        this.ProcessSentimentT5 = this.ProcessSentimentT5.bind(this);
        this.ProcessPICO = this.ProcessPICO.bind(this);
        this.ProcessInVivo = this.ProcessInVivo.bind(this);
        this.ProcessTherUse = this.ProcessTherUse.bind(this);
        this.ProcessSummary = this.ProcessSummary.bind(this);
        this.ProcessTranslate = this.ProcessTranslate.bind(this);
        this.ProcessZeroShot = this.ProcessZeroShot.bind(this);
      }

      exportData = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.table_to_sheet(document.getElementById('entity_table'),{raw:false,cellStyles: true,header: 1});
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "data_export" + fileExtension);

      };

      showModal = () => {
        this.setState({ show: true });
      };
    
      hideModal = () => {
        this.setState({ show: false });
      };


      sort_by_key(array, key){
              return array.sort(function(a, b)
              {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
              });
              }
        

        async ProcessCRFUMLSTagging(text_data)
        {
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
          const req = request.post(new_api+'/ner_all_umls_normalize').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
          await req.send({'query':text_data['text']});
          await req.then((res)=>{
            if (res==undefined){
              window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
              //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
            }
            console.log(res.body);
            var text = res.body.text;
            
            var annotations = this.sort_by_key(res.body.Entities,"start");
    
            var entities = "";
            var annotated_txt= "";
            for(var i = 0;i<annotations.length;i++){
                entities = entities + annotations[i].string+ "; sem_type: "+annotations[i].label+"; id: "+annotations[i].CUI+"; pref_term: "+annotations[i].preferred_name + "; start span:"+annotations[i].start+"; end span:"+annotations[i].end+"<br/>";
            }
            // annots.push(marked_text);
            // this.setState({annotations: annots});
            var id = -1;
            for(var a = 0;a<raw_data.length;a++){
              if(raw_data[a]['text']==text || raw_data[a]['text']+"."==text){
                id = a;
              }
            }
            var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
            full_annotations[len-1].push({"id":id,"text":entities});
            full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
            sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          }).catch((err) => {
            console.log(err);
            console.log("Error is logged")
            if (err.response) {
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
            } else if (err.request) {
              console.log("No response");
            }else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', err.message);
            }
          
          });
        }

        async ProcessUMLSTagging(text_data)
        {
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');

          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
            const req = request.post(new_api+'/tag_umls').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
            await req.send({'query':text_data['text']});
            await req.then((res)=>{
              if (res==undefined){
                window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
                //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              console.log(res.body);
              var text = res.body.Text;
              
              var annotations = this.sort_by_key(res.body.Entities,"start");
      
              var addition = 0;
              var marked_text = text;
              var entities = "";
              var annotated_txt= "";
              for(var i = 0;i<annotations.length;i++){
                  console.log(annotations[i]);
                  // var span_element = "<span title='Semantic type: "+annotations[i].sem_type+'\nID: '+annotations[i].id+'\npref term: '+annotations[i].pref_term+"' style=\"background-color:"+annotations[i].hex+"80"+"\">";
                  // var span_len = span_element.length;
                  annotated_txt = text.slice(annotations[i].start,annotations[i].end);
                  // marked_text =  marked_text.slice(0, annotations[i].start_span+addition) +  span_element + marked_text.slice(annotations[i].start_span+addition,annotations[i].end_span+addition)+"</span>"+marked_text.slice(annotations[i].end_span+addition);
                  // addition = addition + span_len + 7; 
                  entities = entities + annotated_txt+ "; sem_type: "+annotations[i].Type+"; id: "+annotations[i].concept_id+"; pref_term: "+annotations[i].Canonical_name + "; start span:"+annotations[i].start+"; end span:"+annotations[i].end+"<br/>";
              }
              // annots.push(marked_text);
              // this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
                if(raw_data[a]['text']==text){
                  id = a;
                }
              }
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              
              full_annotations[len-1].push({"id":id,"text":entities});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
            });
        }

        async MarinikaZitnik(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com";//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
            const req = request.post(new_api+'/ner_deep_learning').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
            await req.send({'query':text_data['text']});
            await req.then((res)=>{
              if (res==undefined){
                //window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
                //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              console.log(res.body);
              var text = res.body.text;
              
              var annotations = this.sort_by_key(res.body.Entities,"start");
      
              var entities = "";
              var annotated_txt= "";
              for(var i = 0;i<annotations.length;i++){
                  //console.log(annotations[i]);
                  // var span_element = "<span title='Semantic type: "+annotations[i].sem_type+'\nID: '+annotations[i].id+'\npref term: '+annotations[i].pref_term+"' style=\"background-color:"+annotations[i].hex+"80"+"\">";
                  // var span_len = span_element.length;
                  annotated_txt = text.slice(annotations[i].start,annotations[i].end);
                  // marked_text =  marked_text.slice(0, annotations[i].start_span+addition) +  span_element + marked_text.slice(annotations[i].start_span+addition,annotations[i].end_span+addition)+"</span>"+marked_text.slice(annotations[i].end_span+addition);
                  // addition = addition + span_len + 7; 
                  entities = entities + annotated_txt+ "; label: "+annotations[i].label+ "; start span:"+annotations[i].start+"; end span:"+annotations[i].end+"<br/>";
              }
              // annots.push(marked_text);
              // this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
                if(raw_data[a]['text']==text || raw_data[a]['text']+"."==text){
                  id = a;
                }
              }
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              full_annotations[len-1].push({"id":id,"text":entities});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
            }).catch((err)=>{ console.log("Error");
          
          });
        }


        async ProcessSentimentT5(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
            const req = request.post(new_api+'/t5_sentiment').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
           await req.send({'query':text_data['text']});
            await req.then((res)=>{
              if (res==undefined){
                window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
                //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              console.log(res.body);
              var text = res.body.context;
              
              var marked_text = res.body.sentiment?"positive":"negative";
              annots.push(marked_text);
              this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
                if(raw_data[a]['text']==text){
                  id = a;
                }
              }    
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              full_annotations[len-1].push({"id":id,"text":marked_text});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
            });
        }

        async ProcessPICO(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
            const req = request.post(new_api+'/pico_classification').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
            await req.send({'query':text_data['text']});
            await req.then((res)=>{
              if (res==undefined){
                window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
                //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              console.log(res.body);
              var text = res.body.text;
              
      
              var addition = 0;
              var marked_text = "PICO_Class:"+res.body.PICO_Class +"<br/>\n"+"Confidence:"+res.body.confidence;
              annots.push(marked_text);
              this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
                if(raw_data[a]['text']==text){
                  id = a;
                }
              }
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              full_annotations[len-1].push({"id":id,"text":marked_text});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
            });
        }

        async ProcessInVivo(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
          const req = request.post(new_api+'/invivo_classifier').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
          await req.send({'query':text_data['text']});
          await req.then((res)=>{
            if (res==undefined){
              window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
              //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
            }
            console.log(res.body);
            var text = res.body.text;
            
    
            var addition = 0;
            var marked_text = "Class:"+res.body.class +"<br/>\n"+"Confidence:"+res.body.confidence;
            annots.push(marked_text);
            this.setState({annotations: annots});
            var id = -1;
            for(var a = 0;a<raw_data.length;a++){
              if(raw_data[a]['text']==text){
                id = a;
              }
            }
            var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
            full_annotations[len-1].push({"id":id,"text":marked_text});
            full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
            sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          });
        }

        async ProcessTherUse(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');

          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
          const req = request.post(new_api+'/therapeutic_use_classifier').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
          await req.send({'query':text_data['text']});
          await req.then((res)=>{
            if (res==undefined){
              window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
              //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
            }
            console.log(res.body);
            var text = res.body.text;
            
    
            var addition = 0;
            var marked_text = "Class:"+res.body.class +"<br/>\n"+"Confidence:"+res.body.confidence;
            annots.push(marked_text);
            this.setState({annotations: annots});
            var id = -1;
            for(var a = 0;a<raw_data.length;a++){
              if(raw_data[a]['text']==text){
                id = a;
              }
            }
            var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
            full_annotations[len-1].push({"id":id,"text":marked_text});
            full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
            sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          });
        }

        async ProcessZeroShot(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
            const req = request.post(new_api+'/zero_shot_classifier').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
            await req.send({'text':text_data['text'],'labels':sessionStorage.getItem('labels')});
            await req.then((res)=>{
              if (res==undefined){
                window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
                //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              var marked_text = "Class:"+res.body.class +"<br/>\n"+"Confidence:"+res.body.confidence;
              var text = res.body.text;
              annots.push(marked_text);
              this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
                if(raw_data[a]['text']==text){
                  id = a;
                }
              }
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              full_annotations[len-1].push({"id":id,"text":marked_text});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
            });
        }

        async ProcessSummary(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
          const req = request.post(new_api+'/t5_summarize').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
          await req.send({'query':text_data['text']});
          await req.then((res)=>{
            if (res==undefined){
              window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
              //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
            }
            console.log(res.body);
            var text = res.body.context;
            
    
            var addition = 0;
            var marked_text = res.body.summary ;
            annots.push(marked_text);
            this.setState({annotations: annots});
            var id = -1;
            for(var a = 0;a<raw_data.length;a++){
              if(raw_data[a]['text']==text){
                id = a;
              }
            }
            var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
            full_annotations[len-1].push({"id":id,"text":marked_text});
            full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
            sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          });
        }

        async ProcessTranslate(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
            const req = request.post(new_api+'/t5_translate').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
            await req.send({'context':text_data['text'],'source_language': sessionStorage.getItem("input_language"),'target_language':sessionStorage.getItem("output_language")});
            await req.then((res)=>{
              if (res==undefined){
                window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
                //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              console.log(res.body);
              var text = res.body.context;
              
              var marked_text = res.body.translation ;
              annots.push(marked_text);
              this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
                if(raw_data[a]['text']==text){
                  id = a;
                }
              }
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              full_annotations[len-1].push({"id":id,"text":marked_text});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
            });

        }
        sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }
      

        async ProcessLinnaeusData(text_data){
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          
          var len = full_annotations.length;
          var annots = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          const request = require('superagent');
          var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
          const req = request.post(new_api+'/linnaeusannotate').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
          await req.send({"public_dic":localStorage.getItem("pub_dics"),"private_dic":localStorage.getItem("priv_dics"),"username":sessionStorage.getItem("username"),'text':text_data.text});
          await req.then(res=>{
              if (res==undefined ){
              window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
              //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
              }
              //console.log(res.body);
              var text = res.body.text;
              
              var annotations = this.sort_by_key(res.body.annotations,"start_span");
  
              var addition = 0;
              var marked_text = text;
              var entities = "";
              var annotated_txt= "";
              for(var i = 0;i<annotations.length;i++){
                  //console.log(annotations[i]);
                  var span_element = "<span title='Semantic type: "+annotations[i].sem_type+'\nID: '+annotations[i].id+'\npref term: '+annotations[i].pref_term+"' style=\"background-color:"+annotations[i].hex+"80"+"\">";
                  var span_len = span_element.length;
                  annotated_txt = text.slice(annotations[i].start_span,annotations[i].end_span);
                  marked_text =  marked_text.slice(0, annotations[i].start_span+addition) +  span_element + marked_text.slice(annotations[i].start_span+addition,annotations[i].end_span+addition)+"</span>"+marked_text.slice(annotations[i].end_span+addition);
                  addition = addition + span_len + 7; 
                  entities = entities + annotated_txt+ "; sem_type: "+annotations[i].sem_type+"; id: "+annotations[i].id+"; pref_term: "+annotations[i].pref_term + "; start span:"+annotations[i].start_span+"; end span:"+annotations[i].end_span+"<br/>";
              }
              annots.push(marked_text);
              this.setState({annotations: annots});
              var id = -1;
              for(var a = 0;a<raw_data.length;a++){
              if(raw_data[a]['text']==text){
                  id = a;
              }
              }
              var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
              
              full_annotations[len-1].push({"id":id,"text":marked_text,"entities":entities});
              full_annotations[len-1] = this.sort_by_key(full_annotations[len-1],"id");
              sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
  
          }).catch(err => console.log(err));;
      }

      handleSave = async () =>{
        this.setState({ show: false });
        document.getElementById("loader").classList.add('show-loader');
        // In case Linnaeus is selected
        if(sessionStorage.getItem("active_accordion")=="Linnaeus"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=='Linnaeus'){
              alert("This header already exists");
              return;
            }
          }
          heads.push("Linnaeus");
          heads.push("Linnaeus entities");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          // var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          // full_annotations.push([]);
          // var len = full_annotations.length;
          // var annots = this.state.annotations;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          let promises = []

            for(var l = 0;l<raw_data.length/200+1;l++){
              for(var k = 0;k<200;k++){
                if(l*200+k<raw_data.length){
                  await promises.push(this.ProcessLinnaeusData(raw_data[l*200+k]));
                  }
                }
          await Promise.all(promises);
        }
      }

         // In case UMLS annotator is selected
         if(sessionStorage.getItem("active_accordion")=="UMLS annotator"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=='UMLS annotator'){
              alert("This header already exists");
              return;
            }
          }
          heads.push("UMLS annotator");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          let promises = []
          for(var l = 0;l<raw_data.length/20+1;l++){
            for(var k = 0;k<20;k++){
              if(l*20+k<raw_data.length){
            await promises.push(this.ProcessUMLSTagging(raw_data[l*20+k]));
          }
        }
          await Promise.all(promises);
        }
      }

        // In case CRF with UMLS annotator is selected
        if(sessionStorage.getItem("active_accordion")=="CRF for Genes,Drugs, and Diseases with UMLS"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=='CRF for Genes,Drugs, and Diseases with UMLS'){
              alert("This header already exists");
              return;
            }
          }
          heads.push("CRF for Genes,Drugs, and Diseases with UMLS");
          //heads.push("Linnaeus entities");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          var len = full_annotations.length;
          var annots = this.state.annotations;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          let promises = [];
          for(var l = 0;l<raw_data.length/20+1;l++){
          for(var k = 0;k<20;k++){
            if(l*20+k<raw_data.length){
            await promises.push(this.ProcessCRFUMLSTagging(raw_data[l*20+k]));
          }
          }
          await Promise.all(promises);
          //await new Promise(r => setTimeout(r, 1000));
        }
        }

         // In case Marinika Zitnik NER is selected
         if(sessionStorage.getItem("active_accordion")=="Marinika Zitnik NER"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=='Marinika Zitnik NER'){
              alert("This header already exists");
              return;
            }
          }
          heads.push("Marinika Zitnik NER");
          //heads.push("Linnaeus entities");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          var len = full_annotations.length;
          var annots = this.state.annotations;
          let promises = [];
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          for(var l = 0;l<raw_data.length;l++){
          await promises.push(this.MarinikaZitnik(raw_data[l]))
          await Promise.all(promises);
        }
      }


        // In case Sentiment is selected
        if(sessionStorage.getItem("active_accordion")=="Sentiment"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=='Sentiment'){
              alert("This header already exists");
              return;
            }
          }
          heads.push("Sentiment");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          var len = full_annotations.length;
          var annots = this.state.annotations;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          var len2 = raw_data.length;
          let promises = [];
          for(var l = 0;l<raw_data.length/20+1;l++){
            for(var k = 0;k<20;k++){
              if(l*20+k<raw_data.length){
            await promises.push(this.ProcessSentimentT5(raw_data[l*20+k]));
          }
        }
          await Promise.all(promises);
        }
      }


        // In case PICO is selected
        if(sessionStorage.getItem("active_accordion")=="PICO Classifier"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=="PICO Classifier"){
              alert("This header already exists");
              return;
            }
          }
          heads.push("PICO Classifier");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          var len = full_annotations.length;
          var annots = this.state.annotations;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          var len2 = raw_data.length;
          let promises = [];
          for(var l = 0;l<raw_data.length/20+1;l++){
            for(var k = 0;k<20;k++){
              if(l*20+k<raw_data.length){
            await promises.push(this.ProcessPICO(raw_data[l*20+k]))
          }
        }
          await Promise.all(promises);
        }
      }

                // In case In Vivo is selected
                if(sessionStorage.getItem("active_accordion")=="In Vivo Classifier"){
                  var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
                  for(var i =0;i<heads.length;i++){
                    if(heads[i]=="In Vivo Classifier"){
                      alert("This header already exists");
                      return;
                    }
                  }
                  heads.push("In Vivo Classifier");
                  sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
                  var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
                  full_annotations.push([]);
                  sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
                  var len = full_annotations.length;
                  var annots = this.state.annotations;
                  var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
                  var len2 = raw_data.length;
                  let promises = [];
                  for(var l = 0;l<raw_data.length/20+1;l++){
                    for(var k = 0;k<20;k++){
                      if(l*20+k<raw_data.length){
                    await promises.push(this.ProcessInVivo(raw_data[l*20+k]))
                  }
                }
                  await Promise.all(promises);
                }
              }

                 // In case Therapeuic use is selected
                 if(sessionStorage.getItem("active_accordion")=="Therapeutic Use Classifier"){
                  var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
                  for(var i =0;i<heads.length;i++){
                    if(heads[i]=="Therapeutic Use Classifier"){
                      alert("This header already exists");
                      return;
                    }
                  }
                  heads.push("Therapeutic Use Classifier");
                  sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
                  var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
                  full_annotations.push([]);
                  sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
                  var len = full_annotations.length;
                  var annots = this.state.annotations;
                  var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
                  var len2 = raw_data.length;
                  let promises = [];
                  for(var l = 0;l<raw_data.length/20+1;l++){
                    for(var k = 0;k<20;k++){
                      if(l*20+k<raw_data.length){
                    await promises.push(this.ProcessTherUse(raw_data[20*l+k]));
                  }
                }
                  await Promise.all(promises);
                }
              }


                // In case Summarization use is selected
                if(sessionStorage.getItem("active_accordion")=="Summarization"){
                  var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
                  for(var i =0;i<heads.length;i++){
                    if(heads[i]=="Summary"){
                      alert("This header already exists");
                      return;
                    }
                  }
                  heads.push("Summary");
                  sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
                  var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
                  full_annotations.push([]);
                  sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
                  var len = full_annotations.length;
                  var annots = this.state.annotations;
                  var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
                  var len2 = raw_data.length;
                  let promises = [];
                  for(var l = 0;l<raw_data.length/3+1;l++){
                    for(var k = 0;k<3;k++){
                      if(l*3+k<raw_data.length){
                    await promises.push(this.ProcessSummary(raw_data[3*l+k]));
                      }
                  }
                  await Promise.all(promises);
                }
              }

        // In case Translation use is selected
        if(sessionStorage.getItem("active_accordion")=="Translate"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          for(var i =0;i<heads.length;i++){
            if(heads[i]=="Translate"){
              alert("This header already exists");
              return;
            }
          }
          heads.push("Translate");
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          var len = full_annotations.length;
          var annots = this.state.annotations;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          var len2 = raw_data.length;
          
          for(var l = 0;l<raw_data.length;l++){
            let promises = []
            await promises.push(this.ProcessTranslate(raw_data[l]));
          await Promise.all(promises);
        }
      }



        // Zero shot classifier = TODO: Return text when classifing
        if(sessionStorage.getItem("active_accordion")=="Zero shot classifier"){
          var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
          heads.push(sessionStorage.getItem("column_name"));
          sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
          var full_annotations = JSON.parse(sessionStorage.getItem("annotations"));
          full_annotations.push([]);
          sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
          var len = full_annotations.length;
          var annots = this.state.annotations;
          var raw_data = JSON.parse(sessionStorage.getItem("wizzard_items"));
          var len2 = raw_data.length;

          for(var l = 0;l<raw_data.length;l++){
            let promises = [];
            await promises.push(this.ProcessZeroShot(raw_data[l]));
          await Promise.all(promises);
      }
        }
        this.setState({ show: false });
        document.getElementById("loader").classList.remove('show-loader'); 
        window.location.reload();


        // var len2 = raw_data.length;
        // this.setState({ show: false });
        // setTimeout(() => {
          
        //   }, (len2+2)*time_ag);
        
        this.forceUpdate();

      }
    


  render() {
    return (
      <div className="MegaContainer">
          <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          <div className="mainBlock">
          <div className="mainLeft">
          <div className= "modal-loader" id="loader">
            <img src="https://64.media.tumblr.com/345127a42a4baf76158920730f808f3b/tumblr_nak5muSmwi1r2geqjo1_500.gifv" width="500px"></img>
            </div>
          <Modal show={this.state.show} handleClose={this.hideModal} handleSave={this.handleSave}>
          <p>Add Column</p>
        </Modal>
          <button type="button" onClick={this.showModal} className="addModalButton">
          Add column
        </button>

        <button type="button" onClick={this.exportData} className="DownloadXMLXButton">
          Download
        </button>
            <WorkbenchTable/>
        </div>
        <div className="MainRight">
          

        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Workbench;