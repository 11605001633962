import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class WizzardInput extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
    var full_annotations = [];
    var heads = []
    sessionStorage.setItem("annotations",JSON.stringify(full_annotations));
    sessionStorage.setItem("annotations_heads",JSON.stringify(heads));
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
    var index_in = document.getElementById('select_index').value;
      var text = document.getElementById('input_text').value.replace("\n", "");
      if(text == ""){
        return;
      }
      console.log(text);
      if(index_in == "no-index"){
        var jsn2 = [{"id":0,"text":text}]
        sessionStorage.setItem("wizzard_items",JSON.stringify(jsn2));
        window.location.href="/Workbench";
      }
      var url = "https://api.linguist.skgt.int.bayer.com" 
      const req = request.post( url+ '/get_documents_filtered_multi_index').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      var jsn ={}
      jsn[index_in] = text
      req.send({"query":JSON.stringify(jsn)});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
         // throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
        }
        console.log(res.body);
        sessionStorage.setItem("wizzard_items",JSON.stringify(res.body));
        window.location.href="/Workbench";
      });
     
}

  componentDidMount(){
    const queryString = window.location.search;
    var url = "https://api.linguist.skgt.int.bayer.com";
    
    if(queryString.length>0){
      const request = require('superagent');
      const urlParams = new URLSearchParams(queryString);
      const keys = urlParams.keys();
      var jsn = {};
      var hash_value  = "";
      if(urlParams.get("hash")!=undefined){
        hash_value = urlParams.get("hash");
        const req = request.post( url+ '/get_documents_by_hash').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"query":hash_value});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          //window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
         // throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
        }
        console.log(res.body);
        sessionStorage.setItem("wizzard_items",JSON.stringify(res.body));
        window.location.href="/Workbench";
      });
      }
      else{
      for (const key of keys){
        var value = urlParams.get(key);
        jsn[key] = value;
      }
      var data = JSON.stringify(jsn);
      const req = request.post( url+ '/get_documents_filtered_multi_index').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"query":data});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          //window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
         // throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
        }
        console.log(res.body);
        sessionStorage.setItem("wizzard_items",JSON.stringify(res.body));
        window.location.href="/Workbench";
      });
    }



    }


    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Wizzard</h3>
          <p className="inter_para">Input comma separated <a href="https://search.hubble.int.bayer.com/" target="_blank">Hubble</a> ids in the box bellow</p>
          <br/>
          <p> 
          <label className="inputlabel" for="select_index">Please select index:</label>
          <select name="output_language" id="select_index">
          <option value="hubble-literature">Literature</option>
          <option value="hubble-patent">Patents</option>
          <option value="hubble-trial">Clinical trials</option>
          <option value="hubble-pipeline">Pipelines</option>
          <option value="hubble-brs">Reports</option>
          <option value="hubble-news">News</option>
          <option value="hubble-website">Websites</option>
          <option value="hubble-tto">Technology Transfer Offers</option>
          <option value="hubble-grant">Grants</option>
          <option value="no-index">Free text (no index)</option>
          </select>
          </p>
          <br/>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#t5_translation">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
      </div>
    );
  }
}

export default WizzardInput;