import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class WorkbenchTable extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: [],
      
    };
  }

  componentDidMount(){
    var raw_text = JSON.parse(sessionStorage.getItem("wizzard_items"));
    var annotations = JSON.parse(sessionStorage.getItem("annotations"))
    var entity_table = "<table border='1'><thead><td>Document id</td><td>Original text</td>";
    var heads = JSON.parse(sessionStorage.getItem("annotations_heads"));
    for(var i=0;i<heads.length;i++){
        entity_table = entity_table + "<td>"+heads[i]+"</td>";
    }
    entity_table = entity_table + "</thead>";
    for(var i = 0;i<raw_text.length;i++){
      entity_table = entity_table+"<tr><td>"+raw_text[i]['id']+"</td><td>"+raw_text[i]['text']+"</td>"; 
      for(var j=0;j<annotations.length;j++){
          entity_table = entity_table + "<td>"+annotations[j][i]['text']+"</td>";
          if ('entities' in annotations[j][i]){
            entity_table = entity_table + "<td>"+annotations[j][i]['entities']+"</td>";
          }
      }
      entity_table = entity_table + "</tr>"
  }
  entity_table = entity_table + "</table>";
  document.getElementById('entity_table').innerHTML = entity_table;
    
  }
  


  render() {
    return (
      <div className="text_field">
          <div id='entity_table' className="entity_table2"></div>
      </div>
    );
  }
}

export default WorkbenchTable;