import React, {Component} from 'react';
import {java_api} from "./APIs.js";

class TopMenu extends Component {


  
  constructor() {
    super();
    this.state = { username: '' };
    this.state.username = sessionStorage.getItem("username");
    if(this.state.username==null){
      this.state.username = window.prompt("Type your username","");
      sessionStorage.setItem("username",this.state.username);
    }

    const request = require('superagent');
    
    const req = request.post(java_api+'/getprivatedics').set("Authorization",localStorage.getItem("cognito_token"))
    req.send({"username":sessionStorage.getItem("username")});
    req.end((end,res)=>{
      
      if(res.body.message == "The incoming token has expired")
     {
      window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';      
      //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
     }
      if (res==undefined && end !== undefined){
        window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';      
        //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
      }
      console.log(res);
      if(res.body.user_dictionaries!=null){

      this.state.tips = res.body.user_dictionaries ;
      localStorage.setItem('priv_dics',this.state.dobjects);
      this.forceUpdate();
        }});



  }


  render() {



    return (

      <div className="TopMenu">
        <div className="LeftSide">User: {this.state.username}</div>
        
        <div className="RightSide"><a className="MenuLink" href="/contact">About/Contact</a></div>


        <div className="RightSide">
         <div className="dropdown">
  <a className="dropbtn">Named entity recognition</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/annotatePDF">Linnaeus for PDF</a>
  <a className="MenuLink" href="/annotateText">Linnaeus for text</a>
  <a className="MenuLink" href="/UMLS_annotate">Spacy UMLS annotator</a>
  <a className="MenuLink" href="/CRFNER_annotate">CRF annotator for compounds, genes and diseases</a>
  <a className="MenuLink" href="/CRFUMLS_annotate">CRF annotator with UMLS normalizer for compounds, genes and diseases</a>
  <a className="MenuLink" href="/MZ_annotate">Deep learning based NER (Marinika Zitnik)</a>
  <a className="MenuLink" href="/i2b2_Drug">Drug administration info (BERT-based)</a>
  <a className="MenuLink" href="/Chem_biobert">Chemicals and Drug NER (BioBERT)</a>
  <a className="MenuLink" href="/Gene_biobert">Gene NER (BioBERT)</a>
  <a className="MenuLink" href="/Disease_biobert">Disease NER (BioBERT)</a>
  <a className="MenuLink" href="/Ontonotes_ner">Ontonotes 18 entity types (Transformer-based)</a>
  <a className="MenuLink" href="/zero_shot_ner">Zero Shot NER (flair)</a>
  <a className="MenuLink" href="/ZeroShotBioNER">Zero Shot BioNER (Bayer+SIAI)</a>
  <a className="MenuLink" href="/RWE_NER">Real World Evidence Database NER</a>

  
  </div>
  
  
  </div>
  <div className="dropdown">
  <a className="dropbtn">Normalization</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/UMLS_annotate">Spacy UMLS normalizer</a>
  {/*<a className="MenuLink" href="/Ontology_norm">Ontology-based normalizer (SBERT)</a> */}
  </div>
</div> 

<div className="dropdown">
  <a className="dropbtn">Classification</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/Sentiment">Sentiment (T5)</a>
  <a className="MenuLink" href="/ZeroShotClassifier">Zero-shot classification</a>
  <a className="MenuLink" href="/PICOClassifier">PICO classifier</a>
  <a className="MenuLink" href="/InVivoClassifier">In Vivo classifier</a>
  <a className="MenuLink" href="/TherUseClassifier">Therapeutic Use classifier</a>

  
  </div>
</div> 


<div className="dropdown">
  <a className="dropbtn">NLU</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/Inference">Inference (MNLI, T5)</a>
  <a className="MenuLink" href="/QuestionAnswer">Question Answering (T5)</a>
  <a class="MenuLink" href="/RelationshipView.html">Relationship extraction</a>
  </div>
</div>

<div className="dropdown">
  <a className="dropbtn">Text Gen</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/Summarization">Summarization (T5)</a>
  <a className="MenuLink" href="/Translation">Translation (T5)</a>
  <a className="MenuLink" href="/GPT4">GPT-4</a>
  <a className="MenuLink" href="/GPT4_32k">GPT-4 32k tokens</a>
  <a className="MenuLink" href="/GPT4Turbo">GPT4-Turbo</a>
  <a className="MenuLink" href="/GPT4o">GPT4o</a>
  <a className="MenuLink" href="/GPT4oMini">GPT4o Mini</a>
  
  <a className="MenuLink" href="/Claude2">Claude 2</a>
  <a className="MenuLink" href="/Claude3">Claude 3 (Sonnet)</a>
  <a className="MenuLink" href="/Claude3Opus">Claude 3 (Opus)</a>
  <a className="MenuLink" href="/Claude35Sonnet">Claude 3.5 (Sonnet)</a>
  <a className="MenuLink" href="/PALM2">PaLM2 Bison</a>
  <a className="MenuLink" href="/PALM2_unicorn">PaLM2 Unicorn</a>
  <a className="MenuLink" href="/Gemini_pro">Gemini pro</a>
   <a className="MenuLink" href="/Gemini_pro15">Gemini pro 1.5</a>
  <a className="MenuLink" href="/Llama2_13B">Llama 2 13B chat</a>
  <a className="MenuLink" href="/Llama2_70B">Llama 2 70B chat</a>
  <a className="MenuLink" href="/Llama3_70B">Llama 3 70B Instruct</a>
  <a className="MenuLink" href="/Llama31_70B">Llama 3.1 70B Instruct</a>
  <a className="MenuLink" href="/Mixtral">Mistral Mixtral 8x7B</a>
  <a className="MenuLink" href="/HubbleGPTQA">GPT-based QA using Hubble</a>
  <a className="MenuLink" href="/HubbleGPT4QA">GPT4-based QA using Hubble</a>
  <a className="MenuLink" href="https://factfinder.linguist.skgt.int.bayer.com/">FactFinder (LSC)</a>
  
  </div>
</div> 

<div className="dropdown">
  <a className="dropbtn">Other</a>
  <div className="dropdown-content">
  <a className="MenuLink" href="/Wizzard">Workbench</a>
  <a className="MenuLink" href="/PredictNumSequence">Prediction of Numerical Sequences (Regression)</a>
  </div>
</div>

<div className="dropdown">
        <a className="dropbtn">Documentation</a>
        <div className="dropdown-content">
        <a className="MenuLink" href="/docs">Linguist API Docs</a>
        <a className="MenuLink" href="/nlp_scale_docs">Linguist@Scale API Docs</a>
        <a className="MenuLink" href="/PipelineBuilderDocumentation.pdf" target="_blank">PipelineBuilder Docs</a>
        </div>
        </div>

 




</div>
      </div>
      
       );
      }
    }
    
    export default TopMenu;