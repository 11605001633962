import React, { Component } from 'react';
import logo from './Linguist-logo.png';
import deepmind from './Partners/DeepmindLogo.png'
import nlpscale from './Partners/NLP@Scale.png'
import hermione from './Partners/Hermione.png'
import TopMenu from './TopMenu.js';


class About extends Component {
    constructor() {
        super();
       
        this.state = {
          files: [],
          dobjects: []
        };
      }
    


  render() {
    return (
      <div className="MegaContainer">
          <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          <div className="mainBlock">
         <h1>About Linguist</h1>
         <div className="paragraph">
         <p>Linguist is a collection and demo platform for Natural language processing (NLP) Application Programming Interfaces (API) and algorithms that are used across Bayer. The goal of Linguist is to 
             collect all internally developped APIs, demo them, document them, drive and facilitate their reusability across the projects.
         </p>
         <p>The idea of this platform is to have sufficient tools for managing configurable parts of APIs and to empower users to try our NLP algorithms as well as to make it easy to apply them in other projects. 
             Basically, once you find appropriate algorithms on the platform, all you need to do is to check documentation to see how to call the right interface and implement such calls in your application. 
             Therefore, this platform aims at making NLP across organization open, integrated and ready to easily use and apply. 
         </p>
         <p>Often, we need small simple algorithms that would process our text and extract certain information. It is likely that someone in the organization have implemented such algorithm, however, it may be dificult to find the right person.
             Linguist collects all the algorithms in the organization and makes them reusable, and therfore saves often occuring reimplementation costs as well as time of searching for the people who may have implemented given solution. 
         </p>
         <p>In case you are processing any kind of lincensed data, please make sure to follow the lincense agreement.  
         </p>
         </div>
         <h2>Share your algorithms</h2>
         <div className="paragraph">
            In case you have developped an NLP algorithm that can be exposed as API, please let us know. Please write an email to  <a href="mailto:nikola.milosevic@bayer.com">nikola.milosevic@bayer.com</a> and we can discuss, document and implement integration with your API. 
         </div>
         <h2>Help</h2>
         <div className="paragraph">
         For any help regarding to found bugs, issues and other help you may need, please contact <a href="mailto:nikola.milosevic@bayer.com">nikola.milosevic@bayer.com</a>.
         </div>
         <h2>Contributors</h2>
         <div className="paragraph">
         With various capacities and effort in inplementation, deployment and disscussion about issues, the following people made this project possible:
         <ul>
          <li>Nikola Milosevic</li>
          <li>Jannis Busch</li>
          <li>Leonardo D'Ambrosi</li>
          <li>Hagen Mohr</li>
          <li>Carsten Jahn</li>
          <li>Torsten Gippert</li>
          <li>Hooman Sedghamiz</li>
          <li>Enrico Santus</li>
          <li>Wolfgang Thielemann</li>
          <li>Frank Erlebach</li>
          <li>Astrid Rheinländer</li>
          <li>Forooz Shahbazi Avarvand</li>

         </ul>
         </div>
         <h2>Partner projects</h2>
         <div className="paragraph">
         <img className="partner-logo" src={deepmind} alt="Bayer DeepMind" />
         <img className="nlpscale-logo" src={nlpscale} alt="NLP@Scale" />
         <img className="nlpscale-logo" src={hermione} alt="Hermione" />
         </div>
        </div>
        </div>
      </div>
    );
  }
}

export default About;