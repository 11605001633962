import React, {Component} from 'react';
import {java_api} from './APIs.js';

class PublicDictionaryView extends Component {
    
    loadDics() {
        const request = require('superagent');

        const req = request.post(java_api+'/getPublicDics').set("Authorization",localStorage.getItem("cognito_token")).set("Content-Type","application/json");
        req.end((end,res)=>{
         if(res.body.message == "The incoming token has expired"||res.body.message == "Unauthorized")
         {
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';      
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
         }
          if (res==undefined && end !== undefined){
            window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';      
            //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
          }
          console.log(res);
          for (const [key, value] of Object.entries(res.body.public_dictionaries)){
            if(key.startsWith('cs')){
              this.state.csobjects.push(key);
            }
            else{
            this.state.phobjects.push(key);
            }
          }
          this.state.tips =  res.body.public_dictionaries ;
          //localStorage.setItem('pub_dics',this.state.dobjects);
          this.forceUpdate();
            });
        }
    
  constructor() {
    super();
    this.loadDics = this.loadDics.bind(this);
    localStorage.setItem('pub_dics',"");
    if(localStorage.getItem('selected_dics')==null){
      localStorage.setItem('selected_dics',"");
    }
   
    this.state = {
      files: [],
      phobjects: [],
      csobjects: [],
      phtips:[],
      cstips:[]
    };
  }


  componentDidMount(){
    this.loadDics();


    
  }
  componentDidUpdate(){
    var selected_dics = localStorage.getItem('selected_dics').split(",");
    var pub_dics = localStorage.getItem('pub_dics').split(",");
    for(var i = 0; i<selected_dics.length;i++){
      if(selected_dics[i]==""){
        continue;
      }
      document.getElementById(selected_dics[i]).checked = true;
      pub_dics.push(selected_dics[i]);
    }
    localStorage.setItem('pub_dics',pub_dics);
  }

  checkboxChange(event) {
    if (document.getElementById(event.target.id).checked == true) {
      document.getElementById(event.target.id).value = 'on';
      document.getElementById(event.target.id).checked = true;
      
      var pub_dics = localStorage.getItem('pub_dics').split(",");
      pub_dics.push(event.target.id);
      localStorage.setItem('pub_dics',pub_dics);
      console.log(pub_dics);
      var selected_dics = localStorage.getItem('selected_dics').split(",");
      selected_dics.push(event.target.id);
      localStorage.setItem('selected_dics',selected_dics);
      
    } else {
      document.getElementById(event.target.id).value = 'off';
      document.getElementById(event.target.id).checked = false;
      var pub_dics = localStorage.getItem('pub_dics').split(",");
      var selected_dics = localStorage.getItem('selected_dics').split(",");
      const index_sel = selected_dics.indexOf(event.target.id);
      const index = pub_dics.indexOf(event.target.id);
        if (index > -1) {
            pub_dics.splice(index, 1);
        }
        if(index>-1){
          selected_dics.splice(index_sel,1)
        }
        console.log(pub_dics);
        localStorage.setItem('pub_dics',pub_dics);
        localStorage.setItem('selected_dics',selected_dics);
    }
}


  render() {
    const phdics = this.state.phobjects.map(file => (
      <div><span title={this.state.tips[file]}>
        <input type="checkbox" id={file} name={file}
         onClick={this.checkboxChange} ></input>
         <label for={file}>{file}</label></span>
         </div>

    ));
    const csdics = this.state.csobjects.map(file => (
      <div>
        <span title={this.state.tips[file]}><input type="checkbox" id={file} name={file}
         onClick={this.checkboxChange} ></input>
         <label for={file}>{file}</label></span>
         </div>

    ));

    return (
      <div className="public_dictionaries">
          <h3>Public dictionaries</h3>
          <h4>Pharma</h4>
          {phdics}
          <h4>Crop science</h4>
          {csdics}
      </div>
    );
  }
}

export default PublicDictionaryView;