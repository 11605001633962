
import React, {Component} from 'react';
import {nlp_machine_api} from '../APIs.js'

class RWE_NER_Field extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      var text = document.getElementById('input_text').value;
      console.log(text);
      var nlp_machine_api = "https://api.linguist.skgt.int.bayer.com";
      const req = request.post(nlp_machine_api+'/get_rwe').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: '*/*' });;
      req.send({"query":text});
      req.end((end,res)=>{
        if(res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login"); 
        }
        console.log(res.body);
        if (res.body.length!==0){
         text = res.body[0].sentence;
        }
        
        function sort_by_key(array, key)
        {
        return array.sort(function(a, b)
        {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        }
        var annotations = sort_by_key(res.body,"start_ind");

        var addition = 0;
        var marked_text = text;
        var start =-1;
        var entity_table = "<table border='1'><thead><td>RWD_found</td><td>RWD_Reference</td><td>ref_IDs</td><td>Algorithm</td><td>Fuzzy ratio</td><td>Start</td><td>End</td></thead>";
        for(var i = 0;i<annotations.length;i++){
            console.log(annotations[i]);
            var span_element = "<span title='RWD_reference: "+annotations[i].RWD_Reference+'\nref_IDs: '+annotations[i].ref_IDs+'\nAlgorithm: '+annotations[i].Algorithm+'\nFuzzyRatio: '+annotations[i].fuzzy_ratio+"' style=\"background-color:yellow"+"\">";
            var span_len = span_element.length;
            annotations[i].end_ind = parseInt(annotations[i].end_ind) + 1;
            if(parseInt(annotations[i].start_ind)>0){
              if(marked_text.slice(parseInt(annotations[i].start_ind)+addition,parseInt(annotations[i].start_ind)+addition)=="<"){
            marked_text =  marked_text.slice(0, parseInt(annotations[i].start_ind)+addition) +  span_element + marked_text.slice(parseInt(annotations[i].start_ind)+addition,parseInt(annotations[i].end_ind)+addition)+"</span>"+marked_text.slice(parseInt(annotations[i].end_ind)+addition);
              }else{
                marked_text =  marked_text.slice(0, parseInt(annotations[i].start_ind)+addition) +  span_element + marked_text.slice(parseInt(annotations[i].start_ind)+addition,parseInt(annotations[i].end_ind)+addition)+"</span>"+marked_text.slice(parseInt(annotations[i].end_ind)+addition);
           
              }  
          }else{
              marked_text =  marked_text.slice(0, parseInt(annotations[i].start_ind)+addition) +  span_element + marked_text.slice(parseInt(annotations[i].start_ind)+addition,parseInt(annotations[i].start_ind)+addition)+"</span>"+marked_text.slice(parseInt(annotations[i].end_ind)+addition);
           
            }
            addition = addition + span_len + 7;
            
            entity_table = entity_table+"<tr><td>"+annotations[i].RWD_found+"</td><td>"+annotations[i].RWD_Reference+"</td><td>"+annotations[i].ref_IDs+"</td><td>"+annotations[i].Algorithm+"</td><td>"+annotations[i].fuzzy_ratio+"</td><td>"+annotations[i].start_ind+"</td><td>"+annotations[i].end_ind+"</td></tr>";
        }
        entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
        document.getElementById('entity_table').innerHTML = entity_table;
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Annotation method: Real World Evidence data sources using TARS and list of known sources.</h3>
          <h3>Imput text for annotation:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#RWE_NER">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
          <div id='entity_table' className="entity_table"></div>
      </div>
    );
  }
}

export default RWE_NER_Field;