import React, { Component } from 'react';
import logo from './Linguist-logo.png'
import TopMenu from './TopMenu.js'


class Home extends Component {




  render() {
    return (
      <div className="MegaContainer">
           <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          
          <div className="mainBlock">
          <div className='note2'>
        Note: In case you are processing licensed data, please make sure to follow license agreement with data provider.
        </div>
          <div className="blue1">
          <ul>
                 <li><h4><a href="/Wizzard">NLP Workbench (with wizzard for adding Hubble documents by id)</a></h4></li>
                 <li><h4><a href="/PredictNumSequence">Prediction of Numerical Sequences</a></h4></li>
                 </ul>
                 </div>
             <div className="one-sixth yellow">
                 <h3>Named entity recognition</h3>
                 <ul>
                 <li><a href="/annotatePDF">Annotate PDF using Linnaeus</a></li>
                 <li><a href="/annotateText">Annotate text using Linnaeus</a></li>
              <li><a href="/UMLS_annotate">Spacy UMLS annotator</a></li>
              <li><a href="/CRFNER_annotate">CRF annotator for compounds, genes and diseases</a></li>
              <li><a href="/CRFUMLS_annotate">CRF annotator with UMLS normalizer for compounds, genes and diseases</a></li>
              <li><a href="/MZ_annotate">Deep learning based NER (Marinika Zitnik)</a></li>

              <li><a href="/i2b2_Drug">Drug administration information (BERT)</a></li>

              <li><a href="https://docs.int.bayer.com/ae-detection-engine/" target='_blank'>AE Detection engine</a></li>

              <li> <a href="/TAC_ADR">Adverse drug reactions (BERT-based)</a></li>

              <li> <a href="/Chem_biobert">Chemicals and Drug NER (BioBERT)</a></li>
              <li><a href="/Gene_biobert">Gene NER (BioBERT)</a></li>
              <li><a  href="/Disease_biobert">Disease NER (BioBERT)</a></li>
              <li><a  href="/Ontonotes_ner">Ontonotes 18 entity types (Transformer-based)</a></li>
              <li><a href="/zero_shot_ner">Zero Shot NER (flair)</a></li>
              <li><a href="/ZeroShotBioNER">Zero Shot BioNER (Bayer+SIAI)</a></li>
              <li><a href="/RWE_NER">Real World Evidence DB NER</a></li>


              
                 </ul>
            </div> 
            <div className="one-sixth blue">
                 <h3>Named entity normalization</h3>
                 <ul>
                   <li><a href="/UMLS_annotate">UMLS NER and normalizer (Spacy)</a></li>
                  {/* <li><a href="/Ontology_norm">Ontology-based normalizer (SBERT)</a></li>*/}
                 </ul>
            </div> 
            <div className="one-sixth green">
                 <h3>Classification</h3>
                 <ul>
                   <li><a href="/Sentiment">Sentiment analysis (T5)</a></li>
                   <li><a href="/ZeroShotClassifier">Zero-shot classification</a></li>
                   <li><a href="/PICOClassifier">PICO classifier</a></li>
                   <li><a href="/InVivoClassifier">In Vivo classifier</a></li>
                   <li><a href="/TherUseClassifier">Therpeutic Use classifier</a></li>
                   
                   
                 </ul>
            </div>
            <div className="one-sixth gray">
                 <h3>Natural language understanding</h3>
                 <ul>
                 <li><a href="/Inference">Inference (T5,MNLI)</a></li>
                 <li><a href="/QuestionAnswer">Question Answering (T5)</a></li>
                 <li><a href="/RelationshipView.html">Relationship extraction</a></li>
                 </ul>
            </div> 
            <div className="one-sixth purple">
                 <h3>Text generation and summarization</h3>
                <ul>
                 <li><a href="/Summarization">Summarization (T5)</a></li>
                 <li><a href="/Translation">Translation (T5)</a></li>
                 <li><a href="/GPT4">GPT-4</a></li>
                 <li><a href="/GPT4_32k">GPT-4 32k</a></li>
                 <li><a href="/GPT4Turbo">GPT-4 Turbo</a></li>
                 <li><a href="/GPT4o">GPT-4o</a></li>
                 <li><a href="/GPT4oMini">GPT-4o Mini</a></li>
                 
                 <li><a href="/Claude2">Anthropic's Claude 2 LLM</a></li>
                 <li><a href="/Claude3">Anthropic's Claude 3 LLM (Sonnet)</a></li>
                 <li><a href="/Claude3Opus">Anthropic's Claude 3 LLM (Opus)</a></li>
                 <li><a href='/Claude35Sonnet'>Anthropic's Claude 3.5 Sonnet LLM</a></li>
                 <li><a href="/Llama2_13B">Llama 2 13B chat</a></li>
                 <li><a href="/Llama2_70B">Llama 2 70B chat</a></li>
                 <li><a href="/Llama3_70B">Llama 3 70B instruct</a></li>
                 <li><a href="/Llama31_70B">Llama 3.1 70B instruct</a></li>
                 
                 <li><a href="/Mixtral">Mistral Mixtral 8x7B</a></li>
                 <li><a href="/PALM2">Google PaLM 2 (bison)</a></li>
                 <li><a href="/PALM2_unicorn">Google PaLM 2 (unicorn)</a></li>
                 <li><a href="/Gemini_pro">Google Gemini pro</a></li>
                 <li><a href="/Gemini_pro15">Google Gemini pro 1.5</a></li>
                 
                 <li><a href="/HubbleGPTQA">GPT-3.5-based QA using Hubble</a></li>
                 <li><a href="/HubbleGPT4QA">GPT-4-based QA using Hubble</a></li>
                 <li><a href="https://factfinder.linguist.skgt.int.bayer.com/" target="_blank">FactFinder</a></li>
                 
                 </ul>
            </div>
            
        </div>
        <br/>
       
        </div>
        
      </div>
    )
  }
}

export default Home;