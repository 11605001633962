import React, {Component} from 'react';
import DeleteIcon from "./Delete.png";
import {java_api} from "./APIs.js";

class PrivateOntologyView extends Component {
    
    loadDics() {
        const request = require('superagent');
    
        const req = request.post(java_api+'/getprivatedics').set("Authorization",localStorage.getItem("cognito_token"))
        req.send({"username":sessionStorage.getItem("username")});
        req.end((end,res)=>{
          
          if(res.body.message == "The incoming token has expired")
         {
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';      
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
         }
          if (res==undefined && end !== undefined){
            window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';      
            //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
          }
          console.log(res);
          if(res.body.user_dictionaries!=null){
          for (const [key, value] of Object.entries(res.body.user_dictionaries)) {
            this.state.dobjects.push(key);
            
          }
          this.state.tips = res.body.user_dictionaries ;
          localStorage.setItem('priv_dics',this.state.dobjects);
          this.forceUpdate();
            }});
        }
    
  constructor() {
    super();
    this.loadDics = this.loadDics.bind(this);
    this.BoxRemoveHandler = this.BoxRemoveHandler.bind(this);
   
    this.state = {
      files: [],
      dobjects: [],
      tips:{}
    };
  }


  componentDidMount(){
    this.loadDics();

    
  }
  BoxRemoveHandler(event) {
    var shouldDelete = window.confirm('Do you really want to delete ' + event.target.id + ' dictionary?');
    if (shouldDelete) {
      var path = event.target.id;
      const request = require('superagent');
    
        const req = request.post(java_api+'/deletedic').set("Authorization",localStorage.getItem("cognito_token"));
        req.send({"path":path});
        req.end((end,res)=>{
          console.log(res);
          this.forceUpdate();
        window.location.reload(false);
        });

    }
  }



  checkboxChange(event) {
    if (document.getElementById(event.target.id).checked == true) {
      document.getElementById(event.target.id).value = 'on';
      document.getElementById(event.target.id).checked = true;
      var pub_dics = localStorage.getItem('priv_dics').split(",");
      pub_dics.push(event.target.id);
      localStorage.setItem('priv_dics',pub_dics);
      console.log(pub_dics);
      
    } else {
      document.getElementById(event.target.id).value = 'off';
      document.getElementById(event.target.id).checked = false;
      var pub_dics = localStorage.getItem('priv_dics').split(",");
      const index = pub_dics.indexOf(event.target.id);
        if (index > -1) {
            pub_dics.splice(index, 1);
        }
        console.log(pub_dics);
        localStorage.setItem('priv_dics',pub_dics);
    }
}


  render() {
    const dics = this.state.dobjects.map(file => (
      <div>
        <span title={this.state.tips[file]}><input type="checkbox" id={file} name={file} defaultChecked='true'
         onClick={this.checkboxChange} ></input>
         <label for={file}>{file}</label></span><a href="#"><img id={sessionStorage.getItem("username")+"/"+file} alt="Remove this dictionary" onClick={this.BoxRemoveHandler} title="Remove this dictionary" className="DeleteIcon" src={DeleteIcon}></img></a>
         </div>

    ));

    return (
      <div className="public_dictionaries">
          <h3>Private dictionaries</h3>
          {dics}
      </div>
    );
  }
}

export default PrivateOntologyView;