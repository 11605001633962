import React, { Component } from 'react';
import logo from '../Linguist-logo.png';

import SentimentAnnotationField from './SentimentAnnotationField.js';
import TopMenu from '../TopMenu.js';

class SentimentAnnotateText extends Component {
    constructor() {
        super();
       
        this.state = {
          files: [],
          dobjects: []
        };
      }
    


  render() {
    return (
      <div className="MegaContainer">
          <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          <div className="mainBlock">
          <div className="mainLeft">
            <SentimentAnnotationField/>
        </div>
        <div className="MainRight">
          

        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default SentimentAnnotateText;