import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {gpu_api} from '../APIs.js';

class OntologyUploader extends Component {
    
  constructor() {
    super();

    this.onDrop = (files) => {
      const request = require('superagent');
      this.setState({files});
      files.forEach(file => {
        var data = {user:sessionStorage.getItem("username")};
        const req = request.post(gpu_api + '/uploadfile/?user='+sessionStorage.getItem("username")).set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.attach('file', file);
      req.end((end,res)=>{
        
        console.log(res);
        //this.state.dobjects.push({name:res.body.url,url:'http://localhost:8080/downloadFile?docName='+res.body.url});
        this.forceUpdate();
        window.location.reload(false);
      });
  })

      
    };
    this.state = {
      files: [],
      dobjects: []
    };
  }
  
  render() {
    const files = this.state.dobjects.map(file => (
      <li key={file.name}>
        <a href={file.url}>{file.name}</a>
      </li>
    ));

    return (
      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container2">
            <div {...getRootProps({className: 'dropzone2'})}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
              <h4></h4>
              <ul>{files}</ul>
            </aside>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default OntologyUploader;