import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";


class GPTCodeField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }


  

  ButtonSubmit(event) {
    const request = require('superagent');
    document.getElementById('marked_text').style.visibility='hidden';
document.getElementById("typing_imga").style.visibility = "visible";
      var text = document.getElementById('input_text').value;
      var temperature = document.getElementById('temperature').value;
      var max_tokens = document.getElementById('max_tokens').value;
      var stop_seq =  document.getElementById('stop_seq').value;
      if(text == "")return;
      console.log(text);
      const req = request.post(gpu_api + '/davinci_code').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"prompt":text,"temperature":temperature,"max_tokens":max_tokens,"stop_seq":stop_seq});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          if (end.status == 404){
            alert("Request failed. Please refresh page and try again")
          }else{
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          }
        }
        console.log(res.body);
        var annotations = res.body;

        var marked_text = "<code>"+ res.body.text +"</code>";
        marked_text = marked_text.replace(new RegExp("\r\n", 'g'), "<br/>");
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById("typing_imga").style.visibility = "hidden";
document.getElementById('marked_text').style.visibility='visible';
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Large Language Model for code generation (davinci_code_002)</h3>
          <label className="inputlabel" for="temperature">Temperature (measure of model creativity, it should be between 0 and 1, where 0 is least creative):</label><br/>
          <input type="number" step="0.01" id="temperature" className="year_inputs_pred" name="temperature" 
                   defaultValue="0.8"></input><br/><br/><br/>
          <label className="inputlabel" for="max_tokens">Maximum number of output tokens:</label>
          <input type="number" step="1" id="max_tokens" className="year_inputs_pred" name="max_tokens" 
                   defaultValue="350"></input><br/><br/>
          <label className="inputlabel" for="stop_seq">Stop character sequence:</label>
          <input id="stop_seq" className="year_inputs_pred" name="stop_seq" 
                   defaultValue="###"></input><br/><br/>
          <h3>Imput text for prompt:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#gptcode">Documentation about making API calls</a></div>
          <div className='typing'><img id="typing_imga" className='typing_img' src={'/typing.gif'} /></div>
          <div id='marked_text' className="marked_text"></div>
          <div id='entity_table' className="entity_table">

          </div>

          <div id="doc">Please note that GPT Davinci code generation model is hosted by Azure cloud, however, we have opted out from content filtering and abuse monitoring with Microsoft, therefore no submitted data should be logged anywhere. Feel free to review documentation <a href='https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy' target="_blank">here</a>. Please refrain from uploading personal or secret business data or information. <br/>
          Please also review Bayer's guidelines for using GPT models <a href="https://bayernet.int.bayer.com/-/media/bag-intra/ws_bayernet/global/shared/news/2023/09/generative_ai_guidance-and-rules_final_en.pdf" target="_blank">here</a>
          </div>
      </div>
    );
  }
}

export default GPTCodeField;