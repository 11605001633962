import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './AuthProvider';
import my_url from './APIs'
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";



ReactDOM.render(
<BrowserRouter>
    <AzureAD provider={authProvider} forceLogin={false}>
        {
          
            ({login, logout, authenticationState, error, accountInfo})  => {
              
              //const poolData = {UserPoolId: "eu-central-1_NEJOtBGTH", ClientId: "1opjprr5da5hmchq24ri8jnjhi"};
              //const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
              if(localStorage.getItem('cognito_token')==undefined){
                window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
              }

        console.log('>>>>>>>>>>>>...', accountInfo);
        if(authenticationState=='Unauthenticated')
            {
         // window.location.href = "https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com";

        login();
        return "Unauthenticated"
        }
        else{
          //var accessToken = useAccessToken();
          sessionStorage.setItem("username",accountInfo.account.userName);
          localStorage.setItem('AD_token',accountInfo.jwtIdToken);
        }
        
        return <App />;
            }
                

    }
        
        </AzureAD>
    </BrowserRouter>,
    
    document.getElementById('root')


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
