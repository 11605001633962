import React, { Component } from 'react';
import { gpu_api, java_api, nlp_machine_api } from './APIs';
import logo from './Linguist-logo.png';
import TopMenu from './TopMenu.js';


class NLPScaleDocumentation extends Component {
    constructor() {
        super();
       
        this.state = {
          files: [],
          dobjects: []
        };
      }
      componentDidMount(){
        var nlp_root = "https://nlp-api.skgt.int.bayer.com";

        var core_nlp_tokenizer_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Your text here"
                }
              ]
            },
            "config": {}
          }
        var core_nlp_tokenizer_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Your text here",
                "token_annotations": {
                  "core_nlp_tokenizer": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 4
                    },
                    {
                      "tid": 5,
                      "start_offset": 5,
                      "end_offset": 9
                    },
                    {
                      "tid": 10,
                      "start_offset": 10,
                      "end_offset": 14
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          document.getElementById("core_nlp_tokenizer_input").innerHTML = JSON.stringify(core_nlp_tokenizer_in, undefined, 2);
          document.getElementById("core_nlp_tokenizer_output").innerHTML = JSON.stringify(core_nlp_tokenizer_out, undefined, 2);
          var spacy_tokenizer_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Lorem ipsum dolor sit amet."
                }
              ]
            },
            "config": {
              "MODEL_NAME": "en_core_sci_md",
              "INFERENCESERVICE_NAME": "spacy-token-annotator",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local"
            }
          }
        var spacy_tokenizer_out = {
            "id": "id",
            "sections": [
              {
                "raw_text": "Lorem ipsum dolor sit amet.",
                "token_annotations": {
                  "TokenAnnotatorSpacy": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 5
                    },
                    {
                      "tid": 6,
                      "start_offset": 6,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 17
                    },
                    {
                      "tid": 18,
                      "start_offset": 18,
                      "end_offset": 21
                    },
                    {
                      "tid": 22,
                      "start_offset": 22,
                      "end_offset": 26
                    },
                    {
                      "tid": 26,
                      "start_offset": 26,
                      "end_offset": 27
                    }
                  ]
                }
              }
            ]
          }
          document.getElementById("spacy_tokenizer_input").innerHTML = JSON.stringify(spacy_tokenizer_in, undefined, 2);
          document.getElementById("spacy_tokenizer_output").innerHTML = JSON.stringify(spacy_tokenizer_out, undefined, 2);   
          var sentence_splitter_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Lorem ipsum. Sed amet.",
                  "token_annotations": {
                    "someTokenAnnotator": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 5
                      },
                      {
                        "tid": 6,
                        "start_offset": 6,
                        "end_offset": 11
                      },
                      {
                        "tid": 11,
                        "start_offset": 11,
                        "end_offset": 12
                      },
                      {
                        "tid": 13,
                        "start_offset": 13,
                        "end_offset": 16
                      },
                      {
                        "tid": 17,
                        "start_offset": 17,
                        "end_offset": 21
                      },
                      {
                        "tid": 21,
                        "start_offset": 21,
                        "end_offset": 22
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "TOKEN_ANNOTATIONS_TO_USE": "someTokenAnnotator"
            }
          }
        var sentence_splitter_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Lorem ipsum. Sed amet.",
                "token_annotations": {
                  "someTokenAnnotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 5
                    },
                    {
                      "tid": 6,
                      "start_offset": 6,
                      "end_offset": 11
                    },
                    {
                      "tid": 11,
                      "start_offset": 11,
                      "end_offset": 12
                    },
                    {
                      "tid": 13,
                      "start_offset": 13,
                      "end_offset": 16
                    },
                    {
                      "tid": 17,
                      "start_offset": 17,
                      "end_offset": 21
                    },
                    {
                      "tid": 21,
                      "start_offset": 21,
                      "end_offset": 22
                    }
                  ]
                },
                "sentence_annotations": {
                  "core_nlp_sentence_annotator": [
                    {
                      "sid": 0,
                      "start_token": 0,
                      "end_token": 11
                    },
                    {
                      "sid": 13,
                      "start_token": 13,
                      "end_token": 21
                    }
                  ]
                },
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }   
          document.getElementById("sentence_splitter_input").innerHTML = JSON.stringify(sentence_splitter_in, undefined, 2);
          document.getElementById("sentence_splitter_output").innerHTML = JSON.stringify(sentence_splitter_out, undefined, 2); 
          
          var lemmatizer_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "The patient took aspirin for his headache.",
                  "token_annotations": {
                    "someTokenAnnotator": [
                        {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 16
                    },
                    {
                      "tid": 17,
                      "start_offset": 17,
                      "end_offset": 24
                    },
                    {
                      "tid": 25,
                      "start_offset": 25,
                      "end_offset": 28
                    },
                    {
                      "tid": 29,
                      "start_offset": 29,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 41
                    },
                    {
                      "tid": 41,
                      "start_offset": 41,
                      "end_offset": 42
                    }
                    ]
                  },
                  "sentence_annotations": {
                    "someSentenceAnnotator": [
                      {
                        "sid": 0,
                        "start_token": 0,
                        "end_token": 41
                      }
                    ]
                  },
                  "pos_annotations": {
                    "somePosTagAnnotator": [
                      {
                      "tid": 0,
                      "tag": "DET"
                    },
                    {
                      "tid": 4,
                      "tag": "NOUN"
                    },
                    {
                      "tid": 12,
                      "tag": "VERB"
                    },
                    {
                      "tid": 17,
                      "tag": "NOUN"
                    },
                    {
                      "tid": 25,
                      "tag": "ADP"
                    },
                    {
                      "tid": 29,
                      "tag": "PRON"
                    },
                    {
                      "tid": 33,
                      "tag": "NOUN"
                    },
                    {
                      "tid": 41,
                      "tag": "PUNCT"
                    }
                    ]
                  }
                }
              ]
            },
            "config": {
              "TOKENS_TO_USE": "someTokenAnnotator",
              "SENTENCES_TO_USE": "someSentenceAnnotator",
              "POS_TAGS_TO_USE": "somePosTagAnnotator"
            }
          }
        var lemmatizer_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "The patient took aspirin for his headache.",
                "token_annotations": {
                  "someTokenAnnotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 16
                    },
                    {
                      "tid": 17,
                      "start_offset": 17,
                      "end_offset": 24
                    },
                    {
                      "tid": 25,
                      "start_offset": 25,
                      "end_offset": 28
                    },
                    {
                      "tid": 29,
                      "start_offset": 29,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 41
                    },
                    {
                      "tid": 41,
                      "start_offset": 41,
                      "end_offset": 42
                    }
                  ]
                },
                "sentence_annotations": {
                  "someSentenceAnnotator": [
                    {
                      "sid": 0,
                      "start_token": 0,
                      "end_token": 41
                    }
                  ]
                },
                "pos_annotations": {
                  "somePosTagAnnotator": [
                    {
                      "tid": 0,
                      "tag": "DET"
                    },
                    {
                      "tid": 4,
                      "tag": "NOUN"
                    },
                    {
                      "tid": 12,
                      "tag": "VERB"
                    },
                    {
                      "tid": 17,
                      "tag": "NOUN"
                    },
                    {
                      "tid": 25,
                      "tag": "ADP"
                    },
                    {
                      "tid": 29,
                      "tag": "PRON"
                    },
                    {
                      "tid": 33,
                      "tag": "NOUN"
                    },
                    {
                      "tid": 41,
                      "tag": "PUNCT"
                    }
                  ]
                },
                "lemma_annotations": {
                  "core_nlp_lemmatizer": [
                    {
                      "tid": 0,
                      "lemma": "the"
                    },
                    {
                      "tid": 4,
                      "lemma": "patient"
                    },
                    {
                      "tid": 12,
                      "lemma": "take"
                    },
                    {
                      "tid": 17,
                      "lemma": "aspirin"
                    },
                    {
                      "tid": 25,
                      "lemma": "for"
                    },
                    {
                      "tid": 29,
                      "lemma": "he"
                    },
                    {
                      "tid": 33,
                      "lemma": "headache"
                    },
                    {
                      "tid": 41,
                      "lemma": "."
                    }
                  ]
                },
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          document.getElementById("lemmatizer_input").innerHTML = JSON.stringify(lemmatizer_in, undefined, 2);
          document.getElementById("lemmatizer_output").innerHTML = JSON.stringify(lemmatizer_out, undefined, 2);  
          var spacy_pos_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "He was working after 5pm",
                  "token_annotations": {
                     "spacy_token_annotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 2
                    },
                    {
                      "tid": 3,
                      "start_offset": 3,
                      "end_offset": 6
                    },
                    {
                      "tid": 7,
                      "start_offset": 7,
                      "end_offset": 14
                    },
                    {
                      "tid": 15,
                      "start_offset": 15,
                      "end_offset": 20
                    },
                    {
                      "tid": 21,
                      "start_offset": 21,
                      "end_offset": 22
                    },
                    {
                      "tid": 22,
                      "start_offset": 22,
                      "end_offset": 24
                    }
                  ]
                  }
                }
              ]
            },
            "config": {
              "INFERENCESERVICE_NAME": "spacy-postag-annotator",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "en_core_sci_lg"
            }
          }
              
         var  spacy_pos_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "He was working after 5pm",
                "token_annotations": {
                  "spacy_token_annotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 2
                    },
                    {
                      "tid": 3,
                      "start_offset": 3,
                      "end_offset": 6
                    },
                    {
                      "tid": 7,
                      "start_offset": 7,
                      "end_offset": 14
                    },
                    {
                      "tid": 15,
                      "start_offset": 15,
                      "end_offset": 20
                    },
                    {
                      "tid": 21,
                      "start_offset": 21,
                      "end_offset": 22
                    },
                    {
                      "tid": 22,
                      "start_offset": 22,
                      "end_offset": 24
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {
                  "spacy_pos_tag_annotator": [
                    {
                      "tid": 0,
                      "tag": "PRON"
                    },
                    {
                      "tid": 3,
                      "tag": "AUX"
                    },
                    {
                      "tid": 7,
                      "tag": "VERB"
                    },
                    {
                      "tid": 15,
                      "tag": "ADP"
                    },
                    {
                      "tid": 21,
                      "tag": "NUM"
                    },
                    {
                      "tid": 22,
                      "tag": "NOUN"
                    }
                  ]
                },
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }

          document.getElementById("spacy_pos_input").innerHTML = JSON.stringify(spacy_pos_in, undefined, 2);
          document.getElementById("spacy_pos_output").innerHTML = JSON.stringify(spacy_pos_out, undefined, 2);
          
          var corenlp_pos_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "He was working after 5pm",
                  "token_annotations": {
                    "com.bayer.nlp.operators.TokenAnnotatorCoreNlp": [
                      {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 2
                    },
                    {
                      "tid": 3,
                      "start_offset": 3,
                      "end_offset": 6
                    },
                    {
                      "tid": 7,
                      "start_offset": 7,
                      "end_offset": 14
                    },
                    {
                      "tid": 15,
                      "start_offset": 15,
                      "end_offset": 20
                    },
                    {
                      "tid": 21,
                      "start_offset": 21,
                      "end_offset": 22
                    },
                    {
                      "tid": 22,
                      "start_offset": 22,
                      "end_offset": 24
                    }
          
                    ]
                  },
                  "sentence_annotations": {
                    "com.bayer.nlp.operators.SentenceAnnotatorCoreNlp": [
                      {
                        "sid": 0,
                        "start_token": 0,
                        "end_token": 24
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "TOKENS_TO_USE": "com.bayer.nlp.operators.TokenAnnotatorCoreNlp"
            }
          }
        var corenlp_pos_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "He was working after 5pm",
                "token_annotations": {
                  "com.bayer.nlp.operators.TokenAnnotatorCoreNlp": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 2
                    },
                    {
                      "tid": 3,
                      "start_offset": 3,
                      "end_offset": 6
                    },
                    {
                      "tid": 7,
                      "start_offset": 7,
                      "end_offset": 14
                    },
                    {
                      "tid": 15,
                      "start_offset": 15,
                      "end_offset": 20
                    },
                    {
                      "tid": 21,
                      "start_offset": 21,
                      "end_offset": 22
                    },
                    {
                      "tid": 22,
                      "start_offset": 22,
                      "end_offset": 24
                    }
                  ]
                },
                "sentence_annotations": {
                  "com.bayer.nlp.operators.SentenceAnnotatorCoreNlp": [
                    {
                      "sid": 0,
                      "start_token": 0,
                      "end_token": 24
                    }
                  ]
                },
                "pos_annotations": {
                  "core_nlp_pos_tagger": [
                    {
                      "tid": 0,
                      "tag": "PRP"
                    },
                    {
                      "tid": 3,
                      "tag": "VBD"
                    },
                    {
                      "tid": 7,
                      "tag": "VBG"
                    },
                    {
                      "tid": 15,
                      "tag": "IN"
                    },
                    {
                      "tid": 21,
                      "tag": "CD"
                    },
                    {
                      "tid": 22,
                      "tag": "NN"
                    }
                  ]
                },
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }

          document.getElementById("corenlp_pos_input").innerHTML = JSON.stringify(corenlp_pos_in, undefined, 2);
          document.getElementById("corenlp_pos_output").innerHTML = JSON.stringify(corenlp_pos_out, undefined, 2);

          var mark_removal_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Lorem IPSUM. Sed <i>amet.</i>"
                }
              ]
            },
            "config": {}
          }
        var mark_removal_out = {
            "sections": [
              {
                "raw_text": "Lorem IPSUM. Sed amet.",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }

          document.getElementById("mark_removal_input").innerHTML = JSON.stringify(mark_removal_in, undefined, 2);
          document.getElementById("mark_removal_output").innerHTML = JSON.stringify(mark_removal_out, undefined, 2);

          var regex_ner_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Lorem IPSUM. Sed amet.",
                  "token_annotations": {
                    "someTokenAnnotator": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 5
                      },
                      {
                        "tid": 6,
                        "start_offset": 6,
                        "end_offset": 11
                      },
                      {
                        "tid": 11,
                        "start_offset": 11,
                        "end_offset": 12
                      },
                      {
                        "tid": 13,
                        "start_offset": 13,
                        "end_offset": 16
                      },
                      {
                        "tid": 17,
                        "start_offset": 17,
                        "end_offset": 21
                      },
                      {
                        "tid": 21,
                        "start_offset": 21,
                        "end_offset": 22
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "regexes": "[{ \"pattern\": \"[A-Z]{2,}\", \"type\": \"caps\", \"tag\": \"CAPS\" }]"
            }
          }
          var regex_ner_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Lorem IPSUM. Sed amet.",
                "token_annotations": {
                  "someTokenAnnotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 5
                    },
                    {
                      "tid": 6,
                      "start_offset": 6,
                      "end_offset": 11
                    },
                    {
                      "tid": 11,
                      "start_offset": 11,
                      "end_offset": 12
                    },
                    {
                      "tid": 13,
                      "start_offset": 13,
                      "end_offset": 16
                    },
                    {
                      "tid": 17,
                      "start_offset": 17,
                      "end_offset": 21
                    },
                    {
                      "tid": 21,
                      "start_offset": 21,
                      "end_offset": 22
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "regex_ner_annotator": [
                    {
                      "entity": "IPSUM",
                      "start_offset": 6,
                      "end_offset": 11,
                      "eid": "CAPS-6-11",
                      "type": "caps",
                      "synonyms": [],
                      "concept_id": "CAPS",
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          document.getElementById("regex_ner_input").innerHTML = JSON.stringify(regex_ner_in, undefined, 2);
          document.getElementById("regex_ner_output").innerHTML = JSON.stringify(regex_ner_out, undefined, 2);

          var spacy_ner_in = {
            "document": {
              "sections": [
                {
                  "raw_text": "Myeloid derived suppressor cells (MDSC) are immature myeloid cells with immunosuppressive activity.",
                  "token_annotations": {
                    "spacy_token_annotator": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 7
                      },
                      {
                        "tid": 8,
                        "start_offset": 8,
                        "end_offset": 15
                      },
                      {
                        "tid": 16,
                        "start_offset": 16,
                        "end_offset": 26
                      },
                      {
                        "tid": 27,
                        "start_offset": 27,
                        "end_offset": 32
                      },
                      {
                        "tid": 33,
                        "start_offset": 33,
                        "end_offset": 34
                      },
                      {
                        "tid": 34,
                        "start_offset": 34,
                        "end_offset": 38
                      },
                      {
                        "tid": 38,
                        "start_offset": 38,
                        "end_offset": 39
                      },
                      {
                        "tid": 40,
                        "start_offset": 40,
                        "end_offset": 43
                      },
                      {
                        "tid": 44,
                        "start_offset": 44,
                        "end_offset": 52
                      },
                      {
                        "tid": 53,
                        "start_offset": 53,
                        "end_offset": 60
                      },
                      {
                        "tid": 61,
                        "start_offset": 61,
                        "end_offset": 66
                      },
                      {
                        "tid": 67,
                        "start_offset": 67,
                        "end_offset": 71
                      },
                      {
                        "tid": 72,
                        "start_offset": 72,
                        "end_offset": 89
                      },
                      {
                        "tid": 90,
                        "start_offset": 90,
                        "end_offset": 98
                      },
                      {
                        "tid": 98,
                        "start_offset": 98,
                        "end_offset": 99
                      }
                    ]
                  },
                  "sentence_annotations": {},
                  "pos_annotations": {},
                  "lemma_annotations": {},
                  "ner_annotations": {},
                  "entity_link_annotations": {},
                  "nen_umls_link_annotations": {},
                  "rel_dep_annotations": {},
                  "document_classes": {},
                  "abbreviation_annotations": {},
                  "ngram_annotations": {}
                }
              ],
              "id": "id"
            },
            "config": {
              "INFERENCESERVICE_NAME": "spacy-nertag-annotator",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "en_core_sci_md"
            }
          }
          var spacy_ner_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Myeloid derived suppressor cells (MDSC) are immature myeloid cells with immunosuppressive activity.",
                "token_annotations": {
                  "spacy_token_annotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 7
                    },
                    {
                      "tid": 8,
                      "start_offset": 8,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 34
                    },
                    {
                      "tid": 34,
                      "start_offset": 34,
                      "end_offset": 38
                    },
                    {
                      "tid": 38,
                      "start_offset": 38,
                      "end_offset": 39
                    },
                    {
                      "tid": 40,
                      "start_offset": 40,
                      "end_offset": 43
                    },
                    {
                      "tid": 44,
                      "start_offset": 44,
                      "end_offset": 52
                    },
                    {
                      "tid": 53,
                      "start_offset": 53,
                      "end_offset": 60
                    },
                    {
                      "tid": 61,
                      "start_offset": 61,
                      "end_offset": 66
                    },
                    {
                      "tid": 67,
                      "start_offset": 67,
                      "end_offset": 71
                    },
                    {
                      "tid": 72,
                      "start_offset": 72,
                      "end_offset": 89
                    },
                    {
                      "tid": 90,
                      "start_offset": 90,
                      "end_offset": 98
                    },
                    {
                      "tid": 98,
                      "start_offset": 98,
                      "end_offset": 99
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "spacy_nertag_annotator": [
                    {
                      "entity": "Myeloid",
                      "start_offset": 0,
                      "end_offset": 7,
                      "eid": "ENTITY-0-7",
                      "type": "ENTITY",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "suppressor cells",
                      "start_offset": 16,
                      "end_offset": 32,
                      "eid": "ENTITY-16-32",
                      "type": "ENTITY",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "MDSC",
                      "start_offset": 34,
                      "end_offset": 38,
                      "eid": "ENTITY-34-38",
                      "type": "ENTITY",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "immature",
                      "start_offset": 44,
                      "end_offset": 52,
                      "eid": "ENTITY-44-52",
                      "type": "ENTITY",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "myeloid cells",
                      "start_offset": 53,
                      "end_offset": 66,
                      "eid": "ENTITY-53-66",
                      "type": "ENTITY",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "immunosuppressive activity",
                      "start_offset": 72,
                      "end_offset": 98,
                      "eid": "ENTITY-72-98",
                      "type": "ENTITY",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          document.getElementById("spacy_ner_input").innerHTML = JSON.stringify(spacy_ner_in, undefined, 2);
          document.getElementById("spacy_ner_output").innerHTML = JSON.stringify(spacy_ner_out, undefined, 2);

          var termite_ner_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "The patient was having issues with headache and therefore doctor prescribed aspirin"
                }
              ]
            },
            "config": {
              "DICT_PATH": "s3://configuration-dictionaries-296967126277/production/dictionaries",
              "LICENSE_PATH": "s3://configuration-dictionaries-296967126277/termite.lic",
              "DICTIONARY_TYPES": "sci_drug,sci_disease"
            }
          }
        var termite_ner_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "The patient was having issues with headache and therefore doctor prescribed aspirin",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "termite_ner_tagger": [
                    {
                      "entity": "Aspirin",
                      "start_offset": 76,
                      "end_offset": 83,
                      "eid": "DR:BAYPHTH0000108-76-83",
                      "type": "SCI_DRUG",
                      "synonyms": [],
                      "concept_id": "DR:BAYPHTH0000108",
                      "metadata": null
                    },
                    {
                      "entity": "headache",
                      "start_offset": 35,
                      "end_offset": 43,
                      "eid": "SD:BAYPHTH009370-35-43",
                      "type": "SCI_DISEASE",
                      "synonyms": [],
                      "concept_id": "SD:BAYPHTH009370",
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          document.getElementById("termite_ner_input").innerHTML = JSON.stringify(termite_ner_in, undefined, 2);
          document.getElementById("termite_ner_output").innerHTML = JSON.stringify(termite_ner_out, undefined, 2); 

          var linnaeus_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "The patient was prescribed with aspirin."
                }
              ]
            },
            "config": {
              "dictionary_directory": "s3://configuration-dictionaries-296967126277/nlp/main/dictionaries-linnaeus/",
              "dictionaries": "sci_drug_dictionary.txt",
              "matcher_types": "bay_matcher"
            }
          }
          var linnaeus_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "The patient was prescribed with aspirin.",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "linnaeus_tagger": [
                    {
                      "entity": "Aspirin",
                      "start_offset": 32,
                      "end_offset": 39,
                      "eid": "DR:BAYPHTH0007340-32-39",
                      "type": "Drug",
                      "synonyms": [],
                      "concept_id": "DR:BAYPHTH0007340",
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }

          document.getElementById("linnaeus_input").innerHTML = JSON.stringify(linnaeus_in, undefined, 2);
          document.getElementById("linnaeus_output").innerHTML = JSON.stringify(linnaeus_out, undefined, 2); 
          var multibioner_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "The patient took aspirin for his headache.",
                  "token_annotations": {
                    "someTokenAnnotator": [
                        {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 16
                    },
                    {
                      "tid": 17,
                      "start_offset": 17,
                      "end_offset": 24
                    },
                    {
                      "tid": 25,
                      "start_offset": 25,
                      "end_offset": 28
                    },
                    {
                      "tid": 29,
                      "start_offset": 29,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 41
                    },
                    {
                      "tid": 41,
                      "start_offset": 41,
                      "end_offset": 42
                    }
                    ]
                  }
          
                }
              ]
            },
          
            "config": {
              "INFERENCESERVICE_NAME": "torch-multi-bio-ner",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "multi-bio-ner"
            }
          }
        var multibioner_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "The patient took aspirin for his headache.",
                "token_annotations": {
                  "someTokenAnnotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 16
                    },
                    {
                      "tid": 17,
                      "start_offset": 17,
                      "end_offset": 24
                    },
                    {
                      "tid": 25,
                      "start_offset": 25,
                      "end_offset": 28
                    },
                    {
                      "tid": 29,
                      "start_offset": 29,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 41
                    },
                    {
                      "tid": 41,
                      "start_offset": 41,
                      "end_offset": 42
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "multi-bio-ner-tagger": [
                    {
                      "entity": "aspirin",
                      "start_offset": 17,
                      "end_offset": 24,
                      "eid": "aspirin-17-24",
                      "type": "Chemical",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "aspirin",
                      "start_offset": 17,
                      "end_offset": 24,
                      "eid": "aspirin-17-24",
                      "type": "Chemical",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "headache",
                      "start_offset": 33,
                      "end_offset": 41,
                      "eid": "headache-33-41",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "headache",
                      "start_offset": 33,
                      "end_offset": 41,
                      "eid": "headache-33-41",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          document.getElementById("multibioner_input").innerHTML = JSON.stringify(multibioner_in, undefined, 2);
          document.getElementById("multibioner_output").innerHTML = JSON.stringify(multibioner_out, undefined, 2); 
          var spacy_abbr_ner_in = {
            "document": {
              "sections": [
                {
                  "raw_text": "Myeloid derived suppressor cells (MDSC) are immature myeloid cells with immunosuppressive activity.",
                  "token_annotations": {
                    "spacy_token_annotator": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 7
                      },
                      {
                        "tid": 8,
                        "start_offset": 8,
                        "end_offset": 15
                      },
                      {
                        "tid": 16,
                        "start_offset": 16,
                        "end_offset": 26
                      },
                      {
                        "tid": 27,
                        "start_offset": 27,
                        "end_offset": 32
                      },
                      {
                        "tid": 33,
                        "start_offset": 33,
                        "end_offset": 34
                      },
                      {
                        "tid": 34,
                        "start_offset": 34,
                        "end_offset": 38
                      },
                      {
                        "tid": 38,
                        "start_offset": 38,
                        "end_offset": 39
                      },
                      {
                        "tid": 40,
                        "start_offset": 40,
                        "end_offset": 43
                      },
                      {
                        "tid": 44,
                        "start_offset": 44,
                        "end_offset": 52
                      },
                      {
                        "tid": 53,
                        "start_offset": 53,
                        "end_offset": 60
                      },
                      {
                        "tid": 61,
                        "start_offset": 61,
                        "end_offset": 66
                      },
                      {
                        "tid": 67,
                        "start_offset": 67,
                        "end_offset": 71
                      },
                      {
                        "tid": 72,
                        "start_offset": 72,
                        "end_offset": 89
                      },
                      {
                        "tid": 90,
                        "start_offset": 90,
                        "end_offset": 98
                      },
                      {
                        "tid": 98,
                        "start_offset": 98,
                        "end_offset": 99
                      }
                    ]
                  },
                  "sentence_annotations": {},
                  "pos_annotations": {},
                  "lemma_annotations": {},
                  "ner_annotations": {},
                  "entity_link_annotations": {},
                  "nen_umls_link_annotations": {},
                  "rel_dep_annotations": {},
                  "document_classes": {},
                  "abbreviation_annotations": {},
                  "ngram_annotations": {}
                }
              ],
              "id": "id"
            },
            "config": {
              "MODEL_NAME": "en_core_sci_lg",
              "INFERENCESERVICE_NAME": "spacy-abbreviation-annotator",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local"
            }
          }
          
          var spacy_abbr_ner_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Myeloid derived suppressor cells (MDSC) are immature myeloid cells with immunosuppressive activity.",
                "token_annotations": {
                  "spacy_token_annotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 7
                    },
                    {
                      "tid": 8,
                      "start_offset": 8,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 34
                    },
                    {
                      "tid": 34,
                      "start_offset": 34,
                      "end_offset": 38
                    },
                    {
                      "tid": 38,
                      "start_offset": 38,
                      "end_offset": 39
                    },
                    {
                      "tid": 40,
                      "start_offset": 40,
                      "end_offset": 43
                    },
                    {
                      "tid": 44,
                      "start_offset": 44,
                      "end_offset": 52
                    },
                    {
                      "tid": 53,
                      "start_offset": 53,
                      "end_offset": 60
                    },
                    {
                      "tid": 61,
                      "start_offset": 61,
                      "end_offset": 66
                    },
                    {
                      "tid": 67,
                      "start_offset": 67,
                      "end_offset": 71
                    },
                    {
                      "tid": 72,
                      "start_offset": 72,
                      "end_offset": 89
                    },
                    {
                      "tid": 90,
                      "start_offset": 90,
                      "end_offset": 98
                    },
                    {
                      "tid": 98,
                      "start_offset": 98,
                      "end_offset": 99
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {
                  "spacy_abbreviation_annotator": [
                    {
                      "start_offset": 34,
                      "end_offset": 38,
                      "abbreviation": "MDSC",
                      "definition": "Myeloid derived suppressor cells"
                    }
                  ]
                },
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          

          document.getElementById("spacy_abbr_ner_input").innerHTML = JSON.stringify(spacy_abbr_ner_in, undefined, 2);
          document.getElementById("spacy_abbr_ner_output").innerHTML = JSON.stringify(spacy_abbr_ner_out, undefined, 2);

          var rename_ner_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "MicroRNAs (miRNAs), a class of small non-coding RNAs, regulate gene expression by targeting specific mRNAs to trigger translational repression or mRNA degradation. MiR-22 is aberrantly expressed in human ovarian cancer compared to normal tissues, and it plays an important role in regulating ovarian cancer metastasis. However, the concrete mechanism underlying the regulation of ovarian cancer metastasis by miR-22 remains ambiguous. In this study, the effects of miR-22 on metastasis and metastasis-associated targets were investigated in the human ovarian cancer cell line SKOV-3. Using a transwell migration assay and a cell wound healing assay, we discovered that miR-22 inhibited metastasis. A bioinformatic analysis predicted that snail homolog 1 (SNAI1) was a target of miR-22. The prediction was validated by qRT-PCR, Western blotting, an immunofluorescence assay, and a luciferase reporter assay. Furthermore, the effects of miR-22 on the expression of E-cadherin, a downstream target of Snail, were also investigated. These results suggested that miR-22 might inhibit the epithelial-to-mesenchymal transition (EMT) and metastasis by directly targeting SNAI1 for degradation in the human ovarian cancer cell line SKOV-3.",
                  "ner_annotations": {
                    "termite-annotator": [
                      {
                        "entity": "MIR22",
                        "start_offset": 164,
                        "end_offset": 170,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "MIR22",
                        "start_offset": 409,
                        "end_offset": 415,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "MIR22",
                        "start_offset": 465,
                        "end_offset": 471,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "MIR22",
                        "start_offset": 669,
                        "end_offset": 675,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "MIR22",
                        "start_offset": 778,
                        "end_offset": 784,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "MIR22",
                        "start_offset": 935,
                        "end_offset": 941,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "MIR22",
                        "start_offset": 1058,
                        "end_offset": 1064,
                        "eid": "GH:407004",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "CDH1",
                        "start_offset": 963,
                        "end_offset": 973,
                        "eid": "GH:999",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "SNAI1",
                        "start_offset": 738,
                        "end_offset": 743,
                        "eid": "GH:6615",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "SNAI1",
                        "start_offset": 755,
                        "end_offset": 760,
                        "eid": "GH:6615",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "SNAI1",
                        "start_offset": 998,
                        "end_offset": 1003,
                        "eid": "GH:6615",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "SNAI1",
                        "start_offset": 1163,
                        "end_offset": 1168,
                        "eid": "GH:6615",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "SK-OV-3",
                        "start_offset": 576,
                        "end_offset": 582,
                        "eid": "CL:CVCL_0532",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "SK-OV-3",
                        "start_offset": 1223,
                        "end_offset": 1229,
                        "eid": "CL:CVCL_0532",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasm metastasis",
                        "start_offset": 300,
                        "end_offset": 317,
                        "eid": "SD:SCITH010467",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasm metastasis",
                        "start_offset": 388,
                        "end_offset": 405,
                        "eid": "SD:SCITH010467",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasm metastasis",
                        "start_offset": 475,
                        "end_offset": 485,
                        "eid": "SD:SCITH010467",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasm metastasis",
                        "start_offset": 490,
                        "end_offset": 500,
                        "eid": "SD:SCITH010467",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasm metastasis",
                        "start_offset": 686,
                        "end_offset": 696,
                        "eid": "SD:SCITH010467",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasm metastasis",
                        "start_offset": 1130,
                        "end_offset": 1140,
                        "eid": "SD:SCITH010467",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasms, ovarian",
                        "start_offset": 204,
                        "end_offset": 218,
                        "eid": "SD:SCITH010681",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasms, ovarian",
                        "start_offset": 292,
                        "end_offset": 306,
                        "eid": "SD:SCITH010681",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasms, ovarian",
                        "start_offset": 380,
                        "end_offset": 394,
                        "eid": "SD:SCITH010681",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasms, ovarian",
                        "start_offset": 551,
                        "end_offset": 565,
                        "eid": "SD:SCITH010681",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "neoplasms, ovarian",
                        "start_offset": 1198,
                        "end_offset": 1212,
                        "eid": "SD:SCITH010681",
                        "type": "",
                        "concept_id": ""
                      },
                      {
                        "entity": "wound healing",
                        "start_offset": 629,
                        "end_offset": 642,
                        "eid": "SD:SCITH016599",
                        "type": "",
                        "concept_id": ""
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "in_variable": "eid",
              "out_variable": "type",
              "replacements": "SD->Disease,DR->Drug,GH->Gene,CL->Cell-line"
            }
          };
          
          var rename_ner_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "MicroRNAs (miRNAs), a class of small non-coding RNAs, regulate gene expression by targeting specific mRNAs to trigger translational repression or mRNA degradation. MiR-22 is aberrantly expressed in human ovarian cancer compared to normal tissues, and it plays an important role in regulating ovarian cancer metastasis. However, the concrete mechanism underlying the regulation of ovarian cancer metastasis by miR-22 remains ambiguous. In this study, the effects of miR-22 on metastasis and metastasis-associated targets were investigated in the human ovarian cancer cell line SKOV-3. Using a transwell migration assay and a cell wound healing assay, we discovered that miR-22 inhibited metastasis. A bioinformatic analysis predicted that snail homolog 1 (SNAI1) was a target of miR-22. The prediction was validated by qRT-PCR, Western blotting, an immunofluorescence assay, and a luciferase reporter assay. Furthermore, the effects of miR-22 on the expression of E-cadherin, a downstream target of Snail, were also investigated. These results suggested that miR-22 might inhibit the epithelial-to-mesenchymal transition (EMT) and metastasis by directly targeting SNAI1 for degradation in the human ovarian cancer cell line SKOV-3.",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "": [
                    {
                      "entity": "MIR22",
                      "start_offset": 164,
                      "end_offset": 170,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 409,
                      "end_offset": 415,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 465,
                      "end_offset": 471,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 669,
                      "end_offset": 675,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 778,
                      "end_offset": 784,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 935,
                      "end_offset": 941,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 1058,
                      "end_offset": 1064,
                      "eid": "GH:407004",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "CDH1",
                      "start_offset": 963,
                      "end_offset": 973,
                      "eid": "GH:999",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 738,
                      "end_offset": 743,
                      "eid": "GH:6615",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 755,
                      "end_offset": 760,
                      "eid": "GH:6615",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 998,
                      "end_offset": 1003,
                      "eid": "GH:6615",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 1163,
                      "end_offset": 1168,
                      "eid": "GH:6615",
                      "type": "Gene",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SK-OV-3",
                      "start_offset": 576,
                      "end_offset": 582,
                      "eid": "CL:CVCL_0532",
                      "type": "Cell-line",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SK-OV-3",
                      "start_offset": 1223,
                      "end_offset": 1229,
                      "eid": "CL:CVCL_0532",
                      "type": "Cell-line",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 300,
                      "end_offset": 317,
                      "eid": "SD:SCITH010467",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 388,
                      "end_offset": 405,
                      "eid": "SD:SCITH010467",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 475,
                      "end_offset": 485,
                      "eid": "SD:SCITH010467",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 490,
                      "end_offset": 500,
                      "eid": "SD:SCITH010467",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 686,
                      "end_offset": 696,
                      "eid": "SD:SCITH010467",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 1130,
                      "end_offset": 1140,
                      "eid": "SD:SCITH010467",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 204,
                      "end_offset": 218,
                      "eid": "SD:SCITH010681",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 292,
                      "end_offset": 306,
                      "eid": "SD:SCITH010681",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 380,
                      "end_offset": 394,
                      "eid": "SD:SCITH010681",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 551,
                      "end_offset": 565,
                      "eid": "SD:SCITH010681",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 1198,
                      "end_offset": 1212,
                      "eid": "SD:SCITH010681",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "wound healing",
                      "start_offset": 629,
                      "end_offset": 642,
                      "eid": "SD:SCITH016599",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    }
                  ],
                  "termite-annotator": [
                    {
                      "entity": "MIR22",
                      "start_offset": 164,
                      "end_offset": 170,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 409,
                      "end_offset": 415,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 465,
                      "end_offset": 471,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 669,
                      "end_offset": 675,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 778,
                      "end_offset": 784,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 935,
                      "end_offset": 941,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "MIR22",
                      "start_offset": 1058,
                      "end_offset": 1064,
                      "eid": "GH:407004",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "CDH1",
                      "start_offset": 963,
                      "end_offset": 973,
                      "eid": "GH:999",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 738,
                      "end_offset": 743,
                      "eid": "GH:6615",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 755,
                      "end_offset": 760,
                      "eid": "GH:6615",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 998,
                      "end_offset": 1003,
                      "eid": "GH:6615",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SNAI1",
                      "start_offset": 1163,
                      "end_offset": 1168,
                      "eid": "GH:6615",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SK-OV-3",
                      "start_offset": 576,
                      "end_offset": 582,
                      "eid": "CL:CVCL_0532",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "SK-OV-3",
                      "start_offset": 1223,
                      "end_offset": 1229,
                      "eid": "CL:CVCL_0532",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 300,
                      "end_offset": 317,
                      "eid": "SD:SCITH010467",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 388,
                      "end_offset": 405,
                      "eid": "SD:SCITH010467",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 475,
                      "end_offset": 485,
                      "eid": "SD:SCITH010467",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 490,
                      "end_offset": 500,
                      "eid": "SD:SCITH010467",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 686,
                      "end_offset": 696,
                      "eid": "SD:SCITH010467",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasm metastasis",
                      "start_offset": 1130,
                      "end_offset": 1140,
                      "eid": "SD:SCITH010467",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 204,
                      "end_offset": 218,
                      "eid": "SD:SCITH010681",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 292,
                      "end_offset": 306,
                      "eid": "SD:SCITH010681",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 380,
                      "end_offset": 394,
                      "eid": "SD:SCITH010681",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 551,
                      "end_offset": 565,
                      "eid": "SD:SCITH010681",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "neoplasms, ovarian",
                      "start_offset": 1198,
                      "end_offset": 1212,
                      "eid": "SD:SCITH010681",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    },
                    {
                      "entity": "wound healing",
                      "start_offset": 629,
                      "end_offset": 642,
                      "eid": "SD:SCITH016599",
                      "type": "",
                      "synonyms": [],
                      "concept_id": "",
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          };
          

          document.getElementById("rename_ner_input").innerHTML = JSON.stringify(rename_ner_in, undefined, 2);
          document.getElementById("rename_ner_output").innerHTML = JSON.stringify(rename_ner_out, undefined, 2);
          var taxonomy_nen_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "This is an example section text. Here comes a keyword: ERVK-11",
                  "ner_annotations": {
                    "ner-tags": [
                      {
                        "entity": "integrase",
                        "start_offset": 55,
                        "end_offset": 63,
                        "eid": "my-id",
                        "type": "GENE_HUMAN",
                        "synonyms": []
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "DICTIONARY_FOLDER_PATH": "s3://configuration-dictionaries-296967126277/nlp/main/dictionaries-txt",
              "DICTIONARY_NAMES": "sci_gene_human,celline",
              "FUZZY_SCORE": "0.8"
            }
          }
          
          var taxonomy_nen_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "This is an example section text. Here comes a keyword: ERVK-11",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "ner-tags": [
                    {
                      "entity": "L6",
                      "start_offset": 55,
                      "end_offset": 63,
                      "eid": "my-id",
                      "type": "GENE_HUMAN",
                      "synonyms": [
                        "l6 cell",
                        "l 6 cell line",
                        "l 6 rat",
                        "l6 transfected",
                        "l6 myoblast cultures",
                        "l6",
                        "l6 cell lines",
                        "l6 human",
                        "l6 supernatant",
                        "l6 parent",
                        "l6 cell line",
                        "l6 mouse",
                        "l 6 cultured",
                        "l6 clone",
                        "l6 tumor cell",
                        "l 6 cell lines",
                        "l6 rat",
                        "l6 myoblast rat",
                        "l 6 cell",
                        "l6 myoblast cell lines",
                        "l 6 cells",
                        "l6 cultures",
                        "l6 myoblast cell",
                        "l 6 mouse",
                        "l6 myoblast cell line",
                        "l 6 wild type",
                        "l 6 cultures",
                        "l 6 myoblast cell line",
                        "l 6 clone",
                        "l 6 myoblast cells",
                        "l6 tumor cells",
                        "l 6 human",
                        "l6 myoblast cells",
                        "l6 expressed in",
                        "l 6 parent",
                        "l 6 myoblast",
                        "l6 cultured",
                        "l6 cells",
                        "l6 wild type",
                        "l6 sub line",
                        "l6 wildtype"
                      ],
                      "concept_id": "CL:CVCL_0385",
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("taxonomy_nen_input").innerHTML = JSON.stringify(taxonomy_nen_in, undefined, 2);
          document.getElementById("taxonomy_nen_output").innerHTML = JSON.stringify(taxonomy_nen_out, undefined, 2);

          var spacy_nen_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Myeloid derived suppressor cells (MDSC) are immature myeloid cells with immunosuppressive activity.",
                  "token_annotations": {
                    "TokenAnnotatorSpacy": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 7
                      },
                      {
                        "tid": 8,
                        "start_offset": 8,
                        "end_offset": 15
                      },
                      {
                        "tid": 16,
                        "start_offset": 16,
                        "end_offset": 26
                      },
                      {
                        "tid": 27,
                        "start_offset": 27,
                        "end_offset": 32
                      },
                      {
                        "tid": 33,
                        "start_offset": 33,
                        "end_offset": 39
                      },
                      {
                        "tid": 40,
                        "start_offset": 40,
                        "end_offset": 43
                      },
                      {
                        "tid": 44,
                        "start_offset": 44,
                        "end_offset": 52
                      },
                      {
                        "tid": 53,
                        "start_offset": 53,
                        "end_offset": 60
                      },
                      {
                        "tid": 61,
                        "start_offset": 61,
                        "end_offset": 66
                      },
                      {
                        "tid": 67,
                        "start_offset": 67,
                        "end_offset": 71
                      },
                      {
                        "tid": 72,
                        "start_offset": 72,
                        "end_offset": 89
                      },
                      {
                        "tid": 90,
                        "start_offset": 90,
                        "end_offset": 99
                      }
                    ]
                  },
                  "ner_annotations": {
                    "NerTagAnnotatorSpacy": [
                      {
                        "eid": "16",
                        "start_offset": 16,
                        "end_offset": 32,
                        "entity": "CL"
                      },
                      {
                        "eid": "53",
                        "start_offset": 53,
                        "end_offset": 66,
                        "entity": "CL"
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "INFERENCESERVICE_NAME": "spacy-umls-annotator-en-ner-craft-md",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "umls-model"
            }
          }
          
          var spacy_nen_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Myeloid derived suppressor cells (MDSC) are immature myeloid cells with immunosuppressive activity.",
                "token_annotations": {
                  "TokenAnnotatorSpacy": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 7
                    },
                    {
                      "tid": 8,
                      "start_offset": 8,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 32
                    },
                    {
                      "tid": 33,
                      "start_offset": 33,
                      "end_offset": 39
                    },
                    {
                      "tid": 40,
                      "start_offset": 40,
                      "end_offset": 43
                    },
                    {
                      "tid": 44,
                      "start_offset": 44,
                      "end_offset": 52
                    },
                    {
                      "tid": 53,
                      "start_offset": 53,
                      "end_offset": 60
                    },
                    {
                      "tid": 61,
                      "start_offset": 61,
                      "end_offset": 66
                    },
                    {
                      "tid": 67,
                      "start_offset": 67,
                      "end_offset": 71
                    },
                    {
                      "tid": 72,
                      "start_offset": 72,
                      "end_offset": 89
                    },
                    {
                      "tid": 90,
                      "start_offset": 90,
                      "end_offset": 99
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "NerTagAnnotatorSpacy": [
                    {
                      "entity": "CL",
                      "start_offset": 16,
                      "end_offset": 32,
                      "eid": "16",
                      "type": null,
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "CL",
                      "start_offset": 53,
                      "end_offset": 66,
                      "eid": "53",
                      "type": null,
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {
                  "spacy_nen_umls_link_annotator": [
                    {
                      "eid": "16",
                      "cui": "C1710252",
                      "tuis": [
                        "T073"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "16",
                      "cui": "C2954564",
                      "tuis": [
                        "T121"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "16",
                      "cui": "C0017372",
                      "tuis": [
                        "T028"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "16",
                      "cui": "C0038856",
                      "tuis": [
                        "T025"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "16",
                      "cui": "C0079427",
                      "tuis": [
                        "T028"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "53",
                      "cui": "C0007634",
                      "tuis": [
                        "T025"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "53",
                      "cui": "C3282337",
                      "tuis": [
                        "T025"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "53",
                      "cui": "C0023172",
                      "tuis": [
                        "T025"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "53",
                      "cui": "C0524983",
                      "tuis": [
                        "T025"
                      ],
                      "definition": "",
                      "aliases": []
                    },
                    {
                      "eid": "53",
                      "cui": "C0004561",
                      "tuis": [
                        "T025"
                      ],
                      "definition": "",
                      "aliases": []
                    }
                  ]
                },
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("spacy_nen_input").innerHTML = JSON.stringify(spacy_nen_in, undefined, 2);
          document.getElementById("spacy_nen_output").innerHTML = JSON.stringify(spacy_nen_out, undefined, 2);

          var scikit_learn_classifier_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "We experimented with cancer iduced mice. Mouse was injected with p53 and cancer was induced. After 3 weeks in lab conditions, the mouse was examined for cancer growths."
                }
              ]
            },
            "config": {
              "with_features": "raw_text",
              "INFERENCESERVICE_NAME": "sklearn-classifier",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "invivo"
            }
          }
          
          var scikit_learn_classifier_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "We experimented with cancer iduced mice. Mouse was injected with p53 and cancer was induced. After 3 weeks in lab conditions, the mouse was examined for cancer growths.",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {
                  "scikit_learn_classifier": [
                    {
                      "classifier_type": "sklearn-classifier",
                      "class": "positive",
                      "confidence": 0.14
                    }
                  ]
                },
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("scikit_learn_classifier_input").innerHTML = JSON.stringify(scikit_learn_classifier_in, undefined, 2);
          document.getElementById("scikit_learn_classifier_output").innerHTML = JSON.stringify(scikit_learn_classifier_out, undefined, 2);
                    
        var zero_shot_classifier_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "He was diagnosed with severe lung cancer"
                }
              ]
            },
            "config": {
              "labels": "cardiovascular, oncology, women's health",
              "INFERENCESERVICE_NAME": "huggingface-zero-shot-classifier",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "zero-shot-classifier"
            }
          }
          
        var zero_shot_classifier_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "He was diagnosed with severe lung cancer",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {
                  "com.bayer.nlp.operators.ZeroShotClassifier": [
                    {
                      "classifier_type": "ZeroShot_Classifier",
                      "class": "oncology",
                      "confidence": 0.72
                    }
                  ]
                },
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          

           document.getElementById("zero_shot_classifier_input").innerHTML = JSON.stringify(zero_shot_classifier_in, undefined, 2);
          document.getElementById("zero_shot_classifier_output").innerHTML = JSON.stringify(zero_shot_classifier_out, undefined, 2);

          var language_detector_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Pacijent je došao zbog bolova u grudima. Analzom je utvrđeno da boljuje od raka pluća"
                }
              ]
            },
            "config": {
              "INFERENCESERVICE_NAME": "fasttext-language-detector",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "fasttext-language-detector"
            }
          }
          
          var language_detector_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Pacijent je došao zbog bolova u grudima. Analzom je utvrđeno da boljuje od raka pluća",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {
                  "language_detector": [
                    {
                      "classifier_type": "LanguageDetector",
                      "class": "hr",
                      "confidence": 0.5648645758628845
                    }
                  ]
                },
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("language_detector_input").innerHTML = JSON.stringify(language_detector_in, undefined, 2);
          document.getElementById("language_detector_output").innerHTML = JSON.stringify(language_detector_out, undefined, 2);
          var dosage_extractor_in = {
            "document": {
            "sections": [
              {
                "source": null,
                "raw_text": "The patient was prescribed with aspirin 15.6mg and ritalin 38.6mg a day for headache.",
                "token_annotations": {
                  "core_nlp_tokenizer": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 31
                    },
                    {
                      "tid": 32,
                      "start_offset": 32,
                      "end_offset": 39
                    },
                    {
                      "tid": 40,
                      "start_offset": 40,
                      "end_offset": 44
                    },
                    {
                      "tid": 44,
                      "start_offset": 44,
                      "end_offset": 46
                    },
                    {
                      "tid": 47,
                      "start_offset": 47,
                      "end_offset": 50
                    },
                    {
                      "tid": 51,
                      "start_offset": 51,
                      "end_offset": 58
                    },
                    {
                      "tid": 59,
                      "start_offset": 59,
                      "end_offset": 63
                    },
                    {
                      "tid": 63,
                      "start_offset": 63,
                      "end_offset": 65
                    },
                    {
                      "tid": 66,
                      "start_offset": 66,
                      "end_offset": 67
                    },
                    {
                      "tid": 68,
                      "start_offset": 68,
                      "end_offset": 71
                    },
                    {
                      "tid": 72,
                      "start_offset": 72,
                      "end_offset": 75
                    },
                    {
                      "tid": 76,
                      "start_offset": 76,
                      "end_offset": 84
                    },
                    {
                      "tid": 84,
                      "start_offset": 84,
                      "end_offset": 85
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "multi-bio-ner-tagger": [
                    {
                      "entity": "aspirin",
                      "start_offset": 32,
                      "end_offset": 39,
                      "eid": "aspirin-32-39",
                      "type": "Chemical",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "ritalin",
                      "start_offset": 51,
                      "end_offset": 58,
                      "eid": "ritalin-51-58",
                      "type": "Chemical",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
          
                    {
                      "entity": "headache",
                      "start_offset": 76,
                      "end_offset": 84,
                      "eid": "headache-76-84",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          },
            "config": {
              "ENTITY_TYPE": "Disease",
              "MAX_DISTANCE": 0
            }
          }
          
          var dosage_extractor_out = {
            "id": "id",
            "sections": [
              {
                "raw_text": "The patient was prescribed with aspirin 15.6mg and ritalin 38.6mg liked it.",
                "token_annotations": {
                  "": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 31
                    },
                    {
                      "tid": 32,
                      "start_offset": 32,
                      "end_offset": 39
                    },
                    {
                      "tid": 40,
                      "start_offset": 40,
                      "end_offset": 44
                    },
                    {
                      "tid": 44,
                      "start_offset": 44,
                      "end_offset": 46
                    },
                    {
                      "tid": 47,
                      "start_offset": 47,
                      "end_offset": 50
                    },
                    {
                      "tid": 51,
                      "start_offset": 51,
                      "end_offset": 58
                    },
                    {
                      "tid": 59,
                      "start_offset": 59,
                      "end_offset": 63
                    },
                    {
                      "tid": 63,
                      "start_offset": 63,
                      "end_offset": 65
                    },
                    {
                      "tid": 66,
                      "start_offset": 66,
                      "end_offset": 71
                    },
                    {
                      "tid": 72,
                      "start_offset": 72,
                      "end_offset": 74
                    },
                    {
                      "tid": 74,
                      "start_offset": 74,
                      "end_offset": 75
                    }
                  ]
                },
                "sentence_annotations": {
                  "": [
                    {
                      "sid": 0,
                      "start_token": 0,
                      "end_token": 74
                    }
                  ]
                },
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "DummyList": [
                    {
                      "entity": "aspirin",
                      "start_offset": 32,
                      "end_offset": 39,
                      "eid": "32-39-aspirin-DR:SCITH000934",
                      "type": "Drug",
                      "synonyms": [],
                      "concept_id": "DR:SCITH000934"
                    },
                    {
                      "entity": "ritalin",
                      "start_offset": 51,
                      "end_offset": 58,
                      "eid": "51-58-ritalin-DR:SCITH00478",
                      "type": "Drug",
                      "synonyms": [],
                      "concept_id": "DR:SCITH00478"
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {
                  "com.bayer.nlp.operators.DosageInformationExtractor": [
                    {
                      "relationship": "Dosage-Info",
                      "sentence_id": 0,
                      "eid_a": "15.6mg",
                      "eid_b": "32-39-aspirin-DR:SCITH000934"
                    },
                    {
                      "relationship": "Dosage-Info",
                      "sentence_id": 0,
                      "eid_a": "38.6mg",
                      "eid_b": "51-58-ritalin-DR:SCITH00478"
                    }
                  ]
                },
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ]
          }
          
          document.getElementById("dosage_extractor_input").innerHTML = JSON.stringify(dosage_extractor_in, undefined, 2);
          document.getElementById("dosage_extractor_output").innerHTML = JSON.stringify(dosage_extractor_out, undefined, 2);

          var co_occurrence_ext_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "I like to eat chocolate and add a bit of chlorpropham to it.",
                  "token_annotations": {
                    "": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 1
                      },
                      {
                        "tid": 2,
                        "start_offset": 2,
                        "end_offset": 6
                      },
                      {
                        "tid": 7,
                        "start_offset": 7,
                        "end_offset": 9
                      },
                      {
                        "tid": 10,
                        "start_offset": 10,
                        "end_offset": 13
                      },
                      {
                        "tid": 14,
                        "start_offset": 14,
                        "end_offset": 23
                      },
                      {
                        "tid": 24,
                        "start_offset": 24,
                        "end_offset": 27
                      },
                      {
                        "tid": 28,
                        "start_offset": 28,
                        "end_offset": 31
                      },
                      {
                        "tid": 32,
                        "start_offset": 32,
                        "end_offset": 33
                      },
                      {
                        "tid": 34,
                        "start_offset": 34,
                        "end_offset": 37
                      },
                      {
                        "tid": 38,
                        "start_offset": 38,
                        "end_offset": 40
                      },
                      {
                        "tid": 41,
                        "start_offset": 41,
                        "end_offset": 53
                      },
                      {
                        "tid": 54,
                        "start_offset": 54,
                        "end_offset": 56
                      },
                      {
                        "tid": 57,
                        "start_offset": 57,
                        "end_offset": 59
                      },
                      {
                        "tid": 59,
                        "start_offset": 59,
                        "end_offset": 60
                      }
                    ]
                  },
                  "sentence_annotations": {
                    "": [
                      {
                        "sid": 0,
                        "start_token": 0,
                        "end_token": 59
                      }
                    ]
                  },
                  "ner_annotations": {
                    "com.bayer.nlp.operators.TermiteAnnotator": [
                      {
                        "entity": "Chocolate",
                        "start_offset": 14,
                        "end_offset": 23,
                        "eid": "SD:CHEMBL2108921-14-23",
                        "type": "DRUG",
                        "synonyms": [],
                        "concept_id": "SD:CHEMBL2108921"
                      },
                      {
                        "entity": "Chlorpropham",
                        "start_offset": 41,
                        "end_offset": 53,
                        "eid": "SD:CHEMBL104560-41-53",
                        "type": "Chemical",
                        "synonyms": [],
                        "concept_id": "SD:CHEMBL104560"
                      }
                    ],
                    "DummyList": [
                      {
                        "entity": "chocolate",
                        "start_offset": 20,
                        "end_offset": 29,
                        "eid": "DR:1001-20-19",
                        "type": "DR",
                        "synonyms": [],
                        "concept_id": "DR:1001"
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "ENTITY_TYPE_A": "DRUG",
              "ENTITY_TYPE_B": "Chemical",
              "RELATION_TYPE": "Drug<->Chemical",
              "MAX_DISTANCE": 100
            }
          }
          
          var co_occurrence_ext_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "I like to eat chocolate and add a bit of chlorpropham to it.",
                "token_annotations": {
                  "": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 1
                    },
                    {
                      "tid": 2,
                      "start_offset": 2,
                      "end_offset": 6
                    },
                    {
                      "tid": 7,
                      "start_offset": 7,
                      "end_offset": 9
                    },
                    {
                      "tid": 10,
                      "start_offset": 10,
                      "end_offset": 13
                    },
                    {
                      "tid": 14,
                      "start_offset": 14,
                      "end_offset": 23
                    },
                    {
                      "tid": 24,
                      "start_offset": 24,
                      "end_offset": 27
                    },
                    {
                      "tid": 28,
                      "start_offset": 28,
                      "end_offset": 31
                    },
                    {
                      "tid": 32,
                      "start_offset": 32,
                      "end_offset": 33
                    },
                    {
                      "tid": 34,
                      "start_offset": 34,
                      "end_offset": 37
                    },
                    {
                      "tid": 38,
                      "start_offset": 38,
                      "end_offset": 40
                    },
                    {
                      "tid": 41,
                      "start_offset": 41,
                      "end_offset": 53
                    },
                    {
                      "tid": 54,
                      "start_offset": 54,
                      "end_offset": 56
                    },
                    {
                      "tid": 57,
                      "start_offset": 57,
                      "end_offset": 59
                    },
                    {
                      "tid": 59,
                      "start_offset": 59,
                      "end_offset": 60
                    }
                  ]
                },
                "sentence_annotations": {
                  "": [
                    {
                      "sid": 0,
                      "start_token": 0,
                      "end_token": 59
                    }
                  ]
                },
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "com.bayer.nlp.operators.TermiteAnnotator": [
                    {
                      "entity": "Chocolate",
                      "start_offset": 14,
                      "end_offset": 23,
                      "eid": "SD:CHEMBL2108921-14-23",
                      "type": "DRUG",
                      "synonyms": [],
                      "concept_id": "SD:CHEMBL2108921",
                      "metadata": null
                    },
                    {
                      "entity": "Chlorpropham",
                      "start_offset": 41,
                      "end_offset": 53,
                      "eid": "SD:CHEMBL104560-41-53",
                      "type": "Chemical",
                      "synonyms": [],
                      "concept_id": "SD:CHEMBL104560",
                      "metadata": null
                    }
                  ],
                  "DummyList": [
                    {
                      "entity": "chocolate",
                      "start_offset": 20,
                      "end_offset": 29,
                      "eid": "DR:1001-20-19",
                      "type": "DR",
                      "synonyms": [],
                      "concept_id": "DR:1001",
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {
                  "co_occ_extractor": [
                    {
                      "relationship": "Drug<->Chemical",
                      "sentence_id": 0,
                      "eid_a": "SD:CHEMBL2108921-14-23",
                      "eid_b": "SD:CHEMBL104560-41-53",
                      "score": -0.5900000333786011,
                      "trigger_phrase": null,
                      "is_negated": null,
                      "negation_trigger": null,
                      "speculation_trigger": null,
                      "attribute": null,
                      "is_speculative": null
                    }
                  ]
                },
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("co_occurrence_ext_input").innerHTML = JSON.stringify(co_occurrence_ext_in, undefined, 2);
          document.getElementById("co_occurrence_ext_output").innerHTML = JSON.stringify(co_occurrence_ext_out, undefined, 2);

          var translation_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "Obwohl Amyloidfibrillen bereits seit den 1950er Jahren mittels Elektronenmikroskopie (EM) sichtbar gemacht werden können [2], stellt die Aufklärung ihrer Struktur im atomaren Detail noch immer eine technische Herausforderung dar.",
                  "document_classes": {
                    "LanguageDetector": [
                      {
                        "class": "de",
                        "classifier_type": "LanguageDetector",
                        "confidence": 0.99
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "MODEL_NAME": "argos-translation-annotator",
              "LANGUAGE_ANNOTATION_TO_USE": "LanguageDetector",
              "TARGET_LANGUAGE_CODE": "en",
              "INFERENCESERVICE_NAME": "argos-translation-annotator",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local"
            }
          }
          
          var translation_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "Although amyloid fibrils have been able to be made visible by electron microscopy (EM) since the 1950s [2], the clarification of their structure in atomic detail is still a technical challenge.",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("translation_input").innerHTML = JSON.stringify(translation_in, undefined, 2);
          document.getElementById("translation_output").innerHTML = JSON.stringify(translation_out, undefined, 2);

          var projection_in = {
            "document": {
                "sections": [{
                    "source": null,
                    "raw_text": "The patient was prescribed with aspirin 15.6mg and ritalin 38.6mg a day for headache.",
                    "token_annotations": {
                        "core_nlp_tokenizer": [{
                                "tid": 0,
                                "start_offset": 0,
                                "end_offset": 3
                            },
                            {
                                "tid": 4,
                                "start_offset": 4,
                                "end_offset": 11
                            },
                            {
                                "tid": 12,
                                "start_offset": 12,
                                "end_offset": 15
                            },
                            {
                                "tid": 16,
                                "start_offset": 16,
                                "end_offset": 26
                            },
                            {
                                "tid": 27,
                                "start_offset": 27,
                                "end_offset": 31
                            },
                            {
                                "tid": 32,
                                "start_offset": 32,
                                "end_offset": 39
                            },
                            {
                                "tid": 40,
                                "start_offset": 40,
                                "end_offset": 44
                            },
                            {
                                "tid": 44,
                                "start_offset": 44,
                                "end_offset": 46
                            },
                            {
                                "tid": 47,
                                "start_offset": 47,
                                "end_offset": 50
                            },
                            {
                                "tid": 51,
                                "start_offset": 51,
                                "end_offset": 58
                            },
                            {
                                "tid": 59,
                                "start_offset": 59,
                                "end_offset": 63
                            },
                            {
                                "tid": 63,
                                "start_offset": 63,
                                "end_offset": 65
                            },
                            {
                                "tid": 66,
                                "start_offset": 66,
                                "end_offset": 67
                            },
                            {
                                "tid": 68,
                                "start_offset": 68,
                                "end_offset": 71
                            },
                            {
                                "tid": 72,
                                "start_offset": 72,
                                "end_offset": 75
                            },
                            {
                                "tid": 76,
                                "start_offset": 76,
                                "end_offset": 84
                            },
                            {
                                "tid": 84,
                                "start_offset": 84,
                                "end_offset": 85
                            }
                        ]
                    },
                    "sentence_annotations": {},
                    "pos_annotations": {},
                    "lemma_annotations": {},
                    "ner_annotations": {
                        "multi-bio-ner-tagger": [{
                                "entity": "aspirin",
                                "start_offset": 32,
                                "end_offset": 39,
                                "eid": "aspirin-32-39",
                                "type": "Chemical",
                                "synonyms": [],
                                "concept_id": null,
                                "metadata": null
                            },
                            {
                                "entity": "ritalin",
                                "start_offset": 51,
                                "end_offset": 58,
                                "eid": "ritalin-51-58",
                                "type": "Chemical",
                                "synonyms": [],
                                "concept_id": null,
                                "metadata": null
                            },
        
                            {
                                "entity": "headache",
                                "start_offset": 76,
                                "end_offset": 84,
                                "eid": "headache-76-84",
                                "type": "Disease",
                                "synonyms": [],
                                "concept_id": null,
                                "metadata": null
                            }
                        ]
                    },
                    "entity_link_annotations": {},
                    "nen_umls_link_annotations": {},
                    "rel_dep_annotations": {},
                    "document_classes": {},
                    "abbreviation_annotations": {},
                    "ngram_annotations": {}
                }],
                "id": "id"
            },
        
            "config": {
                "MODE": "RETAIN",
                "ANNOTATIONS": "[\"tokens\"]"
            }
        }
        
          var projection_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "The patient was prescribed with aspirin 15.6mg and ritalin 38.6mg a day for headache.",
                "token_annotations": {
                  "core_nlp_tokenizer": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 31
                    },
                    {
                      "tid": 32,
                      "start_offset": 32,
                      "end_offset": 39
                    },
                    {
                      "tid": 40,
                      "start_offset": 40,
                      "end_offset": 44
                    },
                    {
                      "tid": 44,
                      "start_offset": 44,
                      "end_offset": 46
                    },
                    {
                      "tid": 47,
                      "start_offset": 47,
                      "end_offset": 50
                    },
                    {
                      "tid": 51,
                      "start_offset": 51,
                      "end_offset": 58
                    },
                    {
                      "tid": 59,
                      "start_offset": 59,
                      "end_offset": 63
                    },
                    {
                      "tid": 63,
                      "start_offset": 63,
                      "end_offset": 65
                    },
                    {
                      "tid": 66,
                      "start_offset": 66,
                      "end_offset": 67
                    },
                    {
                      "tid": 68,
                      "start_offset": 68,
                      "end_offset": 71
                    },
                    {
                      "tid": 72,
                      "start_offset": 72,
                      "end_offset": 75
                    },
                    {
                      "tid": 76,
                      "start_offset": 76,
                      "end_offset": 84
                    },
                    {
                      "tid": 84,
                      "start_offset": 84,
                      "end_offset": 85
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          

          document.getElementById("projection_input").innerHTML = JSON.stringify(projection_in, undefined, 2);
          document.getElementById("projection_output").innerHTML = JSON.stringify(projection_out, undefined, 2);

          var filter_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "This is an example section text.",
                  "rel_dep_annotations": {
                    "relations": [
                      {
                        "relationship": "my-relation-ship",
                        "sentence_id": 0,
                        "eid_a": "A",
                        "eid_b": "B",
                        "score": 0.3
                      },
                      {
                        "relationship": "my-relation-ship",
                        "sentence_id": 0,
                        "eid_a": "A",
                        "eid_b": "B",
                        "score": 0.6
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "filter": "[ { \"annotation\": \"relations\", \"name\": \"score\", \"operation\": \"GT\", \"value\": \"0.5\" } ]"
            }
          }
          
          var filter_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "This is an example section text.",
                "token_annotations": {},
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {
                  "relations": [
                    {
                      "relationship": "my-relation-ship",
                      "sentence_id": 0,
                      "eid_a": "A",
                      "eid_b": "B",
                      "score": 0.3,
                      "trigger_phrase": null,
                      "is_negated": null,
                      "negation_trigger": null,
                      "speculation_trigger": null,
                      "attribute": null,
                      "is_speculative": null
                    },
                    {
                      "relationship": "my-relation-ship",
                      "sentence_id": 0,
                      "eid_a": "A",
                      "eid_b": "B",
                      "score": 0.6,
                      "trigger_phrase": null,
                      "is_negated": null,
                      "negation_trigger": null,
                      "speculation_trigger": null,
                      "attribute": null,
                      "is_speculative": null
                    }
                  ]
                },
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "id"
          }
          
          document.getElementById("filter_input").innerHTML = JSON.stringify(filter_in, undefined, 2);
          document.getElementById("filter_output").innerHTML = JSON.stringify(filter_out, undefined, 2);

          var relationship_ext_in = {
            "document": {
              "id": 0,
              "sections": [
                {
                  "raw_text": "GSPECT bullseye plots were generated for each of eight frames acquired after stress 99mTc-sestamibi injection in 44 patients with coronary artery disease and at least one severe perfusion defect on summed (ungated) SPECT images.\n",
                  "ner_annotations": {
                    "entities": [
                      {
                        "start_offset": 84,
                        "end_offset": 99,
                        "type": "Drug",
                        "entity": "Sestamibi",
                        "eid": "0"
                      },
                      {
                        "start_offset": 130,
                        "end_offset": 153,
                        "type": "Disease",
                        "entity": "coronary artery disease",
                        "eid": "1"
                      }
                    ]
                  },
                  "sentence_annotations": {
                    "sentence": [
                      {
                        "sid": 0,
                        "start_token": 0,
                        "end_token": 229
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "INFERENCESERVICE_NAME": "nltk-tokenizer",
              "BASE_ENDPOINT": "nlp-inferenceservices.svc.cluster.local",
              "MODEL_NAME": "popular",
              "GENE_ATTRIBUTES": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/gene_attributes/gene_attributes.json",
              "NEGATIONS": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/negations/negations.json",
              "NEGATIVE_ADVERBS": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/negative_adverbs/negative_adverbs.json",
              "SPECULATION_CUES": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/speculation/speculation_cues.json",
              "TRIGGER_RELATIONSHIPS": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/trigger_relationship/triggers_relationships.json",
              "SENTENCE_RULES": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/rules/rule_set_dev_28_01_2021.json",
              "MIDDLE_BLACK_LIST": "s3://configuration-dictionaries-296967126277/nlp/main/relationship_extractor_annotator/black_list/black_middle_list.json"
            }
          }
          var relationship_ext_out = {
            "sections": [
              {
                "source": null,
                "raw_text": "GSPECT bullseye plots were generated for each of eight frames acquired after stress 99mTc-sestamibi injection in 44 patients with coronary artery disease and at least one severe perfusion defect on summed (ungated) SPECT images.\n",
                "token_annotations": {},
                "sentence_annotations": {
                  "sentence": [
                    {
                      "sid": 0,
                      "start_token": 0,
                      "end_token": 229
                    }
                  ]
                },
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {
                  "entities": [
                    {
                      "entity": "Sestamibi",
                      "start_offset": 84,
                      "end_offset": 99,
                      "eid": "0",
                      "type": "Drug",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    },
                    {
                      "entity": "coronary artery disease",
                      "start_offset": 130,
                      "end_offset": 153,
                      "eid": "1",
                      "type": "Disease",
                      "synonyms": [],
                      "concept_id": null,
                      "metadata": null
                    }
                  ]
                },
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {
                  "relationship_extraction_rule_set": [
                    {
                      "relationship": "Drug->Disease",
                      "sentence_id": 0,
                      "eid_a": "0",
                      "eid_b": "1",
                      "score": 0.8,
                      "trigger_phrase": "Treat",
                      "is_negated": 0,
                      "negation_trigger": "",
                      "speculation_trigger": "",
                      "attribute": "",
                      "is_speculative": 0
                    }
                  ]
                },
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {}
              }
            ],
            "id": "0"
          }

          document.getElementById("relationship_ext_input").innerHTML = JSON.stringify(relationship_ext_in, undefined, 2);
          document.getElementById("relationship_ext_output").innerHTML = JSON.stringify(relationship_ext_out, undefined, 2);
          var ngram_in = {
            "document": {
              "id": "id",
              "sections": [
                {
                  "raw_text": "The patient was prescribed with aspirin.",
                  "token_annotations": {
                    "someTokenAnnotator": [
                      {
                        "tid": 0,
                        "start_offset": 0,
                        "end_offset": 3
                      },
                      {
                        "tid": 4,
                        "start_offset": 4,
                        "end_offset": 11
                      },
                      {
                        "tid": 12,
                        "start_offset": 12,
                        "end_offset": 15
                      },
                      {
                        "tid": 16,
                        "start_offset": 16,
                        "end_offset": 26
                      },
                      {
                        "tid": 27,
                        "start_offset": 27,
                        "end_offset": 31
                      },
                      {
                        "tid": 32,
                        "start_offset": 32,
                        "end_offset": 39
                      },
                      {
                        "tid": 39,
                        "start_offset": 39,
                        "end_offset": 40
                      }
                    ]
                  }
                }
              ]
            },
            "config": {
              "TOKENS_TO_USE": "someTokenAnnotator",
              "MIN_LENGTH": 2,
              "MAX_LENGTH": 3,
              "SCOPE": "TOKENS",
              "keep_only_letters": true,
              "ignore_stopwords": true,
              "ignore_parentheses": true,
              "ignore_punctuation": true
            }
          }
          var ngram_out ={
            "sections": [
              {
                "raw_text": "The patient was prescribed with aspirin.",
                "token_annotations": {
                  "someTokenAnnotator": [
                    {
                      "tid": 0,
                      "start_offset": 0,
                      "end_offset": 3
                    },
                    {
                      "tid": 4,
                      "start_offset": 4,
                      "end_offset": 11
                    },
                    {
                      "tid": 12,
                      "start_offset": 12,
                      "end_offset": 15
                    },
                    {
                      "tid": 16,
                      "start_offset": 16,
                      "end_offset": 26
                    },
                    {
                      "tid": 27,
                      "start_offset": 27,
                      "end_offset": 31
                    },
                    {
                      "tid": 32,
                      "start_offset": 32,
                      "end_offset": 39
                    },
                    {
                      "tid": 39,
                      "start_offset": 39,
                      "end_offset": 40
                    }
                  ]
                },
                "sentence_annotations": {},
                "pos_annotations": {},
                "lemma_annotations": {},
                "ner_annotations": {},
                "entity_link_annotations": {},
                "nen_umls_link_annotations": {},
                "rel_dep_annotations": {},
                "document_classes": {},
                "abbreviation_annotations": {},
                "ngram_annotations": {
                  "core_nlp_ngram_annotator": [
                    {
                      "length": 3,
                      "scope": "TOKENS",
                      "n_grams": [
                        [
                          {
                            "start_offset": 4,
                            "end_offset": 11
                          },
                          {
                            "start_offset": 12,
                            "end_offset": 15
                          },
                          {
                            "start_offset": 16,
                            "end_offset": 26
                          }
                        ],
                        [
                          {
                            "start_offset": 16,
                            "end_offset": 26
                          },
                          {
                            "start_offset": 27,
                            "end_offset": 31
                          },
                          {
                            "start_offset": 32,
                            "end_offset": 39
                          }
                        ]
                      ]
                    }
                  ]
                }
              }
            ],
            "id": "id"
          }
          document.getElementById("ngram_input").innerHTML = JSON.stringify(ngram_in, undefined, 2);
          document.getElementById("ngram_output").innerHTML = JSON.stringify(ngram_out, undefined, 2); 
                      
        

}

    
     
      GetTokenButtonSubmit(event) {

        const request = require('superagent');
        var new_api = "https://api.linguist.skgt.int.bayer.com"//9dr0abafd1.execute-api.eu-central-1.amazonaws.com";
        const req = request.post(new_api+'/get_nlp_at_scale_token').set("Authorization",localStorage.getItem("cognito_token"));             //,token.accessToken);//("cognito_token"));
        req.send();
        req.end((end,res)=>{
          if (res==undefined && end !== undefined){
            window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
            //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
          }
          document.getElementById("api_token").innerHTML = res.body;
          console.log(res.body);
        }
        );
      }
    


  render() {
    var nlp_root = "https://nlp-api.skgt.int.bayer.com";

    return (
      <div className="MegaContainer">
          <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>

          <div className="mainBlock">
          <div className="menuBlock"><b>Table of contents</b>
          <ul className="menuUL">
            <li><a href="#General">General information</a></li>
            <li><a href="#Share">Share algorithms</a></li>
            <li><a href="#gitLab">GitLab model upload</a></li>
            <li><a href="#ObtainToken">Obtain token</a></li>
            <li><a href="#Tokenization">Tokenization</a>
            <ul>
            <li><a href="#core_tokenizer">CoreNLP Tokenizer</a></li>
            <li><a href="#spacy_tokenizer">Spacy Tokenizer</a></li>
            <li><a href="#sentence_corenlp">CoreNLP sentence splitter</a></li>
            
            </ul></li>
            <li><a href="#Syntactic_processing">Syntactic processing</a> 
            <ul>
            <li><a href="#CoreNLP_NGram">CoreNLP N-gram annotator</a></li>
            <li><a href="#CoreNLP_Lemmatizer">CoreNLP Lemmatizer</a></li>
            <li><a href="#spacy_pos">Spacy POS</a></li>
            <li><a href="#corenlp_pos">CoreNLP POS</a></li>
            <li><a href="#markup_removal">Markup removal</a></li>
            </ul>
            </li>

            <li><a href="#NER">Named entity recognition</a> 
            <ul>
            <li><a href="#regex_ner">Regular expression NER</a></li>
            <li><a href="#spacy_ner">Spacy NER</a></li>
            <li><a href="#Termite">Scibite's Termite</a></li>
            <li><a href="#Linnaeus">Linnaeus</a></li>
            <li><a href="#mutli_bio_ner">Multi-bio NER (NN)</a></li>
            <li><a href="#spacy_abbr_ner">Spacy abbreviation extractor</a></li>
            <li><a href="#rename_ner">Rename NER</a></li>
            </ul>
            </li>

            <li><a href="#nen">Named entity normalization</a>
            <ul>
            <li><a href="#taxonomy_nen">Taxonomy NEN</a></li>
            <li><a href="#spacy_nen">Spacy NEN</a></li>
           
            </ul>
            </li>
            <li><a href="#classification">Classification</a>
            <ul>
            <li><a href="#scikit_learn_classifier">SciKitLearn Classifier</a></li>
            <li><a href="#zero_shot_classifier">Zero-shot classifier</a></li>
            <li><a href="#language_detector">Language detector</a></li>
            </ul>
            </li>
            <li><a href="#relationship_extraction">Relationship extraction</a>
            <ul>
            <li><a href="#dosage_extractor">Dosage extraction</a></li>
            <li><a href="#co_occurrence_ext">Co-occurrence extractor</a></li>
            <li><a href="#rel_ext_rules">Rule-based relationship extractor</a></li>
            </ul>
            </li>
            <li><a href="#complex_lan">Complex language operators</a>
            <ul>
            <li><a href="#translation">Translation</a></li>
            </ul>
            </li>

            <li><a href="#post_processing">Post processing</a>
            <ul>
            <li><a href="#projection">Projection</a></li>
            <li><a href="#filter">Filter</a></li>
            </ul>
            </li>

          </ul>
          
          
          </div>
          <div className="docBlock">
         <h1>Linguist@Scale API documentation</h1>
         <h2 id="General">General information</h2>
         <div className="paragraph">
        Bellow follows the documentation for the natural language processing at scale API operators that we have collected and developed with our partners. Please refer to this documentation
        when implementing API calls. 
        <br/>
        Linguist@Scale is a scalable part of Linguist platform for building natural language processing pipelines across the organization, built on top of Kafka. It is composed of several tool, such as Linguist@Scale processing pipeline, Linguist Pipeline builder, Linguist@Scale API and Swagger. Pipelines can be built using a set of operators that are also exposed as API. Also some of the pipelines are exposed as API interfaces.
        </div>
        <h2 id="Share">Share your algorithms</h2>
        <div className="paragraph">
            In case you have developped an NLP algorithm that can be exposed as API, please let us know. Please write an email to  <a href="mailto:nikola.milosevic@bayer.com">nikola.milosevic@bayer.com</a> and we can discuss, document and implement integration with your API or include your method to Linguist@Scale. 
            </div>
            <h2 id="gitLab">Upload and use custom dictionaries and models via GitLab</h2>
            <div className="paragraph">
                It is possible for some operators to upload dictionaries and models to S3 bucket via GitLab. You can use <a href="https://gitlab.bayer.com/sih/kafka-nlp/user-dictionaries" tager="_blank">user-dictionaries GitLab repository</a>
                to upload dictionaries. In case you do not have access, feel free to contact <a href="mailto:nikola.milosevic@bayer.com">nikola.milosevic@bayer.com</a> and request access. You need to upload initially items to a branch, from where, items will be available in 
                development instances of API and Swagger (Swagger: <a href="https://nlp-api-docs.skgt.int.bayer.com" target="_blank">https://nlp-api-docs.skgt.int.bayer.com</a>, API: <a href="https://nlp-api.skgt.int.bayer.com" target="_blank">https://nlp-api.skgt.int.bayer.com</a>). You can make then pull request and after review, the items you uploaded will be available also in the S3 bucket that is 
                available in productive environment. 
            </div>
            <h2 id="gitLab">Upload and use custom dictionaries and models via Bayer DeepMind</h2>
            <div className="paragraph">
                In addition, it is possible to load models (Sklearn and Transformer operators) and dictionaries (in following operators: Linnaeus, TaxonomyNEN, LeadMine and Relationship extraction rule based) from Bayer DeepMind (<a href="https://deepmind.int.bayer.com/" target="_blank">https://deepmind.int.bayer.com/</a>).
                This can be done by using protocol prefix deepmind: <b>deepmind://</b>, and short link of the model/dictionary, with the branch name (usually master). 
                For example, for LeadMine operator that is located at <a href="https://deepmind.int.bayer.com/models/nlp-at-scale/leadmine-annotator" target="_blank">https://deepmind.int.bayer.com/models/nlp-at-scale/leadmine-annotator</a> and has a short link
                nlp-at-scale/leadmine-annotator, the link added for dictionary would be:
                <pre> 
                  deepmind://nlp-at-scale/leadmine-annotator/master
                  </pre>

            </div>
          <h2 id="ObtainToken">Obtain API token</h2>
          <div className="paragraph">
          Your current API authorization token can be obtained from the following form by clicking "Obtain API token" button. The token is valid for 24 hours, 
          and then you will have to generate a new one. In case you need more permanent solution, please contact us with your use case. 
         <br/>
         The token needs to be entered in <b>request header</b> under the key <b>"Authorization"</b>.<br/>

         Your API token:
          </div>

          <pre id="api_token"></pre>
          <br/>
          <button id="gettoken_button" onClick={this.GetTokenButtonSubmit}>Obtain API Token</button>
        <h1 id="API_operators">API Operators</h1>
        <div className="paragraph">
        Operators are accessible using various interfaces of the following URL <pre><a href="{nlp_root}">{nlp_root}</a></pre> and 
        Swagger GUI interface for testing and trying the operators is available at 
        <pre><a href="https://nlp-api-docs.skgt.int.bayer.com/">https://nlp-api-docs.skgt.int.bayer.com/</a></pre>
        </div>
        <div className="paragraph">
        <b>Important note for using operators with large dictionaries or models:</b> If you are using operator that uses large dictionary or machine learning model, the model may need some time to initialize. Therefore, the first request may return 503 error. 
        Once the operator is initialized (it may take 1-2 minutes), you will be able to use it for future requests. For cost saving purposes, we have implemented autoscaling, which will, in case you are not using your operator or pipeline for more then an hour, scale down your operator and uninitialize it. Therefore, once you will need it again, it may take some time to initialize.  
        </div>
        <h2 id="Tokenization">Tokenization</h2>
        <div className="paragraph">
        Tokenization is a method that separates a piece of text into smaller units called tokens. For most of the methods presented here, tokens are single words in text. While sentence tokenizer or sentence splitter would split text into sentences. 
        </div>
        <h3 id="core_tokenizer">Core NLP Tokenizer</h3>
        <div className="paragraph">
        Core NLP tokenizer is a component of Core NLP library developed by NLP group at Stanford University (https://stanfordnlp.github.io/CoreNLP/). The call can be done by performing PUT request on the following interface:
      
        <pre>{nlp_root}/core/core_nlp_tokenizer</pre>
        With the PUT Request body in the following format:
        <pre id="core_nlp_tokenizer_input"></pre>
        The response should look in the following way:
        <pre id="core_nlp_tokenizer_output"></pre>
        </div>
        <h3 id="spacy_tokenizer">Spacy token annotator</h3>
        <div className="paragraph">
        Spacy token annotator is based on Spacy framework, and annotates tokens based on the trained model. Four models are available. 
        MODEL_NAME can be set to any of the following models:
        <ul>
<li>en_core_sci_lg </li>
<li>en_core_sci_md </li>
<li>en_core_web_sm </li>
<li>en_ner_craft_md</li>
</ul>
For more information about models see <a href="https://allenai.github.io/scispacy/" target="_blank">https://allenai.github.io/scispacy/</a>
<br/>
For using this tokenizer you can call PUT request to the following interface:
<pre>{nlp_root}/core/spacy_token_annotator</pre>
Request body should look in following manner 
<pre id="spacy_tokenizer_input"></pre>
Respone will look in the following way:
<pre id="spacy_tokenizer_output"></pre>
            </div>
            <h3 id="sentence_corenlp">CoreNLP Sentence splitter</h3>
            <div className="paragraph">
            Sentence annotator finds spans of sentences in a given text. Sentence annotator is dependent on token annotations, and some of the tokenizers need to be run first and TOKEN_ANNOTATIONS_TO_USE needs to be pointed to the name of token annotations (see example).
            <br/>
            <pre>{nlp_root}/core/core_nlp_sentence_annotator</pre>
            PUT request body:
            <pre id="sentence_splitter_input"></pre> 
            Response: 
            <pre id="sentence_splitter_output"></pre> 

            </div>

<h2 id="Syntactic_processing">Syntactic processing and normalization</h2>
<h3 id="CoreNLP_NGram">CoreNLP N-gram annotator</h3>
<div className="paragraph">
Simply annotates spans of n-grams in text based on coreNLP method.
<br/>
This operator requires tookenizer to be run previously and annotations made part of the document. 
<br/>
Interface to call PUT request 
<pre>{nlp_root}/core/core_nlp_ngram_annotator</pre>
            PUT request body:
            <pre id="ngram_input"></pre> 
            Response: 
            <pre id="ngram_output"></pre> 

    </div>


<h3 id="CoreNLP_Lemmatizer">CoreNLP Lemmatizer</h3>
<div className="paragraph">
Lemmatization maps a word to its lemma (dictionary form). For instance, the word <i>was</i> is mapped to the word <i>be</i>.
<br/>
This operator requires tookenizer, sentence splitter and part-of-speech tagger to be run previously and annotations made part of the document. 
<br/>
Interface to call PUT request 
<pre>{nlp_root}/core/core_nlp_lemmatizer</pre>
            PUT request body:
            <pre id="lemmatizer_input"></pre> 
            Response: 
            <pre id="lemmatizer_output"></pre> 

    </div>
    <h3 id="spacy_pos">Spacy Part-of-speech tagging</h3>


    <div className="paragraph">
    The Part of speech tagging or POS tagging is the process of marking a word in the text to a particular part of speech based on both its context and definition. In simple language, we can say that POS tagging is the process of identifying a word as nouns, pronouns, verbs, adjectives, etc.
    <br/>
    This part-of-speech tagger is based on Spacy library and its models. The following models can be currently used:
    <ul>
        <li> en_core_sci_lg </li>
<li>en_core_sci_md </li>
<li>en_core_web_sm </li>
<li>en_ner_craft_md</li>
    </ul>
    Also, tokenizer needs to be run and tokens present in the json document of the request body.
    <br/>Interface to be called:
    <pre>{nlp_root}/core/spacy_pos_tag_annotator</pre>
    PUT Request body:
    <pre id="spacy_pos_input"></pre>
    Response:
    <pre id="spacy_pos_output"></pre>
    </div>

    <h3 id="corenlp_pos">CoreNLP Part-of-speech tagging</h3>
    <div className="paragraph">
This part-of-speech tagger is using CoreNLP library and a method from it to perform part-of-speech tagging. It requires tokenizer and sentence splitter to be run before and token spans set. 
<br/>Interface to be called:
    <pre>{nlp_root}/core/core_nlp_pos_tagger</pre>
    PUT Request body:
    <pre id="corenlp_pos_input"></pre>
    Response:
    <pre id="corenlp_pos_output"></pre>

    </div>

<h3 id="markup_removal">HTML markup removal</h3>
<div className="paragraph">
    This is a tool that was internally developed with our partners to remove remaining HTML and javascript markup in text. <br/>
    Interface to be called:
    <pre>{nlp_root}/core/markup_removal</pre>
    PUT Request body:
    <pre id="mark_removal_input"></pre>
    Response:
    <pre id="mark_removal_output"></pre>
</div>


         <h2 id="NER">Named entity recognition</h2>
         <div className="paragraph">
           There are several prevalent methodologies to approach identification of named entities, such as drugs, compounds, genes, or diseases. 
           Implementations of rule/dictionary-based, traditional machine learning-based (based on CRF) and deep learning approaches have been 
           implemented and are available to use. 
        </div>

        <h3 id="regex_ner">Regular expresson named entity recognizer</h3>
        <div className="paragraph">
        This is a simplest form of named entity recognizer, which matches tokens that match given regular expression. In the example, we show how all upper case words are matched
        <br/>
        Tokenizer needs to be run beforehand and in config needs to be defined "regexes" parameter as stringifyied array or regular expressions to be matched.  
        Interface to be called:
    <pre>{nlp_root}/core/regex_ner_annotator</pre>
    PUT Request body:
    <pre id="regex_ner_input"></pre>
    Response:
    <pre id="regex_ner_output"></pre>

            </div>

        <h3 id="spacy_ner">Spacy Named entity recognizer</h3>
        <div className="paragraph">
        This NER annotator uses Spacy library and its model to annotate named entities. The following models are readily available:
        <ul>
            <li>en_core_sci_lg</li>
            <li>en_core_sci_md</li>
            <li>en_core_web_sm</li>
            <li>en_ner_craft_md</li>
        </ul>
        Other models can be as well imported from GitLab/S3. Most of these models would annotate just entities (as Entity class). For more advanced NER, you need to import other models.<br/>
        It requires tokenizer to be run beforehand. <br/>
        Interface to be called:
    <pre>{nlp_root}/core/spacy_nertag_annotator</pre>
    PUT Request body:
    <pre id="spacy_ner_input"></pre>
    Response:
    <pre id="spacy_ner_output"></pre>
        </div>

        <h3 id="Termite">Scibite's Termite</h3>
        <div className="paragraph">
        At the moment this is the only commercial NER tagger. <br/>
Currently available dictionaries are for cell lines, drugs, genes, and diseases, however, they can be added through GitLab/S3. 
Interface to be called:
    <pre>{nlp_root}/core/termite_ner_tagger</pre>
    PUT Request body:
    <pre id="termite_ner_input"></pre>
    Response:
    <pre id="termite_ner_output"></pre>

        </div>


         <h3 id="Linnaeus">Linnaeus (rule/dictionary-based approach)</h3>
         <div className="paragraph">
           Linnaeus was initally an open source named entity recognized developed for recognition of species at the University of Manchester. 
           More information about original open source version of Linnaeus can be found here <a href="http://linnaeus.sourceforge.net/">http://linnaeus.sourceforge.net/</a>. 
           <br/>
           In order to satisfy internal needs, Linnaeus was internally modified in Bayer to allow more global flags, term-level flags and various way of matching. Dictionary 
           structure of Linnaeus was significatnly modified as well as some of the matching methods. 
           <br/>
           At the moment, global “Switches” that are supported are:
<ul>
<li>explode_spaces – allows for multiple spaces and tab characters where there is a single space in dictionary</li>
<li>ignoreCase – ignores the cases</li>
<li>pluralize – finds regular plural forms of words mentioned in dictionary (by adding s, es and some other suffixes to dictionary terms</li>
  <li>rejectNumbers – does not tag numbers</li>
  <li>subsume – tags only longest match for the given dictionary (currently we don’t have any functionality that works across multiple dictionaries)</li>
  <li>condenseNumbers – Given term with number it also finds it if it is condensed e.g. IRAK 4 and IRAK4</li>
  <li>flipGreeks – flips Greek letters/words in front or end of the word, e.g. given interferon alfa it would also find alfa interferon (works with -,_, apart from spaces).</li>
  <li>tokenizationPattrn - regular expression patter used for tokenization. The default is \b </li>
  <li>removeDiacritics - removes diacritics and operates only with characters from english alphabet. Possible values true/false</li>
  <li>spaceChars - A list of characters (not separated, after =>) treats like space character</li>
</ul>
None of globabl switches need to be used, they all have default value set to false and tokenizationPattrn to \b
When it comes to local, the following switches are supported:
<ul>
<li>r^ and ends with ^ - regular expression, however, please make sure not to put infinite regexes. If you need infinite regular expression, there is a special matcher (automatonMatcher) in the tool for that purpose. So it is not possible to mix infinite regexes and normal terms</li>
<li>/@ at the end – exact match</li>
<li>/^ at the end – case sensitive match</li>
<li>/! At the end – subsynonym match, Only reports hit if another hit from synonyms of this entry was found</li>
<li>/> and /^> at the end can be used - initally > meant that it should reduce ambiguity, or terms that would be otherwise flagged as ambigious, flag as normal. In Linnaeus, this switch has no effect.</li>
<li>Also, some combinations are possible, for example /^! for case sensitive subsynonyms or /@! exact match subsynonyms. Note that combination of case sensitive and exact much does not make sense, as exact match is already case sensitive. </li>
</ul>
<b>Important:</b> Try not to have same term under multiple synonyms. Linnaeus will match only one as it uses bynary search. There is no guarante which entity will it be.
         </div>
         <div className="paragraph">
           Dictionaries contain Directives part, with global swtiches and dictionary part. Dictionary part is tab separated line, containing ID, preferred term, list of synonyms separated with "|" symbol and additional metadata that are not used by matching algorithm. Excerrpt of the sample dictionary can be seen bellow: 


         <pre><code>
         ################ <br/>  
# DIRECTIVES<br/> 
# info => This is BayMatcher dictionary for diseases<br/> 
# explode_spaces => true<br/> 
# ignoreCase => true<br/> 
# pluralize => true<br/> 
# rejectNumbers => true<br/> 
# subsume => true<br/> 
# removeDiacritics => true<br/> 
# condenseNumbers => true<br/> 
# flipGreeks => true<br/> 
# friendlyName => Disease<br/> 
# dictionaryMetaData => SCI Disease-Indication<br/> 
# RGB => 255, 235, 59, 0.5<br/> 
######################<br/> 
#id name	synonyms	hierarchy<br/> 
SD:BAYPHTH000001	neoplasms, mammary, animal	animal mammary carcinoma|animal mammary neoplasm|Animal Mammary Neoplasms|carcinoma, animal mammary|carcinomas, animal mammary|mammary carcinoma, animal|mammary carcinomas, animal|MAMMARY NEOPL ANIMAL|mammary neoplasm|mammary neoplasm, animal|mammary neoplasms, animal|Mammatumoren (Tier|neoplasm, animal mammary|neoplasm, mammary|neoplasms, animal mammary|neoplasms, mammary|neoplasms, mammary, animal	["ANIMAL DISEASES>neoplasms, mammary, animal"]	["ANIMAL DISEASES"]	["ANIMAL DISEASES"]	[D015674]	[C0024667,C1257925]<br/> 
SD:BAYPHTH000002	epidermitis, exudative, of swine	disease, greasy pig|epidermitis|Epidermitis, exsudative, Schwein|epidermitis, exudative of swine|epidermitis, exudative, of swine|exudative dermatitis of swine|exudative epidermitis|exudative of swine epidermitis|greasy pig disease|greasy-pig-krankheit|of swine exudative epidermitis|swine exudative dermatitides|swine exudative dermatitis	["ANIMAL DISEASES>epidermitis, exudative, of swine"]	["ANIMAL DISEASES"]	["ANIMAL DISEASES"]	[D004818]	[C0014521]<br/> 
SD:BAYPHTH000003	lameness, animal	animal gait disorder|animal lameness|animal lamenesses|disorder, animal gait|disorders, animal gait|gait disorder, animal|gait disorders, animal|gangstoerungen, tier|gangstörungen, tier|Lahmheit, Tier|lameness|lameness, animal|lamenesses, animal	["ANIMAL DISEASES>lameness, animal"]	["ANIMAL DISEASES"]	["ANIMAL DISEASES"]	[D007794]	[C0022976]<br/> 

         </code></pre>

         The dictionary in this format can be uploaded through GitLab to S3 and used in annotation. 
         </div>
         <div className="paragraph">
        In order to send a request you need to send a post request to: 
        <pre><code>
          {nlp_root}/core/linnaeus_tagger
          </code></pre>
          With the following PUT parameters in json format:
           <pre id="linnaeus_input"></pre>

           The result of annotater are in following format:
           <pre id="linnaeus_output">
             
           </pre>
           Please note that API returns colors that are defined in Linnaeus dictionary both as hexadecimal and RGB values. 
           
      
        </div>
        <div className="paragraph">
         
          
        </div>

        <h2 id="mutli_bio_ner">MultiBio Named entity recognizer</h2>
        <div className="paragraph">
        Multi-bio-NER tagger is named entity recognition engine based on deep neural network architecture and it is described in the paper “Cross-type Biomedical Named Entity Recognition with Deep Multi-Task Learning”. 
<br/>It needs tokenizer to be run beforehand. <br/>
Interface:
<pre> {nlp_root}/core/multi-bio-ner-tagger</pre>
With the following PUT parameters in json format:
           <pre id="multibioner_input"></pre>

           The result of annotater are in following format:
           <pre id="multibioner_output"></pre>

            </div>


            <h2 id="spacy_abbr_ner">Spacy abbreviation annotator</h2>
        <div className="paragraph">
        This annotator finds abbreviations and their full length names. 
<br/>It needs tokenizer to be run beforehand. <br/>
Interface:
<pre> {nlp_root}/core/spacy_abbreviation_annotator</pre>
With the following PUT parameters in json format:
           <pre id="spacy_abbr_ner_input"></pre>

           The result of annotater are in following format:
           <pre id="spacy_abbr_ner_output"></pre>

            </div>

            <h2 id="rename_ner">Rename NER operator</h2>
        <div className="paragraph">
        Can rename or fill NER operator values based on the input values. One needs to define in configuration field in which certain substring or string is matched, output field, and what replacements would take place where on the left side is what is matched, transition character is -> and on the right is value that is written into output field. For example the following configuration
<pre>    
    "in_variable": "eid",<br/>

    "out_variable": "type",<br/>

    "replacements": "SD->Disease,DR->Drug,GH->Gene,CL->Cell-line"
</pre>
<br/>takes and matches either SD, DR, GH or CL in eid field, and depending on what is found writes into type field either Disease, Drug, Gene or Cell-line.   <br/>
Interface:
<pre> {nlp_root}/core/rename_ner_operator</pre>
With the following PUT parameters in json format:
           <pre id="rename_ner_input"></pre>

           The result of annotater are in following format:
           <pre id="rename_ner_output"></pre>

            </div>
<h2 id="nen">Named entity normalization</h2>

<h3 id="taxonomy_nen">Taxonomy Named entity normalization</h3>
<div className="paragraph">
This operator uses taxonomy to normalize previously found named entities. It can be used in combination of some of NER annotators that use machine learning to resolve entities to be FAIR. 
<br/>It needs named entities to be extracted beforehand and all associated operators (potentially sentence splitting, tokenization, POS). Fuzzy score settings is for setting how much terms from taxonomy may differ from found term. <br/>
Interface:
<pre> {nlp_root}/core/taxonomy_nen</pre>
With the following PUT parameters in json format:
           <pre id="taxonomy_nen_input"></pre>

           The result of annotater are in following format:
           <pre id="taxonomy_nen_output"></pre>

            </div>
            <h3 id="spacy_nen">Spacy-based UMLS normalizer</h3>
<div className="paragraph">
This annotator uses a model and UMLS dictionaries to map concepts to concept ids.  Tokens and named entities that should be resolved to concept ids need to be previously annotated.  
<br/>It requires tokens and named entities to be annotated.  <br/>
Interface:
<pre> {nlp_root}/core/spacy_nen_umls_link_annotator</pre>
With the following PUT parameters in json format:
           <pre id="spacy_nen_input"></pre>

           The result of annotater are in following format:
           <pre id="spacy_nen_output"></pre>

            </div>
            <h2 id="classification">Classification</h2>
            <h3 id = "scikit_learn_classifier">SciKitLearn Classifier</h3>
            <div className="paragraph">
            This classifier takes pickled models developed with scikit learn as input and classifies based on them.  
<br/>Currently available models are: <br/>
<ul>
    <li>invivo - classifies whether document describes invivo procedure or not</li>
    <li>therapeutic-use - classifies whether document talks about therapeutic use of some substance</li>
</ul>
Interface:
<pre> {nlp_root}/core/scikit_learn_classifier</pre>
With the following PUT parameters in json format:
           <pre id="scikit_learn_classifier_input"></pre>

           The result of annotater are in following format:
           <pre id="scikit_learn_classifier_output"></pre>

            </div>


            <h3 id = "zero_shot_classifier">Zeto Shot Classifier</h3>
            <div className="paragraph">
            This classifier uses generic language model to find similarities between given labels and provided text.

Interface:
<pre> {nlp_root}/core/zero_shot_classifier</pre>
With the following PUT parameters in json format:
           <pre id="zero_shot_classifier_input"></pre>

           The result of annotater are in following format:
           <pre id="zero_shot_classifier_output"></pre>

            </div>


            <h3 id = "language_detector">Language detector</h3>
            <div className="paragraph">
            This language detector is based on Fasttext language models. 

Interface:
<pre> {nlp_root}/core/language_detector</pre>
With the following PUT parameters in json format:
           <pre id="language_detector_input"></pre>

           The result of annotater are in following format:
           <pre id="language_detector_output"></pre>

            </div>

            <h2 id="relationship_extraction">Relationship extraction</h2>

            <h3 id = "dosage_extractor">Dosage information extractor</h3>
            <div className="paragraph">
            Extracts and links dosage information with associated drugs. Requires tokenizer and named entities to be extracted beforehand. 

Interface:
<pre> {nlp_root}/core/dosage_info_extractor</pre>
With the following PUT parameters in json format:
           <pre id="dosage_extractor_input"></pre>

           The result of annotater are in following format:
           <pre id="dosage_extractor_output"></pre>

            </div>

            <h3 id = "co_occurrence_ext">Co-occurrence extractor</h3>
            <div className="paragraph">
            Finds co-occurrences between named entities that are previously annotated and selected to be annotated in configuration. One needs to define types of entities that co-occurrences can happen between. <br/>
            It is required that tokens and named entities are extracted<br/>

Interface:
<pre> {nlp_root}/core/co_occ_extractor</pre>
With the following PUT parameters in json format:
           <pre id="co_occurrence_ext_input"></pre>

           The result of annotater are in following format:
           <pre id="co_occurrence_ext_output"></pre>

            </div>

            <h3 id = "rel_ext_rules">Rule-based relationship extractor</h3>
            <div className="paragraph">
            Rule-based relationship extractor finds named relationships in text using a set of dictionaries (for trigger phrases, speculative phrases, negations, modifiers (e.g. gene attributes and modes of action) and sentence rules. 
            These dictionaries and rules are defined in a set of files, which needs to be passed to the following parameters:
            <ul>
              <li>TRIGGER_RELATIONSHIPS - set of trigger words, phrases and regular expressions</li>
              <li>SENTENCE_RULES - rules on how sentences or sentence fragments containing relationship can look like</li>
              <li>SPECULATION_CUES - list of keywords making sentence speculative</li>
              <li>NEGATIONS - list of negation keywords, often flipping the meaning of relationship</li>
              <li>NEGATIVE_ADVERBS</li>
              <li>MIDDLE_BLACK_LIST - black list of words that would discredit relationship</li>
            </ul>
            These dictionaries need to be on S3 bucket, where they can appear by being uploaded to main branch of  the following GitLab repository <a href="https://gitlab.bayer.com/sih/kafka-nlp/user-dictionaries" target="_blank">https://gitlab.bayer.com/sih/kafka-nlp/user-dictionaries</a>.
            <br/>
            This operator needs named entities to be extracted and sentences to be annotated (sentence splitter).
            <br/>


Interface:
<pre> {nlp_root}/core/relationship_extraction_rule_set</pre>
With the following PUT parameters in json format:
           <pre id="relationship_ext_input"></pre>

           The result of annotater are in following format:
           <pre id="relationship_ext_output"></pre>

            </div>

            <h2 id="complex_lan">Complex language operators</h2>
            <h3 id = "translation">Translation</h3>
            <div className="paragraph">
            This method uses argus models to translate given text from one language to another. The available models are: <br/>
            <ul>
                <li>argos-translation-annotator-de-en</li>
                <li>argos-translation-annotator-es-en</li>
                <li>argos-translation-annotator-fr-en</li>
                <li>argos-translation-annotator-it-en</li>
                <li>argos-translation-annotator-pt-en</li>
                <li>argos-translation-annotator-zh-en</li>
            </ul>

Interface:
<pre> {nlp_root}/core/translation_annotator</pre>
With the following PUT parameters in json format:
           <pre id="translation_input"></pre>

           The result of annotater are in following format:
           <pre id="translation_output"></pre>

            </div>

            <h2 id="post_processing">Post processing</h2>
            <h3 id="projection">Projection</h3>

            <div className="paragraph">
            Projection can either remove given annotations (with configuration option DISCARD) or retain given annotations (with configuration option RETAIN) <br/>

Interface:
<pre> {nlp_root}/core/projection</pre>
With the following PUT parameters in json format:
           <pre id="projection_input"></pre>

           The result of annotater are in following format:
           <pre id="projection_output"></pre>

            </div>

            <h3 id="filter">Filter</h3>

            <div className="paragraph">
            The filter operator checks different filters against a single document and discards the document if the filters do not apply. You can define multiple groups of filters. A document fulfills the filter of a group if one of the configured filters applies to the Document. The operator discards the Document if one of the groups does not apply to the Document.
<br/>Specify the annotation typ for the filter (same as in the projection operator): tokens, relations, sentences, ner, abbreviations, lemmas, uml_links and pos.
<br/>Specify the operation EXISTS, EQUALS, NOT_EQUALS, GT, GTE, LT and LTE
<br/>Specify the name of the field: e.g. type, score, eid, ...
<br/>Specify the value to test against: e.g. 0.4, my_id, ...

Interface:
<pre> {nlp_root}/core/filter</pre>
With the following PUT parameters in json format:
           <pre id="filter_input"></pre>

           The result of annotater are in following format:
           <pre id="filter_output"></pre>

            </div>


            

        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default NLPScaleDocumentation;