
import React, {Component} from 'react';
import {nlp_machine_api} from '../APIs.js'

class Zero_shot_BioNER_field extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
    var text = document.getElementById('input_text').value;
    var labels = document.getElementById('labels').value;
      console.log(text);
      const req = request.post(nlp_machine_api+'/zero_shot_bio_ner').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"query":labels+'[SEP]'+text});
      req.end((end,res)=>{
        if(res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login"); 
        }
        console.log(res.body);
        var text = res.body.text;
        function sort_by_key(array, key)
        {
        return array.sort(function(a, b)
        {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        }
        var annotations = sort_by_key(res.body.entities,"span_start");

        var addition = 0;
        var marked_text = text;
        var start =-1;
        var entity_table = "<table border='1'><thead><td>Type</td><td>Text</td><td>Start</td><td>End</td><td>Confidence</td></thead>";
        for(var i = 0;i<annotations.length;i++){
            console.log(annotations[i]);
            var span_element = "<span title='Semantic type: "+annotations[i].label+"' style=\"background-color:"+"yellow"+"\">";
            var span_len = span_element.length;

              marked_text =  marked_text.slice(0, parseInt(annotations[i].span_start)+addition) +  span_element + marked_text.slice(parseInt(annotations[i].span_start)+addition,parseInt(annotations[i].span_end)+addition)+"</span>"+marked_text.slice(parseInt(annotations[i].span_end)+addition);
           
  
            addition = addition + span_len + "<span/>".length;
            
            entity_table = entity_table+"<tr><td>"+annotations[i].label+"</td><td>"+annotations[i].text+"</td><td>"+annotations[i].span_start+"</td><td>"+annotations[i].span_end+"</td><td>"+annotations[i].confidence+"</td></tr>";
        }
        entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
        document.getElementById('entity_table').innerHTML = entity_table;
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Zero Shot BioNER (Implemented with Institute for AI R&D of Serbia)</h3>
          <div id="content-div">This model have been developed and implemented in collaboration between Bayer and Institute for Artificial Intelligence Research and Development of Serbia. For more details and evaluation of the model, please see the following paper: <a href="https://arxiv.org/pdf/2305.04928.pdf" target='_blank'>"From Zero to Hero: Harnessing Transformers for Biomedical Named Entity Recognition in Zero- and Few-shot Contexts"</a></div>
          <h3>Label (lable that we search for - only one):</h3>
          <input type="text"  id="labels" className="labels" name="labels"></input><br/>
          <h3>Imput text for annotation:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#Zero_shot_BioNER">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
          <div id='entity_table' className="entity_table"></div>
      </div>
    );
  }
}

export default Zero_shot_BioNER_field;