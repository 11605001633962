import React, { Component } from 'react';
import logo from './Linguist-logo.png';
import FileDrop from '../FileDrop.js';
import PublicDictionaryView from '../PublicDictionaryView.js';
import TextAnnotationField from './TextAnnotationField.js';
import TopMenu from '../TopMenu.js';
import DictionaryUploader from '../DictionaryUploader.js';
import PrivateDictionaryView from '../PrivateDictionaryView.js';

class AnnotateText extends Component {
    constructor() {
        super();
       
        this.state = {
          files: [],
          dobjects: []
        };
      }
    


  render() {
    return (
      <div className="MegaContainer">
          <TopMenu/><hr />
        <div className="App">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          <div className="mainBlock">
          <div className="mainLeft">
            <TextAnnotationField/>
            
        </div>
        <div className="MainRight">
          <PublicDictionaryView/>
          <br/>
          <PrivateDictionaryView/>
          <h3>Upload user dictionary</h3>
          <DictionaryUploader/>
          

        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default AnnotateText;