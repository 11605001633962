import React, {Component} from 'react';
import {gpu_api} from "../APIs.js"

class PublicOntologyView extends Component {
    
    loadDics() {
        const request = require('superagent');
        const gpu_api = "https://zf5syc4b0l.execute-api.eu-central-1.amazonaws.com/LinguistAPI_state1";
        var req = request.post(gpu_api+'/list_pub_ontologies').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });
        req.end((end,res)=>{
          if(res==undefined || end !== null){
            window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
           // throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
          }
          console.log(res);
          
          for(let i = 0;i<res.body.length;i++){
            this.state.dobjects.push(res.body[i]);
          }
          localStorage.setItem('pub_dics',this.state.dobjects[res.body.length-1]);
          this.forceUpdate();
            });
        }
    
  constructor() {
    super();
    this.loadDics = this.loadDics.bind(this);
   
    this.state = {
      files: [],
      dobjects: []
    };
  }


  componentDidMount(){
    this.loadDics();

    
  }
  checkboxChange(event) {
    if (document.getElementById(event.target.id).checked == true) {
      document.getElementById(event.target.id).value = 'on';
      document.getElementById(event.target.id).checked = true;
      var pub_dics = localStorage.getItem('pub_dics') ;
      pub_dics = event.target.id;
      localStorage.setItem('pub_dics',pub_dics);
      console.log(pub_dics);
      
    } 
}


  render() {
    const dics = this.state.dobjects.map(file => (
      <div>
        <input type="radio" id={file} name="pub_ontologies" defaultChecked='true'
         onClick={this.checkboxChange} ></input>
         <label for={file}>{file}</label>
         </div>

    ));

    return (
      <div className="public_dictionaries">
          <h3>Public ontologies</h3>
          {dics}
      </div>
    );
  }
}

export default PublicOntologyView;