import logo from './logo.svg';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import './App.css';
import Home from './Home.js';
import AnnotateText from './NER/AnnotateText.js';
import HomeScreen from './HomeScreen.js';
import UMLS_annotate from './NER/UMLS_annotate.js';
import CRFNERannotate from './NER/CRFNERannotate.js';
import CRFUMLS_annotate from './NER/CRFUMLS_annotate.js';
import About from './About.js';
import Documentation from './Documentation.js'
import MZannotate from './NER/MarinikaZitnik.js'
import NENAnnotateText from './NEN/NENAnnotateText.js';
import SentimentAnnotateText from './Classification/SentimentAnnotateText.js'
import SummarizationText from './Summarization/SummarizationText.js'
import TranslationPage from './Summarization/TranslationPage.js'
import InferencePage from './Inference/InferencePage.js'
import QAPage from './Inference/QAPage'
import ZeroShotAnnotateText from './Classification/ZeroShotAnnotateText.js'
import PICOClassifierText from './Classification/PICOClassifierText.js'
import InVivoClassifierText from './Classification/InVivoClassifierText';
import TherUseClassifierText from './Classification/TherUseClassifierText';
import Wizzard from './Wizzard/WizzardMain';
import Workbench from './Wizzard/Workbench';
import NLPScaleDocumentation from './NLPScaleDocs';
import I2B2annotate from './NER/TAC_Drug';
import TAC_ADR_annotate from './NER/TAC_ADR';
import Drug_biober_annotate from './NER/Drug_biobert'
import Disease_biober_annotate from './NER/Disease_biobert'
import Gene_biober_annotate from './NER/Gene_biobert'
import Ontotext_annotate from './NER/Ontotext_ner'
import Few_shot_NER from './NER/Few_shot_NER';
import RWE_annotate from './NER/RWE_ner'
import NumericSequence from './Other/NumericSequence';
import GPT3Text from './Summarization/GPT3Text';
import GPT3TurboText from './Summarization/GPT3TurboText';
import GPTCodeText from './Summarization/GPTCodeText';
import Zero_shot_bioNER from './NER/Zero_shot_bioNER';
import HubbleGPTText from './Summarization/HubbleGPTText';
import HubbleGPT4Text from './Summarization/HubbleGPT4Text';
import GPT4Text from './Summarization/GPT4Text';
import GPT4_32kText from './Summarization/GPT4_32kText';
import Claude2Text from './Summarization/Claude2Text';
import Llama2_13BText from './Summarization/Llama2_13BText.js';
import Llama2_70BText from './Summarization/Llama2_70BText.js';
import GPT4TurboText from './Summarization/GPT4TurboText.js';
import GeminiText from './Summarization/GeminiText.js';
import Palm2Text from './Summarization/Palm2Text.js';
import Palm2UnicornText from './Summarization/Palm2UnicornText.js';
import MixtralText from './Summarization/MixtralText.js';
import Claude3Text from './Summarization/Claude3Text.js';
import Claude3OpusText from './Summarization/Claude3OpusText.js';
import Gemini15Text from './Summarization/Gemini15Text.js';
import GPT4oText from './Summarization/GPT4oText.js';
import Llama3_70BText from './Summarization/Llama3_70BText.js';
import Claude35Text from './Summarization/Claude35Text.js';
import Llama31_70BText from './Summarization/Llama31_70BText.js';
import GPT4oMiniText from './Summarization/GPT4oMiniText.js';


class App extends Component {

   componentDidMount() {
  }

  render() {

    return (
      <main>
        <BrowserRouter>
        <Switch>
        <Route path="/" component={HomeScreen} exact />
          <Route path="/annotatePDF" component={Home} exact />
          <Route path="/annotateText" component={AnnotateText} exact />
          <Route path="/UMLS_annotate" component={UMLS_annotate} exact />
          <Route path="/CRFNER_annotate" component={CRFNERannotate} exact />
          <Route path="/CRFUMLS_annotate" component={CRFUMLS_annotate} exact />
          <Route path="/MZ_annotate" component={MZannotate} exact />
          <Route path="/Chem_biobert" component={Drug_biober_annotate} exact />
          <Route path="/Disease_biobert" component={Disease_biober_annotate} exact />
          <Route path="/Ontonotes_ner" component={Ontotext_annotate} exact />
          <Route path="/Gene_biobert" component={Gene_biober_annotate} exact />
          <Route path="/Ontology_norm" component={NENAnnotateText} exact />
          <Route path="/Sentiment" component={SentimentAnnotateText} exact />
          <Route path="/Summarization" component={SummarizationText} exact />
          <Route path="/Translation" component={TranslationPage} exact />
          <Route path="/Inference" component={InferencePage} exact />
          <Route path="/QuestionAnswer" component={QAPage} exact />
          <Route path="/i2b2_Drug" component={I2B2annotate} exact />
          <Route path="/TAC_ADR" component={TAC_ADR_annotate} exact />
          <Route path="/RWE_NER" component={RWE_annotate} exact />
          <Route path="/ZeroShotClassifier" component={ZeroShotAnnotateText} exact />
          <Route path="/PICOClassifier" component={PICOClassifierText} exact />
          <Route path="/InVivoClassifier" component={InVivoClassifierText} exact />
          <Route path="/TherUseClassifier" component={TherUseClassifierText} exact />
          <Route path="/zero_shot_ner" component={Few_shot_NER} exact />
          <Route path="/Wizzard" component={Wizzard} exact />
          <Route path="/Workbench" component={Workbench} exact />
          <Route path="/PredictNumSequence" component={NumericSequence} exact />
          <Route path="/Davinci" component={GPT3Text} exact />
          <Route path="/GPT35Turbo" component={GPT3TurboText} exact />
          <Route path="/GPTCode" component={GPTCodeText} exact />
          <Route path="/ZeroShotBioNER" component={Zero_shot_bioNER} exact/>
          <Route path="/HubbleGPTQA" component={HubbleGPTText} exact/>
          <Route path="/HubbleGPT4QA" component={HubbleGPT4Text} exact/>
          <Route path="/GPT4" component={GPT4Text} exact/>
          <Route path="/GPT4_32k" component={GPT4_32kText} exact/>
          <Route path="/GPT4o" component={GPT4oText} exact/>
          <Route path="/GPT4oMini" component={GPT4oMiniText} exact/>
          <Route path="/Claude2" component={Claude2Text} exact/>
          <Route path="/Llama2_13B" component={Llama2_13BText} exact/>
          <Route path="/Llama2_70B" component={Llama2_70BText} exact/>
          <Route path="/Llama3_70B" component={Llama3_70BText} exact/>
          <Route path="/Llama31_70B" component={Llama31_70BText} exact/>
          <Route path="/Gemini_pro" component={GeminiText} exact/>
          <Route path="/PALM2" component={Palm2Text} exact/>
          <Route path="/PALM2_unicorn" component={Palm2UnicornText} exact/>
          <Route path="/GPT4Turbo" component={GPT4TurboText} exact/>
          <Route path="/Mixtral" component={MixtralText} exact/>
          <Route path="/Claude3" component={Claude3Text} exact/>
          <Route path="/Claude3Opus" component={Claude3OpusText} exact/>
          <Route path="/Gemini_pro15" component={Gemini15Text} exact/>
          <Route path="/Claude35Sonnet" component={Claude35Text} exact />
          
          
          
          
          
          <Route path="/contact" component={About} exact />
          <Route path="/docs" component={Documentation} exact />
          <Route path="/nlp_scale_docs" component={NLPScaleDocumentation} exact />
          

          
        </Switch>
        </BrowserRouter>
      </main>

    );
  }
}

export default App;
