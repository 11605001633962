import React, {Component} from 'react';
import {nlp_machine_api} from '../APIs.js'

class ZeroShotAnnotationField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      var text = document.getElementById('input_text').value;
      var labels = document.getElementById('labels').value;
      var multi_label = document.getElementById('multi-label').value;
      console.log(text);
    console.log(multi_label)
    if (document.getElementById("multi-label").checked == false) {
      const req = request.post(nlp_machine_api + '/zero_shot_classifier').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"text":text,"labels":labels});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
        }
        console.log(res.body);
        var annotations = res.body;
        var marked_text = "Class:"+res.body.class +"<br/>\n"+"Confidence:"+res.body.confidence;
        // var entity_table = "<table border='1'><thead><td>ID</td><td>Concept</td><td>Similarity</td></td></thead>";
        // for(var i = 0;i<annotations.length;i++){
        //     //console.log(annotations[i]);
        //     entity_table = entity_table+"<tr><td>"+annotations[i].concept_id+"</td><td>"+annotations[i].concept_name+"</td><td>"+annotations[i].similarity+"</td></tr>";
            
        // }
        // entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
        //document.getElementById('entity_table').innerHTML = entity_table;
        //this.state.dobjects.push({name:res.body.url,url:'http://localhost:8080/downloadFile?docName='+res.body.url});
        //this.forceUpdate() 
      });
    }else{
      const req = request.post(nlp_machine_api + '/zero_shot_classifier_multi').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"text":text,"labels":labels,"multi_class":"True"});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login");   
        }
        console.log(res.body);
        var annotations = res.body.classes;
        var marked_text = "";
        for (var i=0;i<res.body.classes.length;i++){
        marked_text = marked_text + "<br/>\n"+"Class: "+annotations[i].class +"  --  "+"Confidence: "+annotations[i].confidence;
        }
        // var entity_table = "<table border='1'><thead><td>ID</td><td>Concept</td><td>Similarity</td></td></thead>";
        // for(var i = 0;i<annotations.length;i++){
        //     //console.log(annotations[i]);
        //     entity_table = entity_table+"<tr><td>"+annotations[i].concept_id+"</td><td>"+annotations[i].concept_name+"</td><td>"+annotations[i].similarity+"</td></tr>";
            
        // }
        // entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'

    });
     
}
  }

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h2>Zero Shot classification</h2>
          <h3>Possible labels (comma separated):</h3>
          <input type="text"  id="labels" className="labels" name="labels"></input><br/>
          <div className='MultiClass'><input type="checkbox" id="multi-label" name="multi-label" ></input>
         <label for="multi-label">Multi-label classification</label></div>
          <h3>Input text for annotation:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#zeroshot">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
      </div>
    );
  }
}

export default ZeroShotAnnotationField;