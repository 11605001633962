import PublicDictionaryView from "../PublicDictionaryView";
import PrivateDictionaryView from "../PrivateDictionaryView"


const Modal = ({ handleClose, handleSave, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    sessionStorage.setItem("input_language","English");
    sessionStorage.setItem("output_language","German");

    var acc = document.getElementsByClassName("accordion");
var i;

const handleChange = event => {
    sessionStorage.setItem("labels",document.getElementById("labels").value);
};

const handleChangeColumn = event => {
    sessionStorage.setItem("column_name",document.getElementById("col_name_zero").value);
};

const handleChangeInputLanguage = event => {
  sessionStorage.setItem("input_language",document.getElementById("input_language").value);
};

const handleChangeOutputLanguage = event => {
  sessionStorage.setItem("output_language",document.getElementById("output_language").value);
};




for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    for (i = 0; i < acc.length; i++) {
        acc[i].classList.remove("active");
        panel = acc[i].nextElementSibling;
        panel.style.display = "none";
        
    }
    this.classList.toggle("active");
    sessionStorage.setItem("active_accordion",this.firstChild.data);
    
    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}

// document.getElementById("labels").addEventListener("change",(event) => {
//     sessionStorage.setItem("labels",document.getElementById("labels").textContent);
// });

// document.getElementById("col_name_zero").addEventListener("change",(event) => {
//     sessionStorage.setItem("column_name",document.getElementById("col_name_zero").textContent);
// });

  
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          {children}
          <button class="accordion">Linnaeus</button>
<div class="panel">
    <PublicDictionaryView/>
    <PrivateDictionaryView/>
</div>
<button class="accordion">UMLS annotator</button>
<div class="panel">
  <p>Use UMLS annotator</p>
</div>

<button class="accordion">CRF for Genes,Drugs, and Diseases with UMLS</button>
<div class="panel">
  <p>Use CRF for Genes,Drugs, and Diseases with UMLS normalization</p>
</div>
<button class="accordion">Marinika Zitnik NER</button>
<div class="panel">
  <p>Use Marinika Zitnik NER</p>
</div>

<button class="accordion">Sentiment</button>
<div class="panel">
  <p>Use T5 sentiment</p>
</div>

<button class="accordion">PICO Classifier</button>
<div class="panel">
  <p>Use PICO classifier</p>
</div>

<button class="accordion">Zero shot classifier</button>
<div class="panel">
    
  <p>
  <p><h3>Column name:</h3>
          <input type="text"  id="col_name_zero" className="labels" name="labels" onChange={handleChangeColumn}></input><br/></p>
      
      <h3>Possible labels (comma separated):</h3>
          <input type="text"  id="labels" className="labels" name="labels" onChange={handleChange}></input><br/>
  </p>
</div> 
<button class="accordion">In Vivo Classifier</button>
<div class="panel">
  <p>
  <p>Use In Vivo classifier</p>
  </p>
</div> 
<button class="accordion">Therapeutic Use Classifier</button>
<div class="panel">
  <p>
  <p>Use Therapeutic use classifier</p>
  </p>
</div> 
<button class="accordion">Summarization</button>
<div class="panel">
  <p>
  <p>Use Summarizer based on T5 model</p>
  </p>
</div> 

<button class="accordion">Translate</button>
<div class="panel">
  <p>
  <div id="boxForLang">
          <label className="inputlabel" for="input_language">Input language:</label>
          <select name="input_language" id="input_language" onChange={handleChangeInputLanguage}>
          <option value="English">English</option>
          </select>
          <label className="inputlabel" for="output_language">Output language:</label>
          <select name="output_language" id="output_language" onChange={handleChangeOutputLanguage}>
          <option value="German">German</option>
          <option value="French">French</option>
            <option value="Romanian">Romanian</option>
          </select>
          </div>
  </p>
</div> 






          <button type="button" className="SaveButtonModal" onClick={handleSave}>
            Add selected column
          </button>
          <button type="button" className="CloseButtonModal" onClick={handleClose}>
            Close
          </button>
        </section>
      </div>
    );
  };

  export default Modal;