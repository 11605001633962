// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
// Msal Configurations
const config = {
auth: {
    authority: 'https://sts.windows.net/fcb2b37b-5da0-466b-9b83-0014b67a7c78/',//'https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78',
    clientId: 'fe11d629-a7d0-41b9-afc1-8aa24edbc011',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: "https://linguist.skgt.int.bayer.com",
    validateAuthority: false,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true},
cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};
// Authentication Parameters
export const authenticationParameters = {scopes: ['fe11d629-a7d0-41b9-afc1-8aa24edbc011/.default',//'user.read',
//'profile',
//'openid'
]}
export const authenticationParametersGraph = {scopes: ['openid']}
// Options
export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin}
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);