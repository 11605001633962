import React, { Component } from 'react';
import logo from '../Linguist-logo.png';

import HubbleGPTField from './HubbleGPTField';
import TopMenu from '../TopMenu.js';

class HubbleGPTText extends Component {
    constructor() {
        super();
       
        this.state = {
          files: [],
          dobjects: []
        };
      }
    


  render() {
    return (
      <div className="MegaContainer">
          <TopMenu/><hr />
        <div className="App">
        <div className='error_message' id='error_msg'>There was an timeout with your request. Please wait for few seconds and then you can try again. Often it works on 2nd or 3rd attempt.</div>
         
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
          <div className="mainBlock">
          <div className="mainLeft">
            <HubbleGPTField/>
        </div>
        <div className="MainRight">
          

        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default HubbleGPTText;