import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class TextAnnotationField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      var pub_dics = localStorage.getItem('pub_dics');
      var text = document.getElementById('input_text').value;
      console.log(text);
      const gpu_api = "https://zf5syc4b0l.execute-api.eu-central-1.amazonaws.com/LinguistAPI_state1";
      const req = request.post(gpu_api + '/resolve_term').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json',"Content-Type":"application/json" });;
      req.send({"query":text,"ontology":"ontologies"+"/"+localStorage.getItem("pub_dics")});
      req.end((end,res)=>{
        if (res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
          //throw new Error("Something went badly wrong! Token is not defined, redirecting to login"); 
        }
        console.log(res.body);
        var annotations = res.body;

        var addition = 0;
        var marked_text = text;
        var entity_table = "<table border='1'><thead><td>ID</td><td>Concept</td><td>Similarity</td></td></thead>";
        for(var i = 0;i<annotations.length;i++){
            //console.log(annotations[i]);
            entity_table = entity_table+"<tr><td>"+annotations[i].concept_id+"</td><td>"+annotations[i].concept_name+"</td><td>"+annotations[i].similarity+"</td></tr>";
            
        }
        entity_table = entity_table + "</table>"
        console.log(marked_text);
       // document.getElementById('marked_text').innerHTML = marked_text;
        //document.getElementById('marked_text').style.visibility='visible'
        document.getElementById('entity_table').innerHTML = entity_table;
        //this.state.dobjects.push({name:res.body.url,url:'http://localhost:8080/downloadFile?docName='+res.body.url});
        //this.forceUpdate() 
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Normalization method: Sentence BERT</h3>
          <p className="inter_para">The method takes a single concept and tries to map it to the closest concept in the given ontology. Table shows top 10 entities with the similarity scores.  For example if you type "Black death" and select "Human Disease Ontology", the first mapping would be into "bubonic plague".</p>
          <h3>Input concept for annotation:</h3>
          <textarea type="text" ncols="40" rows="7" id="input_text" className="text_input" name="text_input"></textarea>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#onto_normalizer">Documentation about making API calls</a></div>
          <div id='entity_table' className="entity_table"></div>
      </div>
    );
  }
}

export default TextAnnotationField;