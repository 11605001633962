import React, {Component} from 'react';
import {gpu_api} from "../APIs.js";

class InferenceField extends Component {
    
    
    
  constructor() {
    super();
   
    this.state = {
      files: [],
      dobjects: []
    };
  }
  


  ButtonSubmit(event) {
    const request = require('superagent');
      var hypothesis = document.getElementById('hypothesis_text').value;
      var text = document.getElementById('premise_text').value;
      console.log(text);
      const req = request.post(gpu_api + '/t5_inference').set({ 'Authorization': localStorage.getItem("cognito_token"), Accept: 'application/json' });;
      req.send({"hypothesis":hypothesis,"premise":text});
      req.end((end,res)=>{
        if(res==undefined || end !== null){
          window.location.href='https://linguist.auth.eu-central-1.amazoncognito.com/login?client_id=1opjprr5da5hmchq24ri8jnjhi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://linguist.skgt.int.bayer.com/auth.html';
        //  throw new Error("Something went badly wrong! Token is not defined, redirecting to login");
        }
        console.log(res.body);
        var annotations = res.body;

        var addition = 0;
        var marked_text = res.body.inference;
        // var entity_table = "<table border='1'><thead><td>ID</td><td>Concept</td><td>Similarity</td></td></thead>";
        // for(var i = 0;i<annotations.length;i++){
        //     //console.log(annotations[i]);
        //     entity_table = entity_table+"<tr><td>"+annotations[i].concept_id+"</td><td>"+annotations[i].concept_name+"</td><td>"+annotations[i].similarity+"</td></tr>";
            
        // }
        // entity_table = entity_table + "</table>"
        console.log(marked_text);
        document.getElementById('marked_text').innerHTML = marked_text;
        document.getElementById('marked_text').style.visibility='visible'
        //document.getElementById('entity_table').innerHTML = entity_table;
        //this.state.dobjects.push({name:res.body.url,url:'http://localhost:8080/downloadFile?docName='+res.body.url});
        //this.forceUpdate() 
      });
     
}

  componentDidMount(){

    
  }
  


  render() {
    

    return (
      <div className="text_field">
          <div>
          <h3>Inference (T5)</h3>
          <p className="inter_para">Classifies pair of sentences into three classes: Neutral, Entailment or Contradiction.</p>
          <br/>
          <h3>Premise:</h3>
          <input type="text"  id="premise_text" className="premise_text" name="premise_text"></input>
          <h3>Hypothesis:</h3>
          <input type="text" id="hypothesis_text" className="hypothesis_text" name="hypothesis_text"></input>
          </div>
          <button id="submit_text" className="submit_text" onClick={this.ButtonSubmit}>Submit</button>
          <div id="doc"><a href="/docs#t5_inference">Documentation about making API calls</a></div>
          <div id='marked_text' className="marked_text"></div>
      </div>
    );
  }
}

export default InferenceField;